import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import {  getPosts1, updatePost } from '../../../../api/UserAPI';
import { theme,   } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const EditPostComponent = () => {
    
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const getId = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({
    title: '',
    content: '',
    tag: '',
    image: null,
  });

  const Id = (getId.blogId)
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  useEffect(() => {
    const fetchBlogData = async () => {
        try {
          const response = await getPosts1(Id);
          setPost(response);
        } catch (error) {
          setError(error)
        }
      };
  
      fetchBlogData();
    }, []);

  const handleCKEditorChange = (name, editor) => {
    const value = editor.getData();
    setPost({ ...post, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setPost({ ...post, [name]: e.target.files[0] });
    } else {
      setPost({ ...post, [name]: value });
    }
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const formData = new FormData();
    formData.append('title', post.title);
    formData.append('content', post.content);
    formData.append('tag', post.tag);

    if (post.image) {
      formData.append('image', post.image);
    }else{
      formData.append('image', post.image)
    }

    try {
      // const response = await axios.put(`http://13.58.79.27/api/posts/${Id}/`, formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      const response = await updatePost(Id, formData)
      //});

      setMessage("Blog  updated successfully");
      setLoading(false);
      // Here you might want to navigate back or to a specific page
      navigate('/admin/blogs');
    } catch (error) {
      console.error('Error updating post:', error);
      setError("Select an image");
      setLoading(false);
    }
  };

  return (
    <div>
 <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Update Post</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          </div>
        </div>
      </div>

      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>   

      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" name="title" id="title" value={post.title} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="content">Content</Label>
          <CKEditor
            editor={ClassicEditor}
            data={post.content || ''}
            onChange={(event, editor) => handleCKEditorChange('content', editor)}
            onBlur={(event, editor) => {
              //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //console.log('Focus.', editor);
            }}
          />
          {/* <Input type="textarea" name="content" id="content" value={post.content} onChange={handleChange} style={{ minHeight: '250px' }} required /> */}
        </FormGroup>
        <FormGroup>
          <Label for="title">Tag(s)</Label>
          <Input type="text" name="tag" id="tag" value={post.tag} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image</Label>
          <Input type="file" name="image" id="image" onChange={handleChange} required />
          {post.image && <img src={post.image} alt="Post" style={{ width: "100px", height: "100px" }} />}
        </FormGroup>
        {message && <div className="alert alert-success" role="alert">{message}</div>}

        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        <Button type="submit" color="primary" disabled={loading}>
          {loading ? 'Updating...' : 'Update Post'}
        </Button>
      </Form>
    </div>
    </div>
  );
};

export default EditPostComponent;
