import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Alert, Spin, message, Checkbox } from 'antd';
import { useParams } from 'react-router-dom';
import { getUserById, updateUser } from '../../../api/UserAPI';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;

const UpdateUser = () => {
  const { id } = useParams(); // Get the user ID from the URL
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch user details when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await getUserById(id);
        setUser(data); // Set user data
        form.setFieldsValue(data); // Populate form fields with user data
      } catch (err) {
        setError(err.message);
        message.error('Failed to fetch user details');
      }
    };

    fetchUser();
  }, [id, form]);

  // Handle form submission
  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(values)
    try {
      await updateUser(id, values);
      message.success('User updated successfully');
      setError(null);
      navigate(`/admin/user-manage`)
      
    } catch (err) {
      setError(err.message);
      message.error('Failed to update user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Update User</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={user} // Initial values should be set here
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: 'Please enter first name' }]}
        >
          <Input placeholder="First name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Please enter last name' }]}
        >
          <Input placeholder="Last name" />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true, message: 'Please enter username' }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter email' },
            { type: 'email', message: 'Please enter a valid email' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="user_type"
          label="User Type"
          rules={[{ required: true, message: 'Please select user type' }]}
        >
          <Select placeholder="Select user type">
            <Option value="admin">Admin</Option>
            <Option value="instructor">Instructor</Option>
            <Option value="student">Student</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="is_superuser"
          label="Give a full admin priviledge to this user"
          valuePropName="checked"
          // Note: Consider adding rules for this field if needed
        >
          <Checkbox>Is Superuser</Checkbox>
        </Form.Item>
        <Form.Item>
          {error && <Alert message={error} type="error" showIcon />}
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? <Spin /> : 'Update User'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateUser;
