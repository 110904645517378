import React,{useState, useEffect} from 'react';
import { getTeam, delTeam, editTeam, postTeam } from '../../../api/UserAPI';
import { useFetchData } from '../hooks/useFetchData';
import { GenericDataManagementComponent } from '../hooks/GenericBlocs';
import CreateTeamModal from './modals/TeamsModal';
import { useNavigate } from 'react-router-dom';
import EditTeamModal from './modals/EditTeamModal';
import { Link } from 'react-router-dom';
import { Drawer, theme, Modal, Space } from 'antd';
//import {   FormGroup, Label,Input,Form, Button } from 'reactstrap';
import { Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import altImage from "../../../assets/img/team/profile.jpeg";
import {  StyledSelect, } from "../../../components/StyledComponents";


const Team = () => {
  //const { data: teamMembers, isLoading, notFound } = useFetchData(getTeam);
  const [modalOpen, setModalOpen] = useState(false);

  //const toggleModal = () => setModalOpen(!modalOpen);
  //const openModal = () => setModalOpen(true);
  //const closeModal = () => setModalOpen(false);
  const [teams, setTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noTeamFound, setNoTeamFound] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [teamImage, setTeamIamge] = useState();
  const [teamMember, setTeamMember] = useState({
    name: '',
    position: '',
    bio: '',
    facebookUrl: '',
    twiiterUrl: '',
    linkedinUrl: '',
    image: null,
    
  });
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [selectedItem, setSelectedItem] = useState(null);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showModal = (id) => {
    setSelectedItem(id)
    setOpenModal(true);
  };
  const hideModal = () => {
    setSelectedItem(null)
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchTeam = async () => {
      setIsLoading(true)
        try {
            const response = await getTeam();
            if (!response || response.length === 0) {
              setNoTeamFound(true); // 
              setTeam([]);
          } else {
              setTeam(response);
              console.log(response)                    
              setNoTeamFound(false); //
            }

            
        } catch (error) {
            console.error("Could not fetch team members.", error);
        } finally {
          setIsLoading(false); // Set loading to false when the request is done
        }
    };

    fetchTeam();
}, []);

  const handleDelete = async (teamId) => {
    setIsLoading(true);
//const isConfirmed = window.confirm("Are you sure you want to delete this team member?");
  
  // If the user confirmed, call the delete function
  //if (isConfirmed) {
    
  
    try {
        // Pass the `teamId` to `delTeam`
        const response = await delTeam(teamId);
        if (!response) {
            setNoTeamFound(true);
        } else {
            setNoTeamFound(false);
            setMessage("Team member  deleted successfully");

      
        }
    } catch (error) {
        console.error("Could not delete team member.", error);
    } finally {
        setIsLoading(false);
    }

    window.location.reload();
  }

 // }

  const handleEditClick = (teamId) => {

    navigate(`team-edit/${teamId}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      const file = e.target.files[0];
      
          const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
          if (!validImageTypes.includes(file.type)) {
              alert("Please upload a valid image file (JPEG, PNG, GIF).");
              e.target.value = ""; // Clear the input
              return;
          };
        
          if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
              alert("Image size is too heavy. Please upload an image less than 2MB.");
              e.target.value = ""; // Clear the input
          }else{
              setTeamMember({ ...teamMember, [name]: e.target.files[0] });
          }
    } else {
      setTeamMember({ ...teamMember, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const formData = new FormData();
    formData.append('name', teamMember.name);
    formData.append('position', teamMember.position);
    formData.append('bio', teamMember.bio);
    formData.append('facebookUrl', teamMember.facebookUrl);
    formData.append('twiiterUrl', teamMember.twiiterUrl)
    formData.append('linkedinUrl', teamMember.linkedinUrl)
    formData.append('image', teamMember.image);

    // console.log(formData)

    try {
      const data =  await  postTeam(formData);
      // Handle success response
      setMessage("Team member added successfully");

      setLoading(false)
      window.location.reload();

    } catch (error) {
      // Handle error
      console.error('Error adding team member:', error);
      setError(error.message || "Something went wrong.");
      setLoading(false);  }

  };





  

  return (
    <div>
      <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Team</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          <button  style={{color:'white', background:'green', padding:10, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon> New Team</button>
          </div>
        </div>
      </div>

      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>
        <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <p style={{ fontWeight: 600, color:'#1A2F3E'}}>We found <span>{teams.length} </span> team member</p>
          </div>
        </div>
{/* 
        <div className="col-6 text-end d-flex justify-content-between "> 
        
            <StyledSelect className="m-1" value={selectedSortOrder} onChange={handleSortChange}>
              <option value="asc">Sort by Date  (Ascending)</option>
              <option value="desc">Sort by Date (Descending)</option>
            </StyledSelect>
            <StyledSelect className="m-1" value={selectedTag} onChange={handleTagChange}>
              <option value="">All location</option>
              {uniqueTags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </StyledSelect>
        </div> */}
      </div>
     

      <div >
     {/* <Button color="primary" onClick={openModal}>Add Team Member{" "}       
      </Button>
      <CreateTeamModal isOpen={modalOpen} toggle={closeModal} /> */}
    </div>
    <div className="row ht-team-member-style-two">
              {teams.map((member) => (
                <div
                  key={member.id}
                  className="col-lg-4 col-md-6 col-sm-6 max-mb-50"
                >

                  <div className="grid-item">
                    <div className="team-member">
                      <div className="team-info text-center">
                        <img
                          className=""
                          src={member.image}
                          alt={member.name} 
                          style={{
                            objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                            width: '100%', // Ensure the image fills the container's width
                            height: '400px', // Ensure the image fills the container's height
                            borderRadius: 20,
                          }}
                          onError={(e) => {
                                  e.target.src = `${altImage}`;
                                }}
                            height={"400px"} 
                        />
                        <div className="social-networks">
                          {/*<div className="inner">
                            {teamMembers.socialMedia.map((media, index) => (
                              <Link
                                key={index}
                                target="_blank"
                                className={`hint--bounce hint--top hint--theme-two`}
                                aria-label={media}
                              >
                                <i className={`fab fa-${media}`}></i>
                              </Link>
                            ))}
                            </div>*/}
                        </div>
                      </div>
                      <div className="team-info text-center m-2">
                        <h5 className="name">{member.name}</h5>
                        <div className="position">{member.position}</div>

                        <div className="bio two-line-ellipsis">{member.bio}</div>
                        <div className="social">{member.facebookUrl}</div>
                        <div className="social">{member.twiiterUrl}</div>
                        <div className="social">{member.linkedinUrl}</div>





                      </div>
                      <div className='team-info text-center'>
                      
                       
                      <Button color="primary" onClick={()=> handleEditClick(member.id)}>Edit {" "}       
                    </Button>
                                      
                        <button className="btn btn-danger m-1" onClick={()=>showModal(member.id)}>Delete</button>
                        <Modal
                          title={<div style={{ textAlign: 'center' }}>Deletion confirmation</div>}
                          open={openModal}
                          onCancel={hideModal}
                          footer={null}
                        >
                          <p style={{ textAlign: 'center' }}>Are you sure you want to delete this team member?</p>
                          <div style={{ textAlign: 'center' }}> 
                          <Button className='btn btn' onClick={hideModal}>No</Button>                          
                          <Button className="btn btn-danger m-1" onClick={() => handleDelete(selectedItem)}>Yes </Button>
                          </div>

                        </Modal>
                      </div>

                    </div>
                  </div>
                </div>
              ))}
            </div>

      </div>
      <div>
      <Drawer
        title="Add a new event"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            
          </Space>
        }
      >
         {/* <Modal className='mt-5 pt-5' isOpen={isOpen} toggle={toggle}>
      <ModalHeader className='' toggle={toggle}>Add New Team Member</ModalHeader>
      <ModalBody> */}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input type="text" placeholder='Name' name="name" id="name" onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='Position' name="position" id="position" onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Input type="textarea" placeholder='Bio' name="bio" id="bio" onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='facebook url' name="facebookUrl" id="facebookUrl" onChange={handleChange}  />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='twitter url' name="twiiterUrl" id="twiiterUrl" onChange={handleChange}  />
            </FormGroup>
            <FormGroup>
              <Input className='' type="text" placeholder='linkedin url' name="linkedinUrl" id="linkedinUrl" onChange={handleChange}  />
            </FormGroup>
            <FormGroup>
              <Label for="image">Image</Label>
              <Input type="file" name="image" id="image" onChange={handleChange} required />
            </FormGroup>
            <div className="d-grid col-12 mx-autom-2">
                          {error && (
                            <div className="alert alert-danger" role="alert">
                              {error}
                            </div>
                          )}
                          {message && (
                            <div className="alert alert-success" role="alert">
                              {message}
                            </div>
                          )}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-success btn-lg m-2"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-lg"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <Button className='m-2' color="secondary" onClick={onClose}>Cancel</Button>

          </Form>
        {/* </ModalBody>
        <ModalFooter> */}
        {/* </ModalFooter>
      </Modal> */}
        
      </Drawer>
      </div>

    </div>
  )
}

export default Team