import React from "react";
import { Link, useNavigate } from "react-router-dom";
import cta1 from "../../assets/img/brand/cta-shape-01.png";
import cta2 from "../../assets/img/brand/cta-shape-2.png";

function BFooter() {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="cta-section section section-padding"
        data-bg-color="#f5f1ed"
        style={{ backgroundColor: "#f5f1ed" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cta-content text-center">
                <span className="sub-title">
                  Start today for getting your <span>Certifications</span>
                </span>
                <h2 className="title">
                  <b>Reach out to us!</b>
                </h2>
                <div>
                  <button
                    className="btn btn-success btn-hover-secondary btn-width-300 m-1"
                    onClick={() => navigate("/courses")}
                  >
                    Explore our Courses
                  </button>
                  <button
                    className="btn btn-success btn-hover-secondary btn-width-300 m-1 "
                    onClick={() => navigate("/customize")}
                  >
                    Customize your Course
                  </button>
                </div>

                {/* Animation Shape Start */}
                <div className="shape shape-1 scene">
                  <span data-depth="4">shape 1</span>
                </div>
                <div className="shape shape-2 scene">
                  <span data-depth="4">
                    <img src={cta1} alt="" />
                  </span>
                </div>
                <div className="shape shape-3 scene">
                  <span data-depth="4">
                    <img src={cta2} alt="" />
                  </span>
                </div>
                {/* Animation Shape End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BFooter;
