import React,{useState, useEffect} from 'react';
import { getTeam, delTeam, editTeam, postTeam, getUsers, postUsers, setUserInactive, deleteUser } from '../../../api/UserAPI';
import { useFetchData } from '../hooks/useFetchData';
import { GenericDataManagementComponent } from '../hooks/GenericBlocs';
import CreateTeamModal from './modals/TeamsModal';
import { useNavigate } from 'react-router-dom';
import EditTeamModal from './modals/EditTeamModal';
import { Link } from 'react-router-dom';
import { Drawer, theme, Modal, Space } from 'antd';
import { Form, Input,  Button, Alert, Spin, Select,Table, message } from 'antd';
//import { Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';

import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import altImage from "../../../assets/img/team/profile.jpeg";
import {  StyledSelect, } from "../../../components/StyledComponents";

const { Option } = Select;

const UserManagement = () => {
  //const { data: teamMembers, isLoading, notFound } = useFetchData(getTeam);
  const [modalOpen, setModalOpen] = useState(false);

  //const toggleModal = () => setModalOpen(!modalOpen);
  //const openModal = () => setModalOpen(true);
  //const closeModal = () => setModalOpen(false);
  const [teams, setTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noTeamFound, setNoTeamFound] = useState(false);
  const navigate = useNavigate();
  const [messageData, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [teamImage, setTeamIamge] = useState();
  
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [selectedItem, setSelectedItem] = useState(null);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showModal = (id) => {
    setSelectedItem(id)
    setOpenModal(true);
  };
  const hideModal = () => {
    setSelectedItem(null)
    setOpenModal(false);
  };


  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const response = await getUsers(); // Adjust this endpoint
  //       setUsers(response);
  //       // console.log(response)
  //     } catch (error) {
  //       message.error('Failed to fetch users.');
  //       console.log(error.detail)
  //     }
  //   };

  //   fetchUsers();
  // }, []);
  useEffect(() => {
    const fetchUsers = async () => {

      const response = await getUsers();
      if (response.error) {
        setError(response.error);  // Assuming setError is a state function
        message.error(response.error);
      } else {
        setUsers(response.results);
      }
    };
  
    fetchUsers();
  }, [refresh]);
  

  const handleDelete = async(id) => {
    try {
      await deleteUser(id);
      message.success('User deleted successfully');
      // Optionally, redirect or update UI after deletion
      setRefresh(prev => !prev); 
    } catch (err) {
      setError(err.message);
      message.error(err.message);
    }
    };
    // Handle delete logic
  const handleEditClick = (id) => {
    navigate(`user-edit/${id}`);


  }


  const columns = [
    {
      title: 'Firstname',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Lastname',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User type',
      dataIndex: 'user_type',
      key: 'user_type',
    },
    {
      title: 'Is a Superuser',
      dataIndex: 'is_superuser',
      key: 'is_superuser',
      render: (is_superuser) => (is_superuser ? 'Yes' : 'No'), // Render 'Yes' or 'No'

    },
   
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button type="primary" onClick={() => handleEditClick(record.id)}>Update</Button>
          <Button type="danger" onClick={() => showModal(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ];



//new code
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
    setMessage(null)
    //window.location.reload()
    setRefresh(prev => !prev); 
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      // Perform form submission logic here
      const { confirmPassword, ...submitValues } = values;
      const response = await postUsers(submitValues);
      if (response.error ){
        setError(null);

      }
      console.log('Form values:', response);
      setMessage('Staff added successfully');
      setError(null);
      form.resetFields();
    } catch (err) {
      setError(err);
      setMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (changedFields) => {
    console.log('Changed fields:', changedFields);
  };

  

  return (
    <div>
      <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Staff Management</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          <button  style={{color:'white', background:'green', padding:10, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon>  Add new staff</button>
          </div>
        </div>
      </div>

      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>
        <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <p style={{ fontWeight: 600, color:'#1A2F3E'}}>We found <span>{users.length} </span> users</p>
          </div>
        </div>
    </div>
    <div className="row ht-team-member-style-two">

      {Array.isArray(users) && users.length > 0 ?(
        <Table columns={columns} dataSource={users} rowKey="id" pagination={false} />

      ):(
        <p>
          {error}
        </p>
      )  }

          
            <Modal
              title={<div style={{ textAlign: 'center' }}>Deletion Confirmation</div>}
              open={openModal}
              onCancel={hideModal}
              footer={null}
            >
              <p style={{ textAlign: 'center' }}>Are you sure you want to delete this team member?</p>
              <div style={{ textAlign: 'center' }}> 
                <Button onClick={hideModal}>No</Button>                          
                <Button type="danger" onClick={() => handleDelete(selectedItem)}>Yes</Button>
              </div>
            </Modal>
              
            </div>

      </div>
      <div>

     
      <Drawer
        title="Add a new staff"
        width={720}
        onClose={handleClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onFieldsChange={(_, allFields) => handleChange(allFields)}
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: 'Please enter first name' }]}
          >
            <Input placeholder="First name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Please enter last name' }]}
          >
            <Input placeholder="Last name" />
          </Form.Item>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please enter username' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please enter email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="user_type"
            label="User Type"
            rules={[{ required: true, message: 'Please select user type' }]}
          >
            <Select placeholder="Select user type">
              <Option value="admin">Admin</Option>
              <Option value="instructor"  >Instructor</Option>
              <Option value="student">Student</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please enter password' }]}
            hasFeedback
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          {error && <Alert message={error} type="error" showIcon />}
          {messageData && <Alert message={messageData} type="success" showIcon />}
          <Form.Item>

            <Button type="primary" htmlType="submit" loading={loading} disabled={!!error}>
              {loading ? <Spin /> : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>


      {/* <Drawer
        title="Add a new staff"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            
          </Space>
        }
      >
         
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input type="text" placeholder='First name' name="firstname" id="firstname" onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='Last Name' name="lastname" id="last" onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='Username' name="username" id="username" onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='Email' name="email" id="email" onChange={handleChange} required />
            </FormGroup>
            
            <FormGroup>
              <Input type="text" placeholder='user-type' name="usertype" id="usertype" onChange={handleChange}  />
            </FormGroup>
            
            <FormGroup>
              <Input className='' type="password" placeholder='Password' name="password" id="password" onChange={handleChange}  />
            </FormGroup>
            
            <div className="d-grid col-12 mx-autom-2">
                          {error && (
                            <div className="alert alert-danger" role="alert">
                              {error}
                            </div>
                          )}
                          {message && (
                            <div className="alert alert-success" role="alert">
                              {message}
                            </div>
                          )}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-success btn-lg m-2"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-lg"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <Button className='m-2' color="secondary" onClick={onClose}>Cancel</Button>

          </Form>

      </Drawer> */}
      
      </div>

    </div>
  )
}

export default UserManagement