import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../layout/navbar/HeaderSection";
import AfterHeader from "../layout/bgheader/AfterHeader";
import CourseMode from "../layout/bgheader/CourseMode";
import BgImageWithNavbar from "../layout/bgheader/BgImageHeader";
import IntroSection from "../layout/bgheader/IntroSection";
import OverviewTab from "./tabs/OverviewTab";
import CurriculumTab from "./tabs/CurriculumTab";
import ReviewsTab from "./tabs/ReviewsTab";
import InstructorTab from "./tabs/InstructorTab";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import FooterSection from "../layout/footer/FooterSection";
import coursesData from "./jsonfiles/coursesData.json";
import CourseModal from "./modals/CourseModal";
import CourseOutline from "../assets/Principles.pdf";
import { StyledAnchor } from "../components/StyledComponents";
import { getCourses, getCourses1 } from "../api/UserAPI";
import altImage from "../assets/img/brand/courses-image.jpg";
import { StyledDiv } from "../components/StyledComponents";
import { AiFillStar } from "react-icons/ai";
import generatePDF, { Margin } from "react-to-pdf";

const CourseDetails = () => {
  const navigate = useNavigate();
  const targetRef = useRef();

  const getId = useParams();
  const Id = getId.courseId;

  //const course = coursesData.find((course) => course.id.toString() === id);
  const [activeTab, setActiveTab] = useState("overview");
  const [title, setTitle] = useState("");
  const [course, setCourse] = useState("");
  const [courseModal, setCourseModal] = useState(false);
  const [related_courses, setRelatedCourses] = useState([]);

  const options = {
    // method: "open",
    page: {
      margin: Margin.SMALL,
    },
    filename: `${course.title}.pdf`,
  };

  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const response = await getCourses1(Id);
        console.log(response);
        setCourse(response);
        const allCourses = await getCourses();
        const filteredCourses = allCourses
          .filter(
            (course) =>
              course.category_name === response.category_name &&
              course.id !== response.id
          )
          .slice(0, 4);

        setRelatedCourses(filteredCourses);
      } catch (error) {
        if (!course) return <div>Course not found</div>;
      }
    };

    fetchSelectOptions();
  }, []);

  const toggleCourseModal = () => {
    setCourseModal(!courseModal);
  };
  const handleSetCourseModal = (value) => {
    setTitle(value);
    setCourseModal(true);
  };

  // Function to change the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function numberWithSeparator(numberString) {
    // Convert numberString to string if it's not already a string
    if (typeof numberString !== "string") {
      numberString = String(numberString);
    }

    // Check if numberString contains a decimal point
    const hasDecimal = numberString.includes(".");

    // Split the string into integer and fractional parts (if any)
    const parts = numberString.split(".");

    // Format the integer part with thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and fractional parts (if any)
    return parts.join(hasDecimal ? "." : "");
  }

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
    window.location.reload();
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      {/* <div>
        <header style={{ paddingLeft: 0 }}>
          <div
            className="p-5 bg-image mb-5 mt-5"
            style={{
              backgroundColor: "green",
              backgroundImage: "url('')", //https://i.ibb.co/PYkxy7h/about-us.png
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover", // Added to ensure the image covers the entire container
              height: 400,
            }}
          >
            <div className="d-flex justify-content-center align-items-center  m-5">
              <div className="text-white text-center m-5">
                <h1 className="mb-3"> </h1>
                <p className="mb-3">
                  Grow strong to take up the challenges of life. The top lessons
                  for getting you back on track.
                </p>
              </div>
            </div>
          </div>
        </header>
      </div> */}

      <div class="section">
        <div class="container" style={{ marginTop: "100px" }}>
          <div class="row max-mb-n50">
            <div class="col-lg-8 col-12 order-lg-1 max-mb-50">
              <div class="course-details-wrapper">
                <div class="course-nav-tab">
                  <div>
                    <h3 className="title">{course.title} </h3>
                  </div>

                  <ul class="nav">
                    <li>
                      <a
                        className={activeTab === "overview" ? "active" : ""}
                        onClick={() => handleTabClick("overview")}
                        href="#"
                        data-bs-toggle="tab"
                        style={{ textDecoration: "none" }}
                      >
                        Overview
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tab"
                        className={activeTab === "curriculum" ? "active" : ""}
                        onClick={() => handleTabClick("curriculum")}
                        href="#"
                        style={{ textDecoration: "none" }}
                      >
                        Curriculum
                      </a>
                    </li>
                    {/* <li>
                      <a
                        data-bs-toggle="tab"
                        className={activeTab === "reviews" ? "active" : ""}
                        onClick={() => handleTabClick("reviews")}
                        href="#"
                      >
                        Reviews
                      </a>
                    </li> */}
                  </ul>
                </div>

                <div class="tab-content" ref={targetRef}>
                  {activeTab === "overview" && <OverviewTab course={course} />}
                  {activeTab === "curriculum" && (
                    <CurriculumTab course={course} />
                  )}
                  {activeTab === "reviews" && <ReviewsTab />}
                  {activeTab === "instructor" && <InstructorTab />}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-12 order-lg-2 max-mb-50"
              id="sticky-sidebar"
            >
              <div className="sidebar-widget-wrapper pr-0">
                <div className="sidebar-widget">
                  <div className="sidebar-widget-content">
                    <div className="sidebar-entry-course-info">
                      <div className="course-price">
                        <span className="meta-label">
                          <i class="meta-icon far fa-money-bill-wave"></i> Price
                        </span>
                        <span className="meta-value">
                          <span className="price">
                            <span>₦{numberWithSeparator(course.price)}</span>
                            <span style={{ fontSize: 13 }}>.00</span>
                            <span className="decimals-separator"></span>
                          </span>
                        </span>
                      </div>
                      <div className="course-meta">
                        <div className="course-duration">
                          <span className="meta-label">
                            <i class="far fa-clock"></i> Duration
                          </span>
                          <span className="meta-value">
                            {" "}
                            {course.duration}{" "}
                          </span>
                        </div>

                        <div className="course-students">
                          <span className="meta-label">
                            <i class="far fa-user-alt"></i> Target Audiance
                          </span>
                          <span className="meta-value">{course.target_audience_name} </span>
                        </div>
                        <div className="course-language">
                          <span className="meta-label">
                            {/* This icon is set to hidden in your HTML. Adjust as necessary. */}
                            <i class="far fa-language"></i> Location
                          </span>
                          <span className="meta-value">{course.venue}</span>
                        </div>
                        <div className="course-time">
                          <span className="meta-label">
                            <i class="far fa-calendar"></i> Date
                          </span>
                          <span className="meta-value">{course.starting_date} - {course.ending_date}</span>
                        </div>
                      </div>
                      <hr />
                      <div className="course-meta">
                        <h4 className="title">Options and Brochure</h4>
                      </div>
                      <div className="lp-course-buttons">
                        <button
                          className="btn btn-outline-success btn-hover-secondary btn-width-100"
                          onClick={() => handleSetCourseModal("Register")}
                        >
                          Register for the Course
                        </button>
                      </div>
                      <div className="lp-course-buttons">
                        <button
                          className="btn btn-outline-success btn-hover-secondary btn-width-100 icon-right"
                          onClick={() => handleSetCourseModal("Enquire")}
                        >
                          Enquire about the Course
                        </button>
                      </div>
                      <div className="lp-course-buttons">
                        <button
                          onClick={() => generatePDF(targetRef, options)}
                          className="btn btn-outline-secondary btn-hover-secondary btn-width-100"
                        >
                          Download PDF
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <div className="">
          <div className="container">
            <div className="section-title text-center mb-35" data-aos="">
              <h3 className="title">You may also be interested in​</h3>
            </div> */}
        <div className="section section-padding section section-padding text-center">
          <div className="container">
            <div className="section-title text-center" data-aos="fade-up">
              <span className="sub-title">You may also be interested in</span>
            </div>

            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 max-mb-5 justify-content-center">
              {Array.isArray(related_courses) && related_courses.length > 0 ? (
                related_courses.map((course) => (
                  <StyledDiv
                    key={course.id}
                    className=""
                    data-aos="fade-up"
                    onClick={() => handleCourseClick(course.id)}
                  >
                    <div className="course-4 text-decoration-none ">
                      <div
                        className="thumbnail"
                        onClick={() => handleCourseClick(course.id)}
                      >
                        <Link className="image">
                          <img
                            src={course.imageUrl}
                            alt={course.code}
                            onError={(e) => {
                              e.target.src = `${altImage}`;
                            }}
                          />
                        </Link>
                      </div>
                      <div className="info">
                        <span className="price">
                          ₦{numberWithSeparator(course.price)}{" "}
                          <span style={{ fontSize: 13 }}>.00</span>
                        </span>

                        <div className="course-instructor">
                          <div className="instructor-avatar">
                            <span className="instructor-name">
                              {course.category_name}
                            </span>{" "}
                          </div>
                        </div>
                        <h6
                          className=""
                          onClick={() => handleCourseClick(course.id)}
                        >
                          <Link
                            className=" text-decoration-none"
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              maxLines: "2",
                              overflow: "hidden",
                              display: "-webkit-box",
                              webkitBoxOrient: "vertical",
                              webkitLineClamp: "2",
                            }}
                          >
                            {course.title}
                            {course.is_featured && (
                              <AiFillStar
                                style={{ marginLeft: "0.5rem", color: "gold" }}
                              />
                            )}
                          </Link>
                        </h6>
                        <ul className="meta">
                          <li>
                            <i className="far fa-file-alt"></i> Location:
                            {course.venue}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </StyledDiv>
                ))
              ) : (
                <p>No similar course(s) found ... </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <FooterSection />
      </div>
      <CourseModal
        isOpen={courseModal}
        toggle={toggleCourseModal}
        title={title}
        course={course.title}
      />
    </div>
  );
};

export default CourseDetails;
