import React, { useState } from "react";
import NavbarHome from "../layout/navbar/Navbar";
import BeforeFooter from "../layout/footer/BeforeFooter";
import Footer from "../layout/footer/Footer";
import BFooter from "../layout/footer/BFooter";
import CourseMode from "../layout/bgheader/CourseMode";
import FooterSection from "../layout/footer/FooterSection";
import Header from "../layout/navbar/HeaderSection";
import coursesData from "./jsonfiles/courses.json";
import reviewsData from "./jsonfiles/reviewData.json";
import { StyledLink, StyledAnchor } from "../components/StyledComponents";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// Example courses data
const courses = [
  {
    id: 1,
    title: "Introduction to React",
    description: "Learn the basics of React.",
  },
  {
    id: 2,
    title: "Advanced React",
    description: "Dive deeper into React and its ecosystem.",
  },
  // Add more courses as needed
];

// Example reviews data
const reviews = [
  { id: 1, user: "John Doe", review: "Great course, learned a lot!" },
  { id: 2, user: "Jane Smith", review: "Highly recommend this for beginners." },
  // Add more reviews as needed
];

function Stories() {
  const [filter, setFilter] = useState({ venue: "", category: "", price: 0 });
  const [activeTab, setActiveTab] = useState("overview");

  const venues = [
    "New York",
    "Boston",
    "Chicago",
    "San Francisco",
    "Los Angeles",
  ];
  const categories = ["Technology", "Business", "Health", "Art"];
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;

  // Calculate the current posts to display
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = coursesData.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(coursesData.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
  };

  return (
    <div>
      <div className="mt-5">
        <Header />
      </div>

      <div
        class="page-title-section section pt-5"
        data-bg-color="#f5f5f5"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <div class="page-breadcrumb position-static">
          <div class="container">
            <ul class="breadcrumb">
              <li>
                <a href="nnpcacademy">Home</a>
              </li>
              <li class="current">Success Story</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        id="video-section-1"
        class="section position-relative"
        data-bg-color="#f5f5f5"
        style={{ backgroundColor: "#f5f5f5", marginBottom: "20px" }}
      >
        <div class="container">
          <div class="section-title text-center" data-aos="fade-up">
            <span class="sub-title">Success story</span>
            <h2 class="title">
              The biggest turning point in my life:{" "}
              <br class="d-none d-md-block" /> Wonderful choice.
            </h2>
          </div>
        </div>

        {/* Section Bottom Shape Start */}
        <div class="section-bottom-shape d-md-block d-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            height="100"
          >
            <path
              class="elementor-shape-fill"
              d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"
            ></path>
          </svg>
        </div>
        {/* Section Bottom Shape End */}
      </div>

      <div>
        <div className="section section-padding-bottom fix mt-5">
          <div className="container">
            <div className="row max-mb-n50">
              <div className="col-lg-4 col-12 order-lg-1 max-mb-50">
                <div className="sidebar-widget-wrapper">
                  {/* <div
                    className="sidebar-widget"
                    style={{
                      backgroundColor: "#159351",
                      padding: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    <h5 className="title mb-2" style={{ color: "white" }}>
                      Are you Looking for a Specific Course in your Preferred
                      Location?
                    </h5>
                    <div className="sidebar-widget-content">
                      <div className="sidebar-widget-search">
                        <form action="#">
                          <input type="text" placeholder="Search course..." />
                          <button>
                            <i className="fas fa-search"></i>
                          </button>
                        </form>
                        <div className="lp-course-buttons">
                          <button className="btn btn-dark btn-hover-secondary btn-width-100">
                            View Course
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div
                    className="sidebar-widget"
                    style={{
                      backgroundColor: "#1A2F3E",
                      padding: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    <h5 className="title mb-2" style={{ color: "white" }}>
                      Do you already know what course is more suitable for you?
                    </h5>
                    <div className="sidebar-widget-content">
                      <div className="lp-course-buttons">
                        <button
                          className="btn btn-success btn-hover-secondary btn-width-100"
                          onClick={() =>
                            (window.location.href =
                              "mailto:nnpcacademy@nnpcgroup.com?subject=Your%20Subject%20Here")
                          }
                        >
                          Email Us
                        </button>
                      </div>
                      <div className="lp-course-buttons">
                        <button
                          className="btn btn-success btn-hover-secondary btn-width-100"
                          onClick={() => navigate(`/contact-us`)}
                        >
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-12 order-lg-2 max-mb-50">
                <div className="row row-cols-1 g-0">
                  <div
                    className="blog-3 blog-details col"
                    data-aos="fade-up"
                    style={{ borderBottom: "none" }}
                  >
                    <div className="info" style={{ paddingTop: "0px" }}>
                      <div className="desc">
                        <p>
                          NNPC Academy have provided training courses to
                          thousands of professionals from various industries and
                          governments worldwide, and we have consistently
                          received excellent feedback for the outstanding
                          quality and value of the training courses delivered.
                        </p>
                        <p>
                          Here are some feedback by past course participants
                          after successful completion of events. We continuously
                          strive for success and we are always encouraged when
                          we receive positive feedback.
                        </p>
                        <p>
                          Get involved with trainings courses by attending one
                          of our upcoming trainings, If you are yet to book in
                          for a training course, we would be delighted to hear
                          from you. Please{" "}
                          <strong>
                            <StyledLink to="/contact-us">click here</StyledLink>
                          </strong>{" "}
                          to request more information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-12 order-lg-1 max-mb-50">
                  <div class="course-details-wrapper">
                    {/* <div className="course-nav-tab">
                      <ul
                        className="nav"
                        // style={{ justifyContent: "space-evenly" }}
                      > */}
                    {/* <li><a data-bs-toggle="tab" href="#overview" className={`nav-link ${activeTab === 'overview' ? 'active' : ''}`} onClick={() => setActiveTab('overview')}>Courses</a></li> */}
                    {/* <li>
                          <a
                            data-bs-toggle="tab"
                            href="#overview"
                            className={`nav-link ${
                              activeTab === "reviews" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("overview")}
                          >
                            Stories
                          </a>
                        </li>
                      </ul>
                    </div> */}

                    <div className="tab-content">
                      {activeTab === "overview" && (
                        <div className="tab-pane active">
                          <div className="row row-cols-1 g-0">
                            {/*currentPosts.map((blog) => (
                              <div
                                className="blog-3 col"
                                key={blog.id}
                                data-aos=""
                              >
                                <div className="row row-cols-lg-2 row-cols-1 max-mb-n30">
                                  <div className="col max-mb-30">
                                    <div className="thumbnail">
                                      <a href="#" className="image">
                                        <img
                                          src={blog.imageUrl}
                                          alt="Blog Image"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col max-mb-30">
                                    <div className="info pt-0">
                                      <h4 className="">
                                        <StyledAnchor href="#">
                                          {blog.title}
                                        </StyledAnchor>
                                      </h4>
                                      <div className="desc">
                                        <p>{blog.context}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))*/}
                            <nav>
                              <ul className="pagination justify-content-center">
                                {pageNumbers.map((number) => (
                                  <li key={number} className="page-item m-2 ">
                                    <HashLink
                                      onClick={() => paginate(number)}
                                      to={"/stories/#top"}
                                      className="page-link"
                                    >
                                      {number}
                                    </HashLink>
                                  </li>
                                ))}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      )}

                      {activeTab === "reviews" && (
                        <div className="tab-pane active">
                          <div className="col-lg-12">
                            <ol className="comment-list">
                              {reviewsData.map((review) => (
                                <li
                                  className="comment border-bottom"
                                  key={review.id}
                                >
                                  <div className="comment-2">
                                    <div className="comment-author vcard">
                                      <img
                                        src="https://via.placeholder.com/75"
                                        className=" rounded-circle"
                                      />{" "}
                                    </div>
                                    <div className="comment-content">
                                      <div className="meta">
                                        <h6 className="fn">
                                          {review.authorName}
                                        </h6>
                                      </div>
                                      <div className="comment-text">
                                        <p>{review.commentText}</p>
                                      </div>
                                      <div className="comment-actions">
                                        <StyledAnchor
                                          className="comment-reply-link"
                                          href="#"
                                          style={{ marginLeft: 0 }}
                                        >
                                          {review.commentTitle}
                                        </StyledAnchor>
                                        <span> | </span>
                                        <div
                                          className="comment-datetime"
                                          style={{
                                            marginRight: 0,
                                            marginLeft: 10,
                                          }}
                                        >
                                          {review.commentDate}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ol>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
      <div>
        <hr />
      </div>

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default Stories;
