import React from "react";
import CountUp from "react-countup";
import Navbar from "../layout/navbar/Navbar";
import Footer from "../layout/footer/Footer";
import BeforeFooter from "../layout/footer/BeforeFooter";
import Header from "../layout/navbar/HeaderSection";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import BFooter from "../layout/footer/BFooter";
import FooterSection from "../layout/footer/FooterSection";
import Banner from "../assets/img/banner/about-s.jpg";
import Brochure from "../assets/2024BROCHURE.pdf";
import grid from "../assets/img/brand/grid.png";
import cta1 from "../assets/img/brand/cta-shape-01.png";

function AboutUs() {
  return (
    <div>
      <div>
        <Header />
      </div>

      {/* Page Banner Section Start  */}
      <div className="mt-5">
        <header className="" style={{ paddingLeft: 0 }}>
          <div
            className="pt-5 bg-image"
            style={{
              backgroundColor: "green",
              height: 500,
              background: `url(${Banner}) top / cover no-repeat green`,
              backgroundSize: "cover",
              boxShadow: `inset 0 0 0 2000px rgba(0, 0, 0, 0.7)`,
            }}
          >
            <div className="d-flex justify-content-center align-items-center m-5">
              <div className="text-white text-center m-5 pt-5">
                <h1 className="mb-3">About Us</h1>
                <p className="mb-3" style={{ fontSize: "18px" }}>
                  Grow strong to take up the challenges of life. The top lessons
                  for getting you back on track.
                </p>
              </div>
            </div>
          </div>
        </header>
      </div>
      {/* <Page Banner Section End  */}

      {/* Page Title Section Start  */}
      <div class="page-breadcrumb-no-title-section section">
        <div class="page-breadcrumb">
          <div class="container">
            <ul class="breadcrumb mt-3">
              <li>
                <a href="nnpcacademy">Home</a>
              </li>
              <li class="current">About us</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Page Title Section End */}

      <div>
        <div
          className="about-video-section section-padding-top-190 section-padding-bottom"
          style={{ overflow: "inherit" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="about-me-video-wrapper about-us-one-video pr-55 pr-sm-0 pr-xs-0">
                  <iframe
                    width="100%"
                    height="390"
                    src="https://www.youtube.com/embed/kAaBW8Ci090"
                    title="Fueling Progress - NNPC Ltd.&#39;s 2023 Triumphs Unveiled"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    className="about-me-video video-popup"
                  ></iframe>

                  <div className="shape shape-1 scene">
                    <span data-depth="3">
                      <img
                        src="assets/images/shape-animation/shape-2.svg"
                        alt=""
                        className="svgInject"
                      />
                    </span>
                  </div>
                  <div className="shape shape-2 scene">
                    <span data-depth="-3">
                      <img src="https://i.ibb.co/HNcV7jZ/shape-3.png" alt="" />
                    </span>
                  </div>
                  <div className="shape shape-3 scene">
                    <span data-depth="4">shape 3</span>
                  </div>
                  <div className="shape shape-4 scene">
                    <span data-depth="4">
                      <img src="https://i.ibb.co/gzr1LWN/shape-1.png" alt="" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="about-content mt-sm-50 mt-xs-50">
                  <span className="sub-title">
                    Get to know about <strong>NNPC Academy</strong>
                  </span>
                  <h2 className="title">
                    Learn about our<span> Work Culture</span> at NNPC Academy
                  </h2>
                  <p>
                    NNPC Academy is the training and consultancy arm of
                    Nigeria’s apex energy company, NNPC Ltd. We are a dynamic
                    learning and development institution that offers unique
                    value propositions to our learning clientele at very
                    competitive rates with guaranteed optimal return on training
                    investment and customer delight.
                  </p>
                  <Link
                    style={{ textDecoration: "none" }}
                    className="link link-lg"
                    to={Brochure}
                    target="_blank"
                  >
                    <mark>
                      Download our Brochure{" "}
                      <i className="far fa-long-arrow-right"></i>
                    </mark>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="about-section section section-padding-top section-padding-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 order-lg-1 order-2">
                <div className="about-content mt-sm-50 mt-xs-50">
                  <span className="sub-title">how we work</span>
                  <h2 className="title">
                    What makes us <span>Different</span>
                  </h2>
                  <p>
                    Training Dexterity, structured series of modules, learner
                    centric delivery methodologies, clear concise training
                    materials, Informative video presentations, simulated
                    learning, strategic syndicate sessions, practical case
                    studies, business focused in-class exercise,
                    <span>
                      {" "}
                      opportunities for prolific post-training networking and
                      mentorship.
                    </span>
                  </p>
                </div>
              </div>

              <div
                className="col-lg-6 col-md-12 order-lg-1 order-1"
                data-aos="fade-up"
              >
                <div className="about-image">
                  <div className="about-image-one">
                    <img
                      src="https://i.ibb.co/yg1hzGD/successful-diverse-business-people-discussing-contract.jpg"
                      alt="about01"
                    />
                  </div>
                  <div className="about-image-two">
                    {/* <img src="" alt="about02" /> */}
                  </div>

                  {/* Animation Shape Start */}
                  <div className="shape shape-1 scene">
                    <span data-depth="4">shape 1</span>
                  </div>
                  <div className="shape shape-2 scene">
                    <span data-depth="4">
                      <img
                        src="https://i.ibb.co/BN0KJQz/about-shape-1.png"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="shape shape-3 scene">
                    <span data-depth="4">
                      <img
                        src="https://i.ibb.co/qjtpdqt/nwesletter-shape-2.png"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="shape shape-4 scene">
                    <span data-depth="4">
                      <img src="https://i.ibb.co/gzr1LWN/shape-1.png" alt="" />
                    </span>
                  </div>
                  {/* Animation Shape End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>

      <div>
        <div
          className="funfact-section section section-padding-top"
          style={{ backgroundColor: "#faf8f6" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="row row-cols-md-4 row-cols-1 max-mb-n30">
                  {/* Funfact Start */}
                  <div className="col max-mb-30" data-aos="fade-up">
                    <div className="funfact">
                      <div className="number">
                        <span className="counter">
                          <CountUp delay={3} end={126} duration={5} />
                        </span>
                      </div>
                      <h6 className="text">Training Programs</h6>
                    </div>
                  </div>
                  {/* Funfact End */}

                  {/* Funfact Start */}
                  <div className="col max-mb-30" data-aos="fade-up">
                    <div className="funfact">
                      <div className="number">
                        <span className="counter">
                          <CountUp delay={3} end={95} duration={5} />
                        </span>
                        %
                      </div>
                      <h6 className="text">Successful Participants</h6>
                    </div>
                  </div>
                  {/* Funfact End */}

                  {/* Funfact Start */}
                  <div className="col max-mb-30" data-aos="fade-up">
                    <div className="funfact">
                      <div className="number">
                        <span className="counter">
                          <CountUp delay={3} end={200} duration={6} />
                        </span>
                      </div>
                      <h6 className="text">Certificates Issued</h6>
                    </div>
                  </div>
                  {/* Funfact End */}

                  {/* Funfact Start */}
                  <div className="col max-mb-30" data-aos="fade-up">
                    <div className="funfact">
                      <div className="number">
                        <span className="counter">
                          <CountUp delay={3} end={40} duration={5} />
                        </span>
                        +
                      </div>
                      <h6 className="text">Strategic Partners</h6>
                    </div>
                  </div>
                  {/* Funfact End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="blog-section section section-padding"
          style={{ backgroundColor: "#faf8f6" }}
        >
          <div className="container">
            <div className="row row-25 row-cols-lg-3 row-cols-md-2 row-cols-1">
              {/* Blog Start */}
              <div className="col max-mb-10" data-aos="fade-up">
                {/* Team Image Wrapper Start */}
                <div className="team-image-wrap">
                  <div className="team-image">
                    <img src={grid} alt="team" style={{ maxWidth: "110%" }} />
                  </div>
                  {/* Animation Shape Start */}
                  <div className="shape shape-1 scene">
                    <span data-depth="3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 395 397"
                        version="1.1"
                        width="395px"
                        height="397px"
                      >
                        <g transform="translate(-51.000000, -1390.000000)">
                          <g transform="translate(-28.000000, 963.000000)">
                            <path d="M353.907509,817.715288 C386.112868,835.281848 455.192882,815.572841 468.907509,740.715288 C483.008804,663.747218 442.907509,661.715288 449.907509,633.715288 C478,592 483.008804,538.76355 453.907509,506.715288 C381.907509,431.715288 363.907509,521.715288 303.907509,466.715288 C279.650772,442.458551 242.907509,413.715288 189.907509,433.715288 C114.974471,461.991907 132.907509,521.005066 132.907509,563.715288 C132.907509,578.753612 85.1423289,607.023718 79.9075093,645.715288 C73.306976,694.501115 103.907509,758.715288 132.907509,780.715288 C178.907509,832.715288 249.907509,820.715288 281.907509,802.715288 C309.384918,787.259246 331.907509,805.715288 353.907509,817.715288 Z"></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="shape shape-2 scene">
                    <span data-depth="-3">
                      <img src="https://i.ibb.co/HNcV7jZ/shape-3.png" alt="" />
                    </span>
                  </div>
                  <div className="shape shape-3 scene">
                    <span data-depth="4">shape 3</span>
                  </div>
                  <div className="shape shape-4 scene">
                    <span data-depth="4">
                      <img src="https://i.ibb.co/gzr1LWN/shape-1.png" alt="" />
                    </span>
                  </div>
                  <div className="shape shape-5 scene">
                    <span data-depth="5">
                      <img src={cta1} alt="" />
                    </span>
                  </div>
                  {/* Animation Shape End */}
                </div>
              </div>
              {/* Blog End */}

              <div className="col max-mb-30" data-aos="">
                <div className="blog-list-post-wrapper">
                  {/* Section Title Start */}
                  <div className="section-title text-left mt-25 mb-25">
                    <span className="sub-title">What we do at</span>
                    <h2 className="title">
                      NNPC<span> Academy</span>
                    </h2>
                  </div>
                  {/* Section Title End */}
                  <ul className="blog-list-post">
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Strategic Learning Needs Analysis
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Competency Engineering
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Design, Development & Delivery of Capacity Building
                        Programming
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Bespoke Learning Interventions
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Certification Program
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Blog Start */}
              <div className="col max-mb-30" data-aos="">
                <div className="blog-list-post-wrapper">
                  {/* Section Title Start */}
                  <div
                    className="section-title text-left mt-25 mb-25"
                    style={{ visibility: "hidden" }}
                  >
                    <span className="sub-title">Enjoyable insights</span>
                    <h2 className="title">
                      What we <span> do</span>
                    </h2>
                  </div>
                  {/* Section Title End */}
                  <ul className="blog-list-post">
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Blending Approach to learning
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Corporate Retreats
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Pre and Post-Training Evaluations
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Customer Focused Consultancy Services
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Post-Training Mentorship
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Blog End */}
            </div>
          </div>
        </div>
      </div>

      <div>
        <BFooter />
      </div>

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default AboutUs;
