import React, {useState, useEffect} from 'react'

import { Form, NavLink } from 'react-router-dom';
import { Button, ButtonDropdown, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {useFetchData} from '../hooks/useFetchData'
import {GenericDataManagementComponent} from '../hooks/GenericBlocs'
import { editContactUs, delContactUs, getContactUs } from '../../../api/UserAPI';
import { Drawer, theme, Modal, Space, Card, Collapse } from 'antd';
import {  StyledSelect, } from "../../../components/StyledComponents";

const Contact = () => {
  const { data: contacts, isLoading, notFound: noMessagesFound } = useFetchData(getContactUs);
  const [editModal, setEditModal] = useState(false);
  const [currentContact, setCurrentContact] = useState([]); 
  const contactsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  const [editFormData, setEditFormData] = useState({
    id: '',
    email: '',
    message: '',
    // Initialize other fields here if necessary
  });
  const [message, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [selectedSortOrder, setSelectedSortOrder] = useState('asc'); // Default to ascending order


  const showModal = (id) => {
    setSelectedItem(id)
    setOpenModal(true);
  };
  const hideModal = () => {
    setSelectedItem(null)
    setOpenModal(false);
  };

  useEffect(()=>{

    const fetchContact = async () => {

      try{

        const response = await getContactUs()
        if (response !== null){
          const contacts = Array.isArray(response) ? response : response.results || [];

          setCurrentContact(contacts);

        }else{
          setCurrentContact([])

        }
        //console.log(response)

      } catch(error){
        setMessage(error)

      }

    }
  fetchContact();

  }, [])

  const totalPages = Math.ceil(currentContact.length / contactsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
    
  const Contactspage = currentContact.slice(
    (currentPage - 1) * contactsPerPage,
    currentPage * contactsPerPage
  );

  const contactAttributes = [
    
    { label: "Email", value: "email" },
    { label: "Subject", value: "subject" },
    { label: "messgae", value: "message" },

  ];

  const handleSortChange = (e) => {
    setSelectedSortOrder(e.target.value);
  };

  const sortedBlogs = currentContact.sort((a, b) => {
    if (selectedSortOrder === 'asc') {
      return new Date(a.date) - new Date(b.date); // Sort in ascending order
    } else {
      return new Date(b.date) - new Date(a.date); // Sort in descending order
    }
  });

  
  const handleEditClick = (contact) => {
    setCurrentContact(contact);
    toggleEditModal();
  };

  const handleUpdate = async () => {
    if (!currentContact) return;

    // Call API to update contact
    await editContactUs(editFormData); // Assume this API call is correctly implemented to handle the updated data
    toggleEditModal();
    //refreshData(); // Refresh your contacts data
  };

    // Refresh data or handle UI update


  const handleDelete = async (contactId) => {
    // Confirm deletion
    // if (window.confirm("Are you sure you want to delete this contact?")) {

      
      

      try {
        // Pass the `teamId` to `delTeam`
        const response = await delContactUs(contactId);
        if (!response) {
           // setNoTeamFound(true);
        } else {
          setMessage("contact message  deleted successfully");
        }
    } catch (error) {
        console.error("Could not delete contact.", error);
    } finally {
        //setIsLoading(falsblogPerPagee);
    }
  
    window.location.reload();

      // Refresh data or handle UI update
    // }
  };

  const toggleEditModal = () => setEditModal(!editModal);



  const contactActions = [
    { label: "View", buttonClass: "btn-info", handler: handleEditClick },
    { label: "Delete", buttonClass: "btn-danger", handler: (item) => handleDelete(item.id) },
  ];


  // Function to set current contact and open modal

  const handleEdit = (contact) => {
    setEditFormData({
      id: contact.id,
      email: contact.email,
      // Set other fields here
    });
    toggleEditModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      //setPost({});
    } else {
      //setPost({ });
    }
  };
  

  return (
    <div>

      <div>{message && (
          <div className="alert alert-success" role="alert">
            {message}
          </div>
        )}
      </div>
      <div>
      <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Contact us</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          
          {/* <button  style={{color:'white', background:'green', padding:10, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon> New Event</button> */}
          </div>
        </div>
      </div>
      </div>
      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
        minHeight:500,
      }}>
          <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <p style={{ fontWeight: 600, color:'#1A2F3E'}}>We found <span>{currentContact.length} </span> contacts</p>
          </div>
        </div>
        <div className='col-6 text-end d-flex justify-content-between' >

          {/* <StyledSelect className="m-1" value={selectedSortOrder} onChange={handleSortChange}>
              <option value="asc">Sort by Date  (Ascending)</option>
              <option value="desc">Sort by Date (Descending)</option>
            </StyledSelect> */}

        </div>

        </div>
        <div className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2  row-cols-1 max-mb-n30">
           {Array.isArray(Contactspage) && Contactspage.length > 0 ?(  Contactspage.map((contact) => (
             <div
             className="col-lg-4 col-md-6 col-sm-6 max-mb-50"
              >


        <Card 
          className='mt-1 p-2'
            bordered={true} 
            style={{ 
              width: '100%', 
              border: '2px solid #ddd', // add this line
              margin: 1,
            }} 
          >
          <div style={{ 
            paddingBottom: '10px' 
          }}>
          

            <div>
            <Collapse
                size="small"
                items={[
                  {
                    label: <b>{contact.name } <br/> {contact.email} </b>,
                    children: <div>  
                      <b>Subject - {contact.subject} </b>
                      <p>{contact.message}</p>
                      </div>,
                  },
                ]}
              />
            </div>




          </div>
          <button className="btn btn-danger btn-xs" onClick={()=> showModal(contact.id)}>Delete</button>
          <Modal
            title={<div style={{ textAlign: 'center' }}>Deletion confirmation</div>}
            open={openModal}
            onCancel={hideModal}
            footer={null}
          >
            <p style={{ textAlign: 'center' }}>Are you sure you want to delete this enrollment?</p>
            <div style={{ textAlign: 'center' }}> 
            <Button className='btn btn' onClick={hideModal}>No</Button>                          
            <Button className="btn btn-danger m-1" onClick={() => handleDelete(selectedItem)}>Yes</Button>
            </div>

          </Modal>


          </Card>



              </div>

          ) ) ): (
            <p>
            Wait a little while contacts are loading         
            </p>
          )}
        </div>
        <div className='mt-3' style={{textAlign:'center'}}>
            <ul className="pagination " style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              {Array(totalPages)
                .fill(0)
                .map((_, index) => (
                  <li key={index}>
                    <a
                      href="#"
                      onClick={() => handlePageChange(index + 1)}
                      className={index + 1 === currentPage ? 'active' : ''}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
            </ul>

          </div>
      </div>
      

      {/* <div>
      <GenericDataManagementComponent
        title="Contacts "
        isLoading={isLoading}
        notFound={noMessagesFound}
        data={contacts}
        dataAttributes={contactAttributes}
        actions={contactActions}
      />
      </div> */}

      {/* <EditContactModal /> */}

        
    </div>
  )
}

export default Contact