import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {  postBlog } from '../../../../api/UserAPI';
import { useNavigate } from 'react-router-dom';

const CreateBlog = () => {
  const [post, setPost] = useState({
    title: '',
    content: '',
    tag:'',
    image: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setPost({ ...post, [name]: e.target.files[0] });
    } else {
      setPost({ ...post, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('title', post.title);
    formData.append('content', post.content);
    formData.append('tag', post.tag);

    if (post.image) {
      formData.append('image', post.image);
    }

    try {
     const blog = await postBlog(formData) 
     console.log(blog)
     if (!blog.error){
        alert('The blog is created successfully')
        navigate('/admin/blogs');
    }

      setLoading(false);
      // Reset form or redirect user
      setPost({ title: '', content: '', image: null });
      // Optionally, redirect the user or give feedback

    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.',error);
      setLoading(false);
    }
  };

  return (
    <div className='create-blog-container'>
      <h2>Create New Blog Post</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" name="title" id="title" value={post.title} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="content">Content</Label>
          <Input type="textarea" name="content" id="content" value={post.content} onChange={handleChange} style={{ minHeight: '250px' }} required />
        </FormGroup>
        <FormGroup>
          <Label for="title">Tag(s)</Label>
          <Input type="text" name="tag" id="tag" value={post.tag} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image </Label>
          <Input type="file" name="image" id="image" onChange={handleChange} />
        </FormGroup>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        <Button type="submit" color="primary" disabled={loading}>
          {loading ? 'Creating...' : 'Create Post'}
        </Button>
      </Form>
    </div>
  );
};

export default CreateBlog;
