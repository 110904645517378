import React from 'react'
import altImage from "../../assets/img/brand/courses-image.jpg";


function OverviewTab({ course }) {



  const Providers =[
    {title:"P/A #1", detail:'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.', imageurl:''},
    {title:"P/A #2", detail:'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.', imageurl:''},
    {title:"P/A #3", detail:'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.', imageurl:''},

  ]

  return (
    <div>
      <div id="overview" className="tab-pane">

        <div className="course-overview">
          
          <h4 className="title">Course Description</h4>
          {/* <p>{course.description}</p> */}
          <div dangerouslySetInnerHTML={{ __html: course.description }}></div>

          <div className="overview-course-video">
          <img src={course.imageUrl} alt=" CoursesImage "onError={(e) => { e.target.src = `${altImage}`; }} />          </div>
          <h3 className="title">More details</h3>
          {/* <p>{course.more_details}</p> */}
          <div dangerouslySetInnerHTML={{ __html: course.more_details }}></div>

          
          <hr/>
            {/*   <h3 class="title">Providers and Associations</h3> */}                
              <div class="row row-cols-xl-3 row-cols-sm-2 row-cols-1 g-0">
                  {/* Providers.map(provider =>(
                    <div class="col" data-aos="fade-up">
                        <a href="" class="icon-box text-center" data-vivus-hover>
                            <div class="icon"><img class="svgInject" src={provider.imageurl} alt=""/></div>
                            <div class="content">
                                <h3 class="title"> {provider.title} </h3>
                                <div class="desc">
                                    <p>{provider.detail}</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    
                  ) ) */}
   

                </div>


        </div>
      </div> 

    </div>
  )
}

export default OverviewTab