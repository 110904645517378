import React, { useState } from "react";
import NavbarHome from "../layout/navbar/Navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Footer from "../layout/footer/Footer";
import Header from "../layout/navbar/HeaderSection";
import FooterSection from "../layout/footer/FooterSection";
import { StyledLink } from "../components/StyledComponents";
import { PostContactUs } from "../api/UserAPI";
import emailjs from 'emailjs-com';

const center = [9.072264, 7.491302];

function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const formPayload = {
      name: formData.fullname,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };



    try {
      const response = await PostContactUs(formPayload);
      setMessage("Your message is successfully sent");

      // Handle success, such as resetting form, displaying a message, etc.
      setFormData({
        fullname: "",
        email: "",
        subject: "",
        message: "",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "Something went wrong.");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-5">
        <Header />
      </div>

      <div className="mt-5">
        <div className="contact-information-section section section-padding-bottom mt-5">
          <div className="container">
            <div className="row margin-bottom-85 mt-5">
              <div className="col-12">
                <div className="contact-title max-width-740">
                  <h2 className="title">
                    For more information about our courses, get in touch with
                    NNPC Academy via contacts
                  </h2>
                </div>
              </div>
            </div>
            <div className="row row-30 row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
              {/* Address */}
              <div className="col max-mb-30">
                <div className="contact-info">
                  <div className="icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                  <div className="info">
                    <h4 className="title"> Address</h4>
                    <span className="info-text">
                      {" "}
                      Herbert Macaulay Way P.M.B, 190, Garki, Abuja
                    </span>
                  </div>
                </div>
              </div>
              {/* Contact */}
              <div className="col max-mb-30">
                <div className="contact-info">
                  <div className="icon">
                    <i className="fal fa-phone"></i>
                  </div>
                  <div className="info">
                    <h4 className="title"> Contact</h4>
                    <span className="info-text">
                      Mobile: <strong>(+234) 815-143-9793</strong> <br />
                      Mobile: <strong>(+234) 806-530-3556</strong> <br />
                      Mail:{" "}
                      <StyledLink to="mailto:nnpcacademy@nnpcgroup.com">
                        nnpcacademy@nnpcgroup.com
                      </StyledLink>
                    </span>
                  </div>
                </div>
              </div>
              {/* Hour of Operation */}
              <div className="col max-mb-30">
                <div className="contact-info">
                  <div className="icon">
                    <i className="fal fa-clock"></i>
                  </div>
                  <div className="info">
                    <h4 className="title"> Hour of operation</h4>
                    <span className="info-text">
                      {" "}
                      Monday – Friday : 09:00 – 17:00{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Google Map Area */}
        <div className="google-map-area section text-center">
          <div className="container">
            <div className="contact-map-area">
              <iframe
                className="contact-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.191467904322!2d7.486934174393367!3d9.046292491015725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0b202c0a5349%3A0x1b2332fb7fd4e70e!2sHerbert%20Macaulay%20Wy%2C%20Garki%2C%20Abuja%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1709802779019!5m2!1sen!2sng"
                loading=""
                referrerpolicy="no-referrer-when-downgrade"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="contact-form-section section section-padding">
          <div className="container">
            <div className="row">
              <div className="offset-lg-2 col-lg-8">
                <div className="contact-title max-width-600">
                  <h2 className="title">
                    Fill out this form for booking a consultant advising
                    session.
                  </h2>
                </div>
                <div className="contact-form">
                  <div className="row max-mb-n30">
                    <form onSubmit={handleSubmit} method="post">
                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="col-6">
                          <input
                            className="form-control "
                            style={{ borderColor: "#28a745", height: "50px" }}
                            type="text"
                            name="fullname"
                            placeholder="Fullname *"
                            value={formData.fullname}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control  "
                            style={{ borderColor: "#28a745", height: "50px" }}
                            type="email"
                            name="email"
                            placeholder="Email *"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="col-12">
                          <input
                            className="form-control"
                            style={{ borderColor: "#28a745", height: "50px" }}
                            type="text"
                            name="subject"
                            placeholder="Subject *"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="col-12">
                          <textarea
                            className="form-control "
                            style={{ borderColor: "#28a745", height: "150px" }}
                            name="message"
                            placeholder="Message *"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="d-grid col-12 mx-autom-2">
                          {error && (
                            <div className="alert alert-danger" role="alert">
                              {error}
                            </div>
                          )}
                          {message && (
                            <div className="alert alert-success" role="alert">
                              {message}
                            </div>
                          )}
                        </div>
                        <div className="d-grid col-4 mx-auto">
                          <button
                            type="submit"
                            className="btn btn-success btn-lg"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-lg"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <p className="form-message"> {} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default ContactUs;
