import React, { useState, useEffect } from 'react';
import { Modal, Button, Upload, List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getBackground, postBackground, delBackground, updateBackground } from '../../../api/UserAPI';

const BackgroundManager = () => {
  const [backgrounds, setBackgrounds] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchBackgrounds();
  }, []);

  const fetchBackgrounds = async () => {
    try {
      const response = await getBackground();
      console.log('Fetched backgrounds:', response);
      if (Array.isArray(response)) {
        setBackgrounds(response);
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Failed to fetch backgrounds', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await delBackground(id);
      setBackgrounds(backgrounds.filter(bg => bg.id !== id));
    } catch (error) {
      console.error('Failed to delete background', error);
    }
  };

  const handleUpload = async (updateId) => {
    const formData = new FormData();
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    setUploading(true);
    try {
      const response = await updateBackground(updateId, formData);
      console.log('Upload response:', response);
      if (response && response.data) {
        setBackgrounds([...backgrounds, response.data]);
        setModalVisible(false);
        setFileList([]);
        window.location.reload();
    } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Failed to upload background', error);
    } finally {
      setUploading(false);
      window.location.reload();

    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const showModal = (id) => {
    setSelectedItem(id)
    setModalVisible(true);}
  const hideModal = () => {
    setModalVisible(false);
    setFileList([]);
  };

  return (
    <div>
      
      {/* <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={backgrounds}
        renderItem={item => (
          <List.Item>
            <div>
              <img src={item.image} alt={`Background ${item.id}`} style={{ width: '100%' }} />
              <Button type="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
            </div>
          </List.Item>
        )}
      /> */}
      <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}> Home Background Image</h4>
          </div>
     </div>


      {backgrounds.map((bg) =>(
        <div className='container' >
            <div className='m-1' > 
            <img
                          src={bg.image}
                          alt="Event Image"
                          style={{
                            objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                            width: '100%', // Ensure the image fills the container's width
                            height: '400px', // Ensure the image fills the container's height
                          }}

                        //   onError={(e) => {
                        //     e.target.src = `${altImage}`;
                        //   }}

                          height={"200px"}                       
                           />
            </div>
                        

                           {/* <Button onClick={()=>handleDelete(bg.id)}>Delete</Button> */}
                           <Button type="primary" onClick={()=>showModal(bg.id)} icon={<PlusOutlined />}>

        Change  Background
      </Button>
        </div>

      ))}
      <Modal
        title="Update Background"
        visible={modalVisible}
        onCancel={hideModal}
        footer={[
          <Button key="back" onClick={hideModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={uploading}
            onClick={()=>handleUpload(selectedItem)}
          >
            Upload
          </Button>,
        ]}
      >
        <Upload
          fileList={fileList}
          beforeUpload={() => false}
          onChange={handleFileChange}
          listType="picture"
        >
          <Button icon={<PlusOutlined />}>Select File</Button>
        </Upload>
      </Modal>
    </div>
  );
};

export default BackgroundManager;
