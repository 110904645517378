import React, { useState, useEffect } from "react";
import FooterSection from "../layout/footer/FooterSection";
import Header from "../layout/navbar/HeaderSection";
import { useParams } from "react-router-dom";
import events from "./jsonfiles/events.json";
import { getEvents1 } from "../api/UserAPI";

const speakers = [];

function EventDetails() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const getId = useParams();
  const Id = (getId.eventId)
  const [event, setEvent] = useState('');

  const [eventDetails, setEventDetails] = useState(null);
  //const event = events.find((event) => event.id === parseInt(eventId, 10));
  const currentUrl = window.location.href;

  useEffect(() => {
    const fetchSelectOptions = async () => {
    try {

      const response = await getEvents1(Id);
      setEvent(response)

     

      
  } catch (error) {
    if (!event) return <div>event not found</div>;
  }
};

fetchSelectOptions();
  }, []);


  // Loading state (optional)
  //if (!eventDetails) {
  // return <div>Loading event details...</div>;
  //}

  const handleSubmit = async (event) => {
    //event.preventDefault(); // Prevent default form submission behavior

    // Form data as an object
    
    // Post request to backend
    //try {
      //const response = await fetch("http://localhost:8000/submit-comment/", {
        // Update the URL as needed
       
        //headers: {
        //  "Content-Type": "application/json",
       // },
      // // body: JSON.stringify(formData),
      //});

   // } catch (error) {
     // console.error("Error submitting the form", error);
    //}
  };
  
  const handleShare = (socialMedia) => {
    switch (socialMedia) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`, '_blank');
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        <div
          class="event-page-banner section overlay section-padding-top-200 section-padding-bottom-180"
          //   data-bg-image="https://cms1977.nnpcgroup.com/uploads/4_c62f44e113.jpg"
          style={{
            background: `green url(${event.image}) no-repeat center`,
          }}
        >
          <div class="container">
            <div class="col-12">
              <div class="event-hero-content">
                {/* <div class="entry-date"> {event.date} </div>
                <h1 class="entry-title"> {event.title} </h1> */}
                <div class="entry-countdown">
                  <div
                    class="pro-countdown-1"
                    data-countdown="2022/10/17"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="about-event-section section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                  <h2 className="title fz-48 heading-color">{event.title}</h2>
                  <div className="event-entry-meta">
                    <div className="meta-item">
                      <i className="meta-icon fal fa-map-marker-alt"></i>
                      <span>{event.location}</span>
                    </div>

                    <div className="meta-item">
                      <i className="meta-icon fal fa-calendar"></i>
                      <span>{event.date}</span>
                    </div>

                    <div className="meta-item">
                      <i className="meta-icon fal fa-clock"></i>
                      <span></span>
                    </div>
                  </div>
                </div>
                {/* Section Title End */}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-8 col-12 order-lg-1 max-mb-50"> */}
            {/* Event Details Wrapper Start */}
            {/* <div className="event-details-wrapper">
                  <div className="map-area">
                    <iframe
                      className="event-map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2136.986005919501!2d-73.9685579655238!3d40.75862446708152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4a1c884e5%3A0x24fe1071086b36d5!2sThe%20Atrium!5e0!3m2!1sen!2sbd!4v1585132512970!5m2!1sen!2sbd"
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    ></iframe>
                    <div className="entry-location-address">
                      <span className="fal fa-map-marker-alt"></span>
                      {event.location}
                    </div>
                  </div>
                </div> */}
            {/* Event Details Wrapper End */}
            {/* </div> */}

            {/* <div className="col-lg-4 col-12 order-lg-2 max-mb-50">
                <div className="sidebar-widget-wrapper pr-0 pl-30 pl-md-0 pl-sm-0 pl-xs-0">
                  <div className="sidebar-widget">
                    <div className="sidebar-widget-content">
                      <div className="sidebar-entry-event">
                        <div className="entry-register">
                          <ul className="entry-event-info">
                            <li className="meta-price">
                              <span className="meta-label">
                                <i className="meta-icon far fa-money-bill-wave"></i>
                                Cost:
                              </span>
                              <span className="meta-value">
                                <span className="event-price">
                                  {event.price}
                                  <span className="decimals-separator">
                                    .00
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li className="total">
                              <span className="meta-label">
                                <i className="meta-icon far fa-user-friends"></i>
                                Total Slot:
                              </span>
                              <span className="meta-value">99</span>
                            </li>
                            <li className="booking_slot">
                              <span className="meta-label">
                                <i className="meta-icon far fa-lock-alt"></i>
                                Booked Slot:
                              </span>
                              <span className="meta-value">0</span>
                            </li>
                          </ul>

                          <button className="btn btn-primary btn-hover-secondary btn-width-100">
                            Book Now
                          </button>
                          <p className="event-register-message">
                            You must <a href="/login-register">login</a> before
                            register event.
                          </p>
                        </div>
                        <ul className="author-social-networks event-social"> */}
            {/* Social Links */}
            {/* </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            <div className="row">
              <div className="col-12">
                <div className="event-content">
                  {/* Event Description */}
                  <p style={{ textAlign: "justify" }}>
                  <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <div className="speaker-section section section-padding-bottom">
          <div className="container section-border-bottom section-padding-bottom-90">
            <div className="row">
              <div className="col-12">
                <div
                  className="section-title text-center max-mb-50"
                  data-aos="fade-up"
                >
                  <h2 className="title fz-48 heading-color">Our Speakers</h2>
                </div>
              </div>
            </div>
            <div className="speaker-slider swiper-container row" data-aos="">
              <div className="swiper-wrapper">
                {speakers.map((speaker) => (
                  <div className="swiper-wrapper " key={speaker.id}>
                    <div className="single-speaker">
                      <div className="image">
                        <img src={speaker.imageUrl} alt={speaker.name} />
                      </div>
                      <div className="content">
                        <h6 className="speaker-name">{speaker.name}</h6>
                        <div className="speaker-description">
                          {speaker.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="event-speakers-description">
              Register online, get your ticket, meet up with our inspirational
              speakers and specialists in the field to share your ideas.
            </div>
          </div>
        </div>
      </div> */}

      {/* <div>
        <div className="event-comment-section section section-padding-bottom">
          <div className="container"> */}
      {/* Other components */}
      {/* <div className="row">
              <div className="offset-lg-2 col-lg-8">
                <div className="event-form">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-12 max-mb-30">
                        <input
                          type="text"
                          placeholder="Your Name *"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 col-12 max-mb-30">
                        <input
                          type="email"
                          placeholder="Email *"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-12 max-mb-30">
                        <textarea
                          name="message"
                          placeholder="Your Comment"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="col-12 text-center">
                        <button
                          className="btn btn-primary btn-hover-secondary btn-width-180 btn-height-60"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default EventDetails;
