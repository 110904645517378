import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input  } from 'reactstrap';
import { getAudiance, getCategory, getCourses1, getInstructors, postCourses, updateCourses } from '../../../../api/UserAPI';
import axios from 'axios';
import { theme,   } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const UpdateCourse = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const getId = useParams();
  const Id = (getId.courseId)
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const categoriesRes = await getCategory();
        //console.log(categoriesRes)
        setCategories(categoriesRes);

        const audiencesRes = await getAudiance();
        setAudiences(audiencesRes);

       // const instructorsRes = await getInstructors();
        //setInstructors(instructorsRes);

        const response = await getCourses1(Id);
        console.log(response)
        setFormData(response);


       
      } catch (error) {
        console.error('Failed to fetch select options:', error);
        setError('Failed to fetch select options.');
      }
    };

    fetchSelectOptions();
  }, []);

  const handleChange = (e) => {
    const { name, type, checked, value, files, options } = e.target;

    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.files[0] 
      }));
    } else if (type === 'checkbox') {
        const newValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue, // Directly use the checked property for checkboxes
        }));}else if (type === 'select-multiple') {
            // Collect all selected options for a multi-select
            const selectedOptions = Array.from(options)
                                          .filter(option => option.selected)
                                          .map(option => option.value);
            setFormData((prevData) => ({
              ...prevData,
              [name]: selectedOptions,
            }));}
         else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    try {

      const response = await updateCourses(Id,formDataToSend);

      if (!response.error) {
        alert('Course updated successful')
        navigate('/admin/courses');

      }else{
        setError('error')
      }

     
    } catch (error) {
      console.error('Submission error:', error);
      setError('Submission failed.');
    } finally {
      setLoading(false);
    }
  };

  const handleCKEditorChange = (name, editor) => {
    const value = editor.getData();
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fields = [
    { name: 'code', type: 'text', label: 'Course Code' },
    { name: 'title', type: 'text', label: 'Course Title' },
    { name: 'description', type: 'textarea', label: 'Description' },
    { name: 'category', type: 'select', label: 'Category', options: categories.map(cat => ({ value: cat.id, label: cat.name })) },
    { name: 'target_audience', type: 'select', label: 'Target Audience', options: audiences.map(aud => ({ value: aud.id, label: aud.name })) },
    { name: 'price', type: 'text', label: 'Price' },
    { name: 'duration', type: 'text', label: 'Duration' },
    { name: 'is_featured', type: 'checkbox', label: 'Is Featured Course? ' },
    { name: 'imageUrl', type: 'file', label: 'Image ' },
    { name: 'venue', type: 'text', label: 'Venue' },
    // { name: 'date', type: 'date', label: 'Date' }, 
    { name: 'starting_date', type: 'date', label: 'Starting Date' },    
    { name: 'ending_date', type: 'date', label: 'Ending Date' },    
    { name: 'more_details', type: 'textarea', label: 'More Details' },


    // Add other fields as necessary
  ];

  
  return (
    <div>
        <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Update course</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          </div>
        </div>
      </div>

      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>

      <Form onSubmit={handleSubmit} encType="multipart/form-data">
      {fields.map((field) =>
          field.type === 'select' ? (
            <FormGroup key={field.name}  style={{ width: '48%', display: 'inline-block', margin:5 }}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="select"
                name={field.name}
                id={field.name}
                value={field.type === 'checkbox' ? undefined : formData[field.name] || ''}
                onChange={handleChange}
                required
              >
                <option value="">Select...</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value} required>
                    {option.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ) : field.type === 'file' ? (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="file"
                name={field.name}
                id={field.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
          ) :  field.type === 'textarea' ? (
            <div key={field.name} style={{  margin:5, }} >
              <Label for={field.name}>{field.label}</Label>
              <CKEditor
                editor={ClassicEditor}
                data={formData[field.name] || ''}
                onChange={(event, editor) => handleCKEditorChange(field.name, editor)}
                onBlur={(event, editor) => {
                  //console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  //console.log('Focus.', editor);
                }}
              />
            

            </div>

          ):(
            <FormGroup key={field.name}  style={{ width: '48%', display: 'inline-block', margin:5 }}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type={field.type}
                name={field.name}
                id={field.name}
                value={field.type === 'checkbox' ? undefined : formData[field.name] || ''}
                checked={formData[field.name] || false}                
                 onChange={handleChange}
                 texttext
              />
            </FormGroup>
          )
        )}
        {/* {fields.map((field) =>
          field.type === 'select' ? (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="select"
                name={field.name}
                id={field.name}
                value={formData[field.name] || ''}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ) : field.type === 'file' ? (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="file"
                name={field.name}
                id={field.name}
                onChange={handleChange}
              />
            </FormGroup>
          ) : (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type={field.type}
                name={field.name}
                id={field.name}
                value={field.type === 'checkbox' ? undefined : formData[field.name] || ''}
                checked={formData[field.name] || false}
                onChange={handleChange}
              />
            </FormGroup>
          )
        )} */}
        {error && <div className="alert alert-danger m-2">{error}</div>}
        <Button className='mt-2' type="submit" color="success" disabled={loading}>
          {loading ? 'Updating...' : 'Update Course'}
        </Button>
      </Form>
      </div>
    </div>
  );
};

export default UpdateCourse;
