import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  StyledAnchor,
  StyledSelect,
} from "../components/StyledComponents";
import Brochure from "../assets/2024BROCHURE.pdf";
import { StyledDiv } from "../components/StyledComponents";
import { getCategory, getCourses } from "../api/UserAPI";
import altImage from "../assets/img/brand/courses-image.jpg";
import { AiFillStar } from "react-icons/ai";
import CourseModal from "./modals/CourseModal";

const Courses = () => {
  let navigate = useNavigate();

  const [courses, setCourses] = useState([]);
  const [visibleCourses, setVisibleCourses] = useState(8); // Number of initially visible courses
  const [getCategories, setgetCategories] = "";
  //const [filter, setFilter] = useSfilterfiltertate({});
  const [filter, setFilter] = useState({ venue: "", category: "", price: "" });
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [courseModal, setCourseModal] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const response = await getCourses();

        if (Array.isArray(response)) {
          // Check if response is an array
          setCourses(response);
          setFilteredCourses(response);
          console.log(response)
        } else {
          console.error("Response is not an array:", response);
        }
      } catch (error) {}
    };

    fetchSelectOptions();
  }, []);

  useEffect(() => {
    filterCourses(filter);
  }, [filter, courses]);

  // Example venues and categories for select options
  const venues = useMemo(
    () => [...new Set(courses.map((course) => course.venue))],
    [courses]
  );
  const categories = useMemo(
    () => [...new Set(courses.map((course) => course.category_name))],
    [courses]
  );

  function numberWithSeparator(numberString) {
    // Convert numberString to string if it's not already a string
    if (typeof numberString !== "string") {
      numberString = String(numberString);
    }

    // Check if numberString contains a decimal point
    const hasDecimal = numberString.includes(".");

    // Split the string into integer and fractional parts (if any)
    const parts = numberString.split(".");

    // Format the integer part with thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and fractional parts (if any)
    return parts.join(hasDecimal ? "." : "");
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const filterCourses = (filterCriteria) => {
  //   let updatedCourses = courses.filter(
  //     (course) =>
  //       (filterCriteria.venue ? course.venue === filterCriteria.venue : true) &&
  //       (filterCriteria.category
  //         ? course.category === filterCriteria.category
  //         : true) &&
  //       (filterCriteria.price ? course.price <= filterCriteria.price : true)
  //   );
  //   setFilteredCourses(updatedCourses);
  // };
  const filterCourses = (filterCriteria) => {
    let updatedCourses = courses.filter(
      (course) =>
        (filterCriteria.venue ? course.venue === filterCriteria.venue : true) &&
        (filterCriteria.category
          ? course.category_name === filterCriteria.category
          : true) &&
        (filterCriteria.price ? course.price <= filterCriteria.price : true)
    );
    setFilteredCourses(updatedCourses);
  };

  const loadMore = () => {
    setVisibleCourses((prevVisibleCourses) => prevVisibleCourses + 8); // Load 6 more courses
  };
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };
  const toggleCourseModal = () => {
    setCourseModal(!courseModal);
  };
  const handleSetCourseModal = (value) => {
    setTitle(value);
    setCourseModal(true);
  };

  return (
    <div className="section section-padding-bottom">
      <div className="section section-padding">
        <div className="container mt-3">
          {/* Feature Wrapper Start */}
          <div className="row row-cols-xl-3 row-cols-sm-2 row-cols-1 max-mb-n30">
            {/* Feature Start (Icon Box) */}
            <div className="col max-mb-30" data-aos="">
              <Link
                // to=""
                className="icon-box h5-icon-box text-center text-decoration-none"
              >
                <div className="icon">
                  <img
                    src="https://i.ibb.co/dPYV8gD/home-5-image-box-01.png"
                    style={{ width: "auto", height: "auto" }}
                    alt="Course 1"
                  />
                </div>
                <div className="content">
                  <h3 className="title">Classroom Training</h3>
                  <div className="desc">
                    <p>
                      Learn practical skills from industry experts in our
                      instructor-led classroom courses. Find the perfect
                      training to advance your career.
                    </p>
                  </div>
                  <span
                    className="link"
                    onClick={() => {
                      filterCourses({
                        category: "", // Remove category filter
                      });
                      setFilter({
                        category: "", // Remove category filter
                      });
                    }}
                  >
                    View programs <i className="far fa-long-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
            {/* Feature End (Icon Box) */}

            {/* Feature Start (Icon Box) */}
            <div className="col max-mb-30" data-aos="">
              <Link
                // onClick={() => handleSetCourseModal("Customization")}
                to="/customize"

                className="icon-box h5-icon-box text-center text-decoration-none"
              >
                <div className="icon">
                  <img
                    src="https://i.ibb.co/dbt9JXM/home-5-image-box-02.png"
                    style={{ width: "auto", height: "auto" }}
                    alt="Course 1"
                  />
                </div>
                <div className="content">
                  <h3 className="title">In-house Training</h3>
                  <div className="desc">
                    <p>
                      Up your team's skills with our customized in-house
                      training. We deliver expert-led programs to your workplace
                      for minimal disruption and max impact.
                    </p>
                  </div>
                  <span
                    className="link"
                    // onClick={() => {
                    //   filterCourses({
                    //     venue: "Training",
                    //   });
                    //   setFilter({
                    //     venue: "Training",
                    //   });
                    // }}
                  >
                    Schedule trainings{" "}
                    <i className="far fa-long-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
            {/* Feature End (Icon Box) */}

            {/* Feature Start (Icon Box) */}
            <div className="col max-mb-30" data-aos="">
              <Link className="icon-box h5-icon-box text-center text-decoration-none ">
                <div className="icon">
                  <img
                    src="https://i.ibb.co/KF0hzLr/home-5-image-box-03.png"
                    style={{ width: "auto", height: "auto" }}
                    alt="Course 1"
                  />
                </div>
                <div className=" content ">
                  <h3 className=" title ">Online Courses</h3>
                  <div className=" desc">
                    <p>
                      Learn anytime, anywhere with our online training. Choose
                      from a vast library of self-paced courses and interactive
                      sessions led by industry experts.
                    </p>
                  </div>
                  <span
                    className="link"
                    onClick={() => {
                      filterCourses({
                        venue: "",
                      });
                      setFilter({
                        venue: "Online",
                      });
                    }}
                  >
                    View courses <i className="far fa-long-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
            {/* Feature End (Icon Box) */}
          </div>
          {/* Feature Wrapper End */}
        </div>
      </div>
      <div className="container">
        <div className="row row-cols-6 justify-content-between align-items-center max-mb-20">
          <div className="col-sm-auto col-12 max-mb-10">
            <p className="result-count">
              We found <span>{filteredCourses?.length}</span> courses available
              for you
            </p>
          </div>
          <div />
          <div />
          <div className="event-button">
            <StyledAnchor
              href={Brochure}
              target="_blank"
              className="text-decoration-none"
            >
              <i className="far fa-download me-3"></i>
              Download Brochure
            </StyledAnchor>
          </div>
          <div className="col-sm-auto col-12 max-mb-10">
            <StyledSelect
              className="form-select selectric"
              name="category"
              value={filter.category}
              onChange={handleFilterChange}
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </StyledSelect>
            {/* <StyledSelect
              className="form-select selectric"
              name="venue"
              value={filter.venue}
              onChange={handleFilterChange}
            >
              <option value="">All Venues</option>
              {venues.map((venue) => (
                <option key={venue} value={venue}>
                  {venue}
                </option>
              ))}
            </StyledSelect> */}
          </div>
        </div>

        {filteredCourses.length > 0 ? (
          <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 max-mb-n30">
            {filteredCourses.slice(0, visibleCourses).map((course) => (
              <StyledDiv
                key={course.id}
                className="col max-mb-30"
                data-aos="fade-up"
              >
                <div
                  className="course-4 text-decoration-none"
                  key={course.id}
                  onClick={() => handleCourseClick(course.id)}
                >
                  <div className="thumbnail" style={{ maxHeight: "200px" }}>
                    <Link className="image">
                      <img
                        src={course.imageUrl}
                        alt=" CoursesImage "
                        style={{
                          objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                          width: '100%', // Ensure the image fills the container's width
                          height: '200px', // Ensure the image fills the container's height
                        }}

                        onError={(e) => {
                          e.target.src = `${altImage}`;
                        }}
                        height={"200px"}
                      />
                    </Link>
                  </div>

                  <div className="info ">
                    <div className="instructor-avatar">
                      <span className="instructor-name">
                        {course.category_name}
                      </span>
                    </div>
                    <span className=" fw-bold text-success">
                      <b>
                        <span>₦{numberWithSeparator(course.price)}</span>
                        <span style={{ fontSize: 13 }}>.00</span>
                      </b>
                    </span>
                    <h6 className="">
                      <Link
                        className="text-decoration-none"
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          maxLines: "2",
                          overflow: "hidden",
                          display: "-webkit-box",
                          webkitBoxOrient: "vertical",
                          webkitLineClamp: "2",
                        }}
                      >
                        {course.title}
                        {course.is_featured && (
                          <AiFillStar
                            style={{ marginLeft: "0.5rem", color: "gold" }}
                          />
                        )}
                      </Link>
                    </h6>
                    <ul className="meta">
                    {course.starting_date
                     && (
                      <>
                      <li>
                        <i className=""></i>
                        Starting and Closing Date
                      </li>
                      <li>
                        <i className=""></i>
                        {course.starting_date} - {course.ending_date}
                      </li>
                      </>
                      
                      )}

                      <li>
                        <i className="far fa-file-alt"></i>
                        {course.venue}
                      </li>
                      {course.students && (
                        <li>
                          <i className="far fa-user-alt"></i>
                          {course.students}
                        </li>
                      )}
                      
                      
                    </ul>
                  </div>
                </div>
              </StyledDiv>
            ))}
          </div>
        ) : (
          <div className="row mt-5 text-center border p-5">
            <span>
              <i
                className="far fa-scroll me-3 pb-3"
                style={{ fontSize: "36px", color: "green" }}
              ></i>
            </span>
            <h2>No available courses yet!</h2>
          </div>
        )}

        {filteredCourses.length > 8 ? (
          <div className="row mt-5">
            <div className="col text-center">
              <Button outline onClick={loadMore}>
                Load More <i className="fal fa-redo ms-3"></i>
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        <CourseModal
          isOpen={courseModal}
          toggle={toggleCourseModal}
          title={title}
          course={"Write about the details of course you want... "}
        />
      </div>
    </div>
  );
};

export default Courses;
