import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCalendarAlt, faAddressBook, faUsers, faBlog, faUser, faTags, faUserPlus, faAlignCenter, faAlignJustify } from '@fortawesome/free-solid-svg-icons';
// import './admin.css';
import { Card, Space, Tag, Flex,theme, Table, Modal } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  ReadOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
  FileAddOutlined,
  PhoneOutlined,
  FileProtectOutlined,

} from '@ant-design/icons';
import {   FormGroup, Label,Input,Form, Button } from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getContactUs, getCourses,getEnroll,getEvents,getPosts,getTeam, postBlog, getLogs } from '../../../api/UserAPI';
import WebsiteVisitorsChart from './websiteVisitorChart';
import {jwtDecode} from 'jwt-decode'

const tiles = [
  { label: ' Add courses ', icon: <ReadOutlined/>, link: '/admin/courses' },
  { label: ' Add Events ', icon: <CalendarOutlined/>, link: '/admin/events' },
  { label: ' Manage Contact ', icon: <PhoneOutlined/>, link: '/admin/contacts' },
  { label: ' Manage Team ', icon: <UsergroupAddOutlined/>, link: '/admin/teams' },
  { label: ' Add Blog ', icon: <FileAddOutlined/>, link: '/admin/blogs' },
 // { label: 'Users', icon: faUser, link: '/admin/users' },
  { label: ' Manage Enrollment ', icon: <FileProtectOutlined/>, link: '/admin/enroll' },
];
const Index = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [courses, setCourses]= useState('');
  const [events, setEvents]= useState('');
  const [posts, setPosts]= useState('');
  const [enroll, setEnroll]= useState('');
  const [contact, setContact]= useState('');
  const [team, setTeam] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [post, setPost] = useState({
    title: '',
    content: '',
    tag:'',
    image: null,
  });
  const navigate = useNavigate();

  const handleCKEditorChange = (name, editor) => {
    const value = editor.getData();
    setPost({ ...post, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      const file = e.target.files[0];
      
          const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
          if (!validImageTypes.includes(file.type)) {
              alert("Please upload a valid image file (JPEG, PNG, GIF).");
              e.target.value = ""; // Clear the input
              return;
          };
        
        if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
            alert("Image size is too heavy. Please upload an image less than 2MB.");
            e.target.value = ""; // Clear the input
        }else{
          setPost({ ...post, [name]: e.target.files[0] });


        };


    } else {
      setPost({ ...post, [name]: value });
    }
  };

  const showModal = () => {
    setOpenModal(true);
  };
  const hideModal = () => {
    setOpenModal(false);
  };

  const gotoBlogs =()=>{
    navigate('/admin/blogs');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('title', post.title);
    formData.append('content', post.content);
    formData.append('tag', post.tag);

    if (post.image) {
      formData.append('image', post.image);
    }

    try {
     const blog = await postBlog(formData) 
     console.log(blog)
     if (!blog.error){
        //alert('The blog is created successfully')
        showModal();


      }

      setLoading(false);
      // Reset form or redirect user
      setPost({ title: '', content: '', image: null });
      // Optionally, redirect the user or give feedback

    } catch (error) {
      setError('Failed to create post. Please try again.',error);
      setLoading(false);
    }
  };


  

useEffect(()=>{
  const fetchAll = async () => {

    // let rfff = localStorage.getItem('userData')

    // console.log(rfff)
    try{
      const resCourses = await getCourses();
      setCourses(resCourses.length);
      const resPost = await getPosts();
      setPosts(resPost.length);
      const resEvents = await getEvents();
      setEvents(resEvents.length);
      const resEnroll = await getEnroll();
      setEnroll(resEnroll.length)
      const resContact = await getContactUs();
      setContact(resContact.length)
      const resTeam = await getTeam();
      setTeam(resTeam.length)
      const resLogs = await getLogs();
      const logs = Array.isArray(resLogs) ? resLogs : resLogs.results || [];
 
      setLogs(logs);

    } catch (error) {
      //console.error('Failed to fetch select options:', error);
      //setError('Failed to fetch select options.');
    }
    


  }
  fetchAll();

} ,[])

const formatDate = (dateTimeString) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    //second: '2-digit',
    //hour12: true
  };

  return new Date(dateTimeString).toLocaleString(undefined, options);
};



  

  return (
    <div className="admin-dashboard"style={{  background:'none',}} >

    <div className=''> <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Dashboard</h4></div>

      <div className="row" 
      style={{
              padding: 15,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}>
        <div className='col-6 ' 
        > 
        <Card 
        className='mb-3 p-3'
          bordered={true} 
          style={{ 
            width: '100%', 
            border: '2px solid #ddd', // add this line
            margin: 5,
          }} 
        >
          <div style={{ 
            paddingBottom: '10px' 
          }}>
            <b>Good afternoon and welcome to the NNPC Academy Admin Site, Admin!</b>
            <p>Here is a way to get Started!</p>

          </div>

          <div style={{ 
            paddingBottom: '10px', 
           
          }}>
            {tiles.map((tile) => (
                <NavLink to={tile.link} style={{ textDecoration: 'none'}}  > 
            <div  style={{background: '#2268451A', padding: 2,paddingInlineEnd:10, borderRadius: 25, margin: 5,display: 'flow', justifyContent: 'space-between', alignContent: 'center'}} ><icon style={{background: '#226845', borderRadius:25, color:'white', fontSize: 16, padding:6, }} > {tile.icon }  </icon> <span style={{padding:5}}>{ tile.label}</span></div>
            </NavLink>            
            ))}

          </div>
        </Card>

        <Card 
        className='mt-3 p-3'
          bordered={true} 
          style={{ 
            width: '100%', 
            border: '2px solid #ddd', // add this line
            margin: 5,
          }} 
        >
          <div style={{ 
            paddingBottom: '10px' 
          }}>
            <b>Quick Info</b>
            <p>Here is the Statistics of your site</p>

          </div>

          <div className='row' style={{ 
            paddingBottom: '10px', 
           
          }}>
            <div className='col-6'>     
             <div  style={{background: '', padding: 2,paddingInlineEnd:10, borderRadius: 25, margin: 5,display: 'flow', justifyContent: 'space-between', alignContent: 'center'}} ><icon style={{background: '', color:'black', fontSize: 16, padding:6, }} > {<ReadOutlined/> } </icon> <span style={{padding:6, fontSize:16}}> {courses} Courses </span></div>
             <div  style={{background: '', padding: 2,paddingInlineEnd:10, borderRadius: 25, margin: 5,display: 'flow', justifyContent: 'space-between', alignContent: 'center'}} ><icon style={{background: '', color:'black', fontSize: 16, padding:6, }} > {<CalendarOutlined/> } </icon> <span style={{padding:6, fontSize:16}}> {events} events </span></div>
             <div  style={{background: '', padding: 2,paddingInlineEnd:10, borderRadius: 25, margin: 5,display: 'flow', justifyContent: 'space-between', alignContent: 'center'}} ><icon style={{background: '', color:'black', fontSize: 16, padding:6, }} > {<FileAddOutlined/> } </icon> <span style={{padding:6, fontSize:16}}> {posts} posts </span></div>

            </div>
            <div className='col-6 text-start'>
            <div  style={{background: '', padding: 2,paddingInlineEnd:10, borderRadius: 25, margin: 5,display: 'flow', justifyContent: 'space-between', alignContent: 'center'}} ><icon style={{background: '', color:'black', fontSize: 16, padding:6, }} > {<FileProtectOutlined/> } </icon> <span style={{padding:6, fontSize:16}}> {enroll} Enroll </span></div>
            <div  style={{background: '', padding: 2,paddingInlineEnd:10, borderRadius: 25, margin: 5,display: 'flow', justifyContent: 'space-between', alignContent: 'center'}} ><icon style={{background: '', color:'black', fontSize: 16, padding:6, }} > {<PhoneOutlined/> } </icon> <span style={{padding:6, fontSize:16}}> {contact} Contact </span></div>
            <div  style={{background: '', padding: 2,paddingInlineEnd:10, borderRadius: 25, margin: 5,display: 'flow', justifyContent: 'space-between', alignContent: 'center'}} ><icon style={{background: '', color:'black', fontSize: 16, padding:6, }} > {<UsergroupAddOutlined/> } </icon> <span style={{padding:6, fontSize:16}}> {team} Team </span></div>

            </div>


          </div>
        </Card>
        <Card 
        className='mt-3 p-3'
          bordered={true} 
          style={{ 
            width: '100%', 
            border: '2px solid #ddd', // add this line
            margin: 5,
          }} 
        >
          <div style={{ 
            paddingBottom: '10px' 
          }}>
            <b>Recent Activities</b> 
            <p>All recent activity in this site </p>
          </div>
          <div style={{ 
            paddingBottom: '10px', 
           
          }}>

            { logs.map((log)=> (
              <div>  { log.user_name  } - { log.action } - { log.content_type_name } - { formatDate(log.action_time)}</div>

            ))

            }
            
          </div>
          </Card>

        </div>
        <div className='col-6 '> 
        <Card 
        className='p-2'
          bordered={true} 
          style={{ 
            width: '100%', 
            border: '2px solid #ddd', // add this line
            margin: 5,
          }} 
        >
          <div style={{ 
            paddingBottom: '10px' 
          }}>
            <b>Add a new Post</b>
            <p>Add new content fast</p>
          </div>

          <div style={{ 
            paddingBottom: '10px', 
           
          }}>


            <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="title">Title</Label>
                  <Input type="text" name="title" id="title" value={post.title} onChange={handleChange} required />
                </FormGroup>
                <FormGroup>
                  <Label for="content">Content</Label>
                  {/* <Input type="textarea" name="content" id="content" value={post.content} onChange={handleChange} style={{ minHeight: '250px' }} required /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={post.content || ''}
                    onChange={(event, editor) => handleCKEditorChange('content', editor)}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      //console.log('Focus.', editor);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Tag(s)</Label>
                  <Input type="text" name="tag" id="tag" value={post.tag} onChange={handleChange} required />
                </FormGroup>
                <FormGroup>
                  <Label for="image">Image </Label>
                  <Input type="file" name="image" id="image" onChange={handleChange} />
                </FormGroup>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <Button type="submit" color="success" disabled={loading}>
                  {loading ? 'Creating...' : 'Create Post'}
                </Button>
              </Form>
              <Modal
                title={<div style={{ textAlign: 'center' }}>Blog created successfully...</div>}
                open={openModal}
                onCancel={hideModal}
                footer={null}
              >
                <p style={{ textAlign: 'center' }}>Do you want to go to blogs pages?</p>
                <div style={{ textAlign: 'center' }}> 
                <Button className='btn btn' onClick={hideModal}>No</Button>                          
                <Button className="btn btn-success m-1" onClick={gotoBlogs}>Yes</Button>
                </div>

              </Modal>
             {/* <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={{}}
    >
      <Form.Item
        name="title"
        rules={[
          {
            required: true,
            message: 'Please input post title!',
          },
        ]}
      >
        <Input 
        //prefix={<UserOutlined className="site-form-item-icon" />} 
        placeholder="Post title" />
      </Form.Item>
      <Form.Item
      name={'content'}
      rules={[
        {
          required: true,
          message: 'Please input content of the post'
        }
      ]}>
        <CKEditor
            
          
            editor={ClassicEditor}
            data="<p> Write the details of the post</p>"
            onReady={editor => {
              //console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              if (editor) {
                const content = editor.getData();
                //setEvent({ ...event, description });
              }
            }}
            onBlur={(event, editor) => {
              //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //console.log('Focus.', editor);
            }}/>
        
      </Form.Item>
      <Form.Item
        name="tag"
        rules={[
          {
            required: true,
            message: 'Please input post tag(s)!',
          },
        ]}
      >
        <Input
          //prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="tag(s)"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
        </Form.Item>
        <Input type="file" name="image" id="image"  placeholder='select featured image'//onChange={handleChange}
         />


        {/* <a className="login-form-forgot" href="">
          Forgot password
        </a> *
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Add post
        </Button>
      </Form.Item>
    </Form> */}
            

          </div>
        </Card>
        <Card 
        className='mt-3 p-2'
          bordered={true} 
          style={{ 
            width: '100%', 
            border: '2px solid #ddd', // add this line
            margin: 5,
          }} 
        >
          <div style={{ 
            paddingBottom: '10px' 
          }}>
            <b>Website visitors</b> 
            <p>Here is the Statistics of visitors </p>
            
          </div>
          <div>
          <WebsiteVisitorsChart />
          </div>
          </Card>
          
        </div>
        




        {/* {tiles.map((tile, index) => (
          <div key={index} className="col-md-4 mb-3">
            <NavLink to={tile.link} className="admin-tile">
              <FontAwesomeIcon icon={tile.icon} className="icon" />
              <span className="label">{tile.label}</span>
            </NavLink>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default Index;
