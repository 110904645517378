import axios from 'axios';


const BASE_URL = process.env.REACT_APP_BASE_URL;
 // Adjust this to your Django server URL

//const token = localStorage.getItem('userToken');


const getAuthConfig = () => {
  const token = localStorage.getItem('userToken');
  return {
    headers: {
      'Authorization': `Bearer ${token}`,
      // 'Content-Type': 'multipart/form-data'
    }
  };
};

// const token = localStorage.getItem('userToken');

//     // Set up the headers with the Authorization token
// const config = {
//   headers: {
//     'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
//   }
// };



export const getBackground = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/backgrounds/`);
    console.log(response.data.results)

    return response.data.results;
  } catch (error) {
    return error;
  }
};

export const postBackground = async (formData) => {
  try {
    const config = getAuthConfig();

    const response = await axios.post(`${BASE_URL}/backgrounds/`, formData, config);

    return response.data;
  } catch (error) {
    //console.error("Error adding lesson:", error);
    return error;
  }
};


export const updateBackground = async (imageId, formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const response = await axios.put(`${BASE_URL}/backgrounds/${imageId}/`,formData, {
      headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

        },
      });

    return response.data;
  } catch (error) {
    // ////console.error("Error fetching lesson:", error);
    return error;
  }
};

export const delBackground = async (imageId) => {
  try {
    const config = getAuthConfig();

    const response = await axios.delete(`${BASE_URL}/backgrounds/${imageId}/`, config ); // Include the teamId in the URL
    
    return response.data;
  } catch (error) {
    //console.error("Error deleting lesson:", error);
    return error;
  }
};


export const getLesson = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/lessons/`);
    console.log(response.data.results)

    return response.data;
  } catch (error) {
    return error;
  }
};

export const postLesson = async (formData) => {
  try {
    const config = getAuthConfig();

    const response = await axios.post(`${BASE_URL}/lessons/`, formData, config);

    return response.data;
  } catch (error) {
    //console.error("Error adding lesson:", error);
    return error;
  }
};

export const getLesson1 = async (lessonId) => {
  try {

    const response = await axios.get(`${BASE_URL}/courses/${lessonId}/lessons/`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateLesson = async (lessonId, formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const response = await axios.put(`${BASE_URL}/courses/${lessonId}/`,formData, {
      headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

        },
      });

    return response.data;
  } catch (error) {
    // ////console.error("Error fetching lesson:", error);
    return error;
  }
};

export const delLesson = async (lessonId) => {
  try {
    const config = getAuthConfig();

    const response = await axios.delete(`${BASE_URL}/lessons/${lessonId}/`, config ); // Include the teamId in the URL
    
    return response.data;
  } catch (error) {
    //console.error("Error deleting lesson:", error);
    return error;
  }
};

export const delEnroll = async (enrollId) => {
  try {
    const token = localStorage.getItem('userToken');

    // Set up the headers with the Authorization token
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
      }
    };
    const response = await axios.delete(`${BASE_URL}/enrollments/${enrollId}/`, config); // Include the teamId in the URL
    
    return response.data;
  } catch (error) {
    //console.error("Error deleting enroll:", error);
    return error;
  }
};

export const postEnroll = async (formData) => {
  try {
    const config = getAuthConfig();

    const response = await axios.post(`${BASE_URL}/enrollments/`, formData, config);

    return response.data;
  } catch (error) {
    //console.error("Error enrolling course:", error);
    return error;
  }
};
export const getEnroll = async() =>{

  try{
    const config = getAuthConfig();

    
    const response = await axios.get(`${BASE_URL}/enrollments/`, config);
    return response.data.results
  }catch(error){
    return error
  }

};

export const getInstructors = async() =>{
  try{
    const response = await axios.get(`${BASE_URL}/users/`);
    return response.data.results
  }catch(error){
    return error
  }

};
export const getCategory = async() =>{
  try{
    const response = await axios.get(`${BASE_URL}/categories/`);
    return response.data.results
  }catch(error){
    return error
  }

};

export const getCategory1 = async(Id) =>{
  try{

    const response = await axios.get(`${BASE_URL}/categories/${Id}`);
    return response.data
  }catch(error){
    return error
  }

};

export const getAudiance = async() =>{
  try{
    const response = await axios.get(`${BASE_URL}/target-audiences/`);
    return response.data.results
  }catch(error){
    return error
  }

};

export const delTeam = async (teamId) => {
  try {
    const config = getAuthConfig();

    const response = await axios.delete(`${BASE_URL}/team/${teamId}/` , config); // Include the teamId in the URL
    
    return response.data;
  } catch (error) {
    //console.error("Error deleting team member:", error);
    return error;
  }
};

export const editTeam = async (teamId, formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const response = await axios.put(`${BASE_URL}/team/${teamId}/`, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

      },
    });

    return response.data;
  } catch (error) {
    //console.error("Error editing team member:", error.response);
    throw error;
  }
};


export const getTeam = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/team/`);
    
    return response.data.results;
  } catch (error) {
    //console.error("Error posting contact form:", error);
    return error;
  }
};

export const getTeam1 = async (teamId) => {
  try {
    const response = await axios.get(`${BASE_URL}/team/${teamId}/`);
    
    return response.data;
  } catch (error) {
    //console.error("Error posting contact form:", error);
    return error;
  }
};

export const postTeam = async (formData) => {
  try {
    const config = getAuthConfig();

    const response = await axios.post(`${BASE_URL}/team/`, formData,config);

    return response.data;
  } catch (error) {
    //console.error("Error posting contact form:", error);
    return error;
  }
};

export const delContactUs = async (contId) => {
  try {
    const config = getAuthConfig();

    const response = await axios.delete(`${BASE_URL}/contact-us/${contId}/`, config);
    
    return response.data;
  } catch (error) {
    //console.error("Error posting contact form:", error);
    return error;
  }
};

export const editContactUs = async () => {
  try {
    const config = getAuthConfig();

    const response = await axios.put(`${BASE_URL}/contact-us/`, config);
    
    return response.data;
  } catch (error) {
    //console.error("Error posting contact form:", error);
    return error;
  }
};

export const getContactUs = async () => {
  try {
    const config = getAuthConfig();

    const response = await axios.get(`${BASE_URL}/contact-us/`, config);
    
    return response.data.results;
  } catch (error) {
    //console.error("Error posting contact form:", error);
    return error;
  }
};

export const PostContactUs = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/contact-us/`, formData);

    return response.data;
  } catch (error) {
    //console.error("Error posting contact form:", error);
    return error;
  }
};

// Function for courses
export const postCourses = async (formData) => {
  try {
    const config = getAuthConfig();

    const response = await axios.post(`${BASE_URL}/courses/`,formData, config);
    return response.data;
  } catch (error) {
    //console.error("Error fetching courses:", error);
    return error;
  }
};

export const updateCourses = async (courseId, formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const response = await axios.put(`${BASE_URL}/courses/${courseId}/`,formData, {
      headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

        },
      });

    return response.data;
  } catch (error) {
    //console.error("Error fetching courses:", error);
    return error;
  }
};

export const delCourse = async (courseId) => {
  try {
    const config = getAuthConfig();

    const response = await axios.delete(`${BASE_URL}/courses/${courseId}/`, config ); // Include the teamId in the URL
    
    return response.data;
  } catch (error) {
    //console.error("Error deleting course:", error);
    return error;
  }
};

export const getCourses = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/courses/`);
    return response.data.results;
  } catch (error) {
    //console.error("Error fetching courses:", error);
    return error;
  }
};

export const getCourses1 = async (courseId) => {
  try {
    const response = await axios.get(`${BASE_URL}/courses/${courseId}/`);
    return response.data;
  } catch (error) {
    //console.error("Error fetching courses:", error);
    return error;
  }
};

// Function for events
export const getEvents = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/events/`);
    return response.data.results;
    console.log(response.data)
  } catch (error) {
    //console.error("Error fetching events:", error);
    return error;
  }
};

export const getEvents1 = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/events/${eventId}/`);
    return response.data;
  } catch (error) {
    //console.error("Error fetching event:", error);
    return [];
  }
};

export const postEvents = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/events/`, formData, config);
    return response.data;
    console.log(response.data)
  } catch (error) {
    //console.error("Error creating events:", error);
    return error;
  }
};

export const delEvents = async (eventId) => {
  try {
    const config = getAuthConfig();

    const response = await axios.delete(`${BASE_URL}/events/${eventId}/`, config);
    return response.data;
    console.log(response.data)
  } catch (error) {
    //console.error("Error deleting events:", error);
    return error;
  }
};

export const UpdateEvent = async (eventId, formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const response = await axios.put(`${BASE_URL}/events/${eventId}/`,formData, {
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

        },
      });

    return response.data;
  } catch (error) {
    //console.error("Error fetching events:", error);
    return error;
  }
};



// Functions for posts
export const editBlog = async (formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const response = await axios.put(`${BASE_URL}/posts/`,formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

      },
    });
      return response.data  ;
    } catch (error) {
      //console.error("Error fetching posts:", error);
      return error;
    }
};

export const postBlog = async (formData) => {
  try {
    const config = getAuthConfig();

    const response = await axios.post(`${BASE_URL}/posts/`,formData,config);
      return response.data  ;
    } catch (error) {
      //console.error("Error fetching posts:", error);
      return error;
    }
};


export const getPosts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/posts/`);
    return response.data.results;
  } catch (error) {
    //console.error("Error fetching posts:", error);
    return error;
  }
};
export const getPosts1 = async (postId) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts/${postId}/`);
    return response.data;
  } catch (error) {
    //console.error("Error fetching posts:", error);
    return error;
  }
};
export const delPost = async (postId) => {
  try {
    const config = getAuthConfig();

    const response = await axios.delete(`${BASE_URL}/posts/${postId}/`, config); // Include the teamId in the URL
    
    return response.data;
  } catch (error) {
    //console.error("Error deleting post:", error);
    return error;
  }
};

export const updatePost = async (postId, formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const response = await axios.put(`${BASE_URL}/posts/${postId}/`,formData, {
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

        },
      });

    return response.data;
  } catch (error) {
    //console.error("Error fetching posts:", error);
    return error;
  }
};

//login
export const login = async (username, password) => {
  try {    const accessToken = localStorage.getItem('access');

    const response = await axios.post(`${BASE_URL}/login/admin/`, { username, password });
    return response.data;
  } catch (error) {
    // Check if the error response status is 401 (Unauthorized)
    if (error.response && error.response.status === 401) {
      console.log("Invalid username/password.");
      return error.response.Error; // Or handle this message in a way that suits your UI/UX
    } else {
      console.log("Error logging in:", error.response.Error);
      return error.response.Error // General error message
    }
  }
};


// Logout
export const logout = async () => {
  try {
    // Include the refresh token in the request body
    const refreshToken = localStorage.getItem('refresh');

    const response = await axios.post(`${BASE_URL}/auth/logout/`, {
      refresh: refreshToken,
    });

    return response.data;
    
  } catch (error) {
    //console.error("Error logging out:", error);
    return null;
  }
};


//current user
const getCurrentUser = async () => {
  try {
    const accessToken = localStorage.getItem('access');
    if (!accessToken) {
      console.log("No access token available.");
      return null;
    }

    const response = await fetch(`${BASE_URL}//to/user/info/endpoint`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const userData = await response.json();
    return userData;
  } catch (error) {
    //console.error("Error fetching user data:", error);
    return null;
  }

};

export const getLogs = async () => {
  try {
    const config = getAuthConfig();

    const response = await axios.get(`${BASE_URL}/admin-logs/`, config);

    return response.data.results;
  } catch (error) {
    return error;
  }
};



export const getUsers = async () => {
  try {
    const config = getAuthConfig();
    const response = await axios.get(`${BASE_URL}/users/`, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Return the error detail if it exists in the response
      //console.error("Error fetching users:", error.response.data.detail);

      return { error: error.response.data.detail || 'An error occurred' };
    } else {
      // Handle cases where no response is received
      //console.error("Error fetching users:", error.message);
      return { error: 'An error occurred while fetching users.' };
    }
  }
};


export const postUsers = async (formData) => {
  try {
    const token = localStorage.getItem('userToken');

    const config = getAuthConfig();
    const response = await axios.post(`${BASE_URL}/register/admin/`, formData, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header

        },
      } );
    return response.data;
  } catch (error) {
    console.error("Error posting user:", error.message);
    return error;
  }
};

export const setUserInactive = async (id) => {
  try {
    const token = localStorage.getItem('userToken');
    await axios.post(`${BASE_URL}/users/${id}/inactive/`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    message.success('User set to inactive successfully');
  } catch (error) {
    console.error("Error setting user inactive:", error.message);
    throw error;
  }
};



// export const getUserById = async (id) => {
//   try {
//     const token = localStorage.getItem('userToken');
//     const response = await axios.get(`${BASE_URL}/users/${id}/`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching user:", error.message);
//     throw error;
//   }
// };

// export const updateUser = async (id, formData) => {
//   try {
//     const token = localStorage.getItem('userToken');
//     const response = await axios.put(`${BASE_URL}/users/${id}/update/`, formData, {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error updating user:", error.message);
//     throw error;
//   }
// };

export const getUserById = async (id) => {
  try {
    const token = localStorage.getItem('userToken');
    const response = await axios.get(`${BASE_URL}/users/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.detail || error.message);
  }
};
export const updateUser = async (id, formData) => {
  try {
    const token = localStorage.getItem('userToken');
    const response = await axios.put(`${BASE_URL}/users/${id}/update/`, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.detail || error.message);
  }
};

export const deleteUser = async (id) => {
  try {
    const token = localStorage.getItem('userToken');
    const res=await axios.delete(`${BASE_URL}/users/${id}/delete/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res
    message.success('User deleted successfully');

  } catch (error) {
    console.error("Error deleting user:", error.message);
    throw error;
  }
};
