import React,{useState, useEffect} from "react";
import { getLesson, getLesson1 } from "../../api/UserAPI";

function CurriculumTab({course}) {
  const [lessons, setLessons] = useState([]);
  const courseId = course.id

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await getLesson1(courseId);



        if (Array.isArray(response)) {
          // Check if response is an array
          console.log(response)
          setLessons(response);
        } else {
          console.error("Response is not an array:", response);
        }

        //const data = await response.json();
        console.log(response)
      } catch (error) {
      }
    };

    fetchLessons();
  }, []);


  return (
    <div>
      <div id="curriculum" class="tab-pane ">
        <div class="course-curriculum">
          <ul class="curriculum-sections">
            <li class="single-curriculum-section">
              <div class="section-header">
                <div class="section-left">
                  <h5 class="title">Change simplification</h5>
                  <p class="section-desc">
                    General introduction to {course.title}
                  </p>
                </div>
              </div>
              <ul class="section-content">
              {lessons.map(lesson => (
                
              
                <li class="course-item" key={lesson.id}>
                  <span class="section-item-link lesson">
                    <span class="item-name">
                    {lesson.title}
                    </span>
                  </span>

                  <ul>
                    <li>
                      <span>
                      <div dangerouslySetInnerHTML={{ __html: lesson.content }}></div>
                      </span>
                    </li>
                  </ul>
                </li>
                ))}


                {/* <li class="course-item">
                  <span class="section-item-link lesson">
                    <span class="item-name">
                      Live meeting about Infotech Strategies
                    </span>
                  </span>
                </li>

                <li class="course-item">
                  <span class="section-item-link lesson">
                    <span class="item-name">Lesson 02: A/B Testing</span>
                  </span>
                </li>

                <li class="course-item">
                  <span class="section-item-link lesson">
                    <span class="item-name">
                      Lesson 03: Wrap up about A/B testing
                    </span>
                  </span>
                </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CurriculumTab;
