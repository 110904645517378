import React, { useState, useMemo, useEffect } from "react";
import FooterSection from "../layout/footer/FooterSection";
import { useNavigate } from "react-router-dom";
import Header from "../layout/navbar/HeaderSection";
import { StyledLink, StyledSelect } from "../components/StyledComponents";
import { getEvents } from "../api/UserAPI";
import altImage from "../assets/img/brand/courses-image.jpg";

function Event() {
  const navigate = useNavigate();


  const [filteredEvents, setFilteredEvents] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchSelectOptions = async () => {
    try {

      const response = await getEvents();
      if (Array.isArray(response)) { // Check if response is an array
        console.log(response)
        setEvents(response)
        setFilteredEvents(response)
      } else {
        //console.error("Response is not an array:", response);
      }
      
     
  } catch (error) {
    if (!events) return <div>Events not found</div>;
  }
};

fetchSelectOptions();
  }, []);

  const handleFilterChange = (e) => {
    // make call with e.target.value

    // set response here
    setFilteredEvents(events);
  };

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  return (
    <div>
      <div className="mt-5">
        <Header />
      </div>
      <div></div>

      <div>
        <div class="page-title-section section mt-5" id="top">
          <div class="page-title">
            <div class="container">
              <h1 class="title">Events</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="" style={{ height: "360px" }}></div>
      <div>
        <div className="section section-padding-bottom">
          <div className="container">
            <div className="row justify-content-between align-items-center max-mb-20">
              <div className="col-sm-auto col-12 max-mb-10">
                <p className="result-count">
                  We found <span>{filteredEvents.length}</span> events available for
                  you
                </p>
              </div>
              {/* <div className="col-sm-auto col-12 max-mb-10">
                <StyledSelect
                  className="form-select selectric"
                  onChange={handleFilterChange}
                >
                  <option defaultValue="selected">Default</option>
                  <option value="popularity">Popularity</option>
                  <option value="date">Latest</option>
                  <option value="price">Price: low to high</option>
                  <option value="price-desc">Price: high to low</option>
                </StyledSelect>
              </div> */}
            </div>
            {Array.isArray(filteredEvents) && filteredEvents.length > 0 ? (

            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 max-mb-n30">
              {filteredEvents.map((event) => (
                <div
                  key={event.id}
                  className="col max-mb-30"
                  data-aos="fade-up"
                >
                  <div className="event">
                    <div className="thumbnail">
                      <a
                        className="image"
                        key={event.id}
                        onClick={() => handleEventClick(event.id)}
                      >
                        <img
                          src={event.image}
                          alt="Event Image"
                          style={{
                            objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                            width: '100%', // Ensure the image fills the container's width
                            height: '200px', // Ensure the image fills the container's height
                          }}
                          onError={(e) => {
                            e.target.src = `${altImage}`;
                          }}
                        />
                        <div className="event-overlay-background"></div>
                        <div className="event-overlay-content">
                          {/* <button
                            className="btn btn-md btn-light btn-hover-light theme-color"
                            key={event.id}
                            onClick={() => handleEventClick(event.id)}
                          >
                            Get ticket
                          </button> */}
                        </div>
                      </a>
                    </div>
                    <div className="info">
                      <span className="date">{event.date}</span>
                      <a
                        className="text-decoration-none"
                        key={event.id}
                        onClick={() => handleEventClick(event.id)}
                      >
                        <h3 className="title">
                          <StyledLink
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              maxLines: "2",
                              overflow: "hidden",
                              display: "-webkit-box",
                              webkitBoxOrient: "vertical",
                              webkitLineClamp: "2",
                            }}
                          >
                            {event.title}
                          </StyledLink>
                        </h3>
                      </a>
                      <ul className="event-location">
                        <li>
                          <i className="far fa-map-marker-alt"></i>
                          {event.location}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            ):(
              <div></div>
            )}
          </div>
        </div>
      </div>

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default Event;
