import React from 'react'


const reviews = [
  {
    id: 1,
    authorImage: "",
    authorName: 'ednawatson',
    title: 'Cover all my needs',
    rating: 5,
    text: 'The course identify things we want to change and then figure out the things that need to be done to create the desired outcome. The course helped me in clearly define problems and generate a wider variety of quality solutions. Support more structures analysis of options leading to better decisions.'
  },
  {
    id: 2,
    authorImage: "",
    authorName: 'Owen Christ',
    title: 'Engaging & Fun',
    rating: 5,
    text: 'This is the third course I attend from you, and I absolutely loved all of them. Especially this one on leadership. Your method of explaining the concepts, fun, engaging and with real-world examples, is excellent. This course will help me a lot in my job, my career, and life in general, and I thank you for that. Thank you for improving the lives of many people with engaging and in-depth courses.'
  }
];

function ReviewsTab() {
  return (
    <div>

  <   div id="reviews" className="tab-pane ">
        <div className="course-reviews">
          {/* Course Rating & Reviews List Here */}
          <div className="course-rating">
            <h3 className="title">Reviews</h3>
            {/* Average Rating & Detailed Rating Components Here */}
            {/* Consider breaking down into smaller components if complex */}
          </div>
          <div className="course-reviews-area">
            <ul className="course-reviews-list">
              {reviews.map((review) => (
                <li className="review" key={review.id}>
                  <div className="review-container">
                    <div className="review-author">
                      <img src={review.authorImage} alt="author" />
                    </div>
                    <div className="review-content">
                      <h4 className="title">{review.authorName}</h4>
                      {/* Implement star rating based on review.rating */}
                      <h5 className="review-title">{review.title}</h5>
                      <div className="review-text">{review.text}</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewsTab