import { React, useEffect } from "react";
import Admin from "./Admin";
import BgImageWithNavbar from "../layout/bgheader/BgImageHeader";

import { useState } from "react";
import { getCourses } from "../api/UserAPI";
import Header from "../layout/navbar/HeaderSection";
import { Link } from "react-router-dom";
import "../layout/bgheader/style.css";
import BFooter from "../layout/footer/BFooter";
import FooterSection from "../layout/footer/FooterSection";
import CourseMode from "../layout/bgheader/CourseMode";
import { useNavigate } from "react-router-dom";
import grid from "../assets/img/brand/grid.png";
import { Button } from "../components/StyledComponents";
import { StyledDiv } from "../components/StyledComponents";
import cta1 from "../assets/img/brand/cta-shape-01.png";
import { AiFillStar } from "react-icons/ai";
import altImage from "../assets/img/brand/courses-image.jpg";

function Home() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Subscribed with email: ${email}`);
    setEmail("");
  };

  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const response = await getCourses();
        setCourses(response);
        //setFilteredCourses(response);
      } catch (error) {}
    };

    fetchSelectOptions();
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  function numberWithSeparator(numberString) {
    // Convert numberString to string if it's not already a string
    if (typeof numberString !== "string") {
      numberString = String(numberString);
    }

    // Check if numberString contains a decimal point
    const hasDecimal = numberString.includes(".");

    // Split the string into integer and fractional parts (if any)
    const parts = numberString.split(".");

    // Format the integer part with thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and fractional parts (if any)
    return parts.join(hasDecimal ? "." : "");
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        <BgImageWithNavbar />
      </div>
      <div>
        <CourseMode />
      </div>

      <div>
        <div
          className="section section-padding"
          data-bg-color="#FAF8F6"
          style={{ backgroundColor: "#FAF8F6" }}
        >
          <div className="container">
            <div className="section-title text-center" data-aos="fade-up">
              <span className="sub-title">
                Choose where you'd like to begin
              </span>
              <h2 className="title fz-48">
                Latest Featured <span>Courses</span>
              </h2>
            </div>

            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 max-mb-n30">
              {Array.isArray(courses) && courses.length > 0 ? (
                courses
                  .filter((course) => course.is_featured)
                  .map((course) => (
                    <StyledDiv
                      key={course.id}
                      className="col max-mb-30"
                      data-aos="fade-up"
                    >
                      <div className="course-4 text-decoration-none ">
                        <div
                          className="thumbnail"
                          onClick={() => handleCourseClick(course.id)}
                          style={{ maxHeight: "200px" }}
                        >
                          <div className="image" style={{  }}>
                            <img
                             
                            src={course.imageUrl}
                             
                            alt={course.code}
                            
                            style={{
                              objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                              width: '100%', // Ensure the image fills the container's width
                              height: '200px', // Ensure the image fills the container's height
                            }}
                            onError={(e) => {
                                    e.target.src = `${altImage}`;
                                  }}
                              height={"200px"}
                           
                          />
                          </div>
                        </div>
                        <div className="info">
                          <span className="price">
                            ₦{numberWithSeparator(course.price)}{" "}
                            <span style={{ fontSize: 13 }}>.00</span>
                          </span>

                          <div className="course-instructor">
                            <div className="instructor-avatar">
                              <span className="instructor-name">
                                {course.category_name}
                              </span>{" "}
                            </div>
                          </div>
                          <h6
                            className=""
                            onClick={() => handleCourseClick(course.id)}
                          >
                            <Link
                              className=" text-decoration-none"
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                maxLines: "2",
                                overflow: "hidden",
                                display: "-webkit-box",
                                webkitBoxOrient: "vertical",
                                webkitLineClamp: "2",
                              }}
                            >
                              {course.title}
                              {course.is_featured && (
                                <AiFillStar
                                  style={{
                                    marginLeft: "0.5rem",
                                    color: "gold",
                                  }}
                                />
                              )}
                            </Link>
                          </h6>
                          <ul className="meta">
                            <li>
                              <i className="far fa-file-alt"></i> Location:
                              {course.venue}
                            </li>
                            {course.starting_date
                              && (
                                <>
                                <li>
                                  <i className=""></i>
                                  Starting and Closing Date
                                </li>
                                <li>
                                  <i className=""></i>
                                  {course.starting_date} - {course.ending_date}
                                </li>
                                </>
                                
                                )}
                          </ul>
                        </div>
                      </div>
                    </StyledDiv>
                  ))
              ) : (
                <p>
                  No courses found in the database ...please, upload courses.{" "}
                </p>
              )}
            </div>

            <div className="row mt-4">
              <div className="text-center col-lg-7 mx-auto">
                <Button onClick={() => navigate(`/courses`)}>
                  View all courses{" "}
                  <i className="far fa-long-arrow-right ms-3"></i>
                </Button>
              </div>
            </div>
          </div>

          <div className="section-bottom-shape">
            {/* SVG Shape Here */}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              height="100"
            >
              <path
                class="elementor-shape-fill"
                d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"
              ></path>
            </svg> */}
          </div>
        </div>
      </div>
      <div>
        <div
          className="blog-section section section-padding"
          data-bg-color="#f8f8f8"
        >
          <div className="container">
            <div className="row row-25 row-cols-lg-3 row-cols-md-2 row-cols-1">
              {/* Blog Start */}
              <div className="col max-mb-10" data-aos="fade-up">
                {/* Team Image Wrapper Start */}
                <div className="team-image-wrap">
                  <div className="team-image">
                    <img src={grid} alt="team" style={{ maxWidth: "110%" }} />
                  </div>
                  {/* Animation Shape Start */}
                  <div className="shape shape-1 scene">
                    <span data-depth="3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 395 397"
                        version="1.1"
                        width="395px"
                        height="397px"
                      >
                        <g transform="translate(-51.000000, -1390.000000)">
                          <g transform="translate(-28.000000, 963.000000)">
                            <path d="M353.907509,817.715288 C386.112868,835.281848 455.192882,815.572841 468.907509,740.715288 C483.008804,663.747218 442.907509,661.715288 449.907509,633.715288 C478,592 483.008804,538.76355 453.907509,506.715288 C381.907509,431.715288 363.907509,521.715288 303.907509,466.715288 C279.650772,442.458551 242.907509,413.715288 189.907509,433.715288 C114.974471,461.991907 132.907509,521.005066 132.907509,563.715288 C132.907509,578.753612 85.1423289,607.023718 79.9075093,645.715288 C73.306976,694.501115 103.907509,758.715288 132.907509,780.715288 C178.907509,832.715288 249.907509,820.715288 281.907509,802.715288 C309.384918,787.259246 331.907509,805.715288 353.907509,817.715288 Z"></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="shape shape-2 scene">
                    <span data-depth="-3">
                      <img src="https://i.ibb.co/HNcV7jZ/shape-3.png" alt="" />
                    </span>
                  </div>
                  <div className="shape shape-3 scene">
                    <span data-depth="4">shape 3</span>
                  </div>
                  <div className="shape shape-4 scene">
                    <span data-depth="4">
                      <img src="https://i.ibb.co/gzr1LWN/shape-1.png" alt="" />
                    </span>
                  </div>
                  <div className="shape shape-5 scene">
                    <span data-depth="5">
                      <img src={cta1} alt="" />
                    </span>
                  </div>
                  {/* Animation Shape End */}
                </div>
              </div>
              {/* Blog End */}

              <div className="col max-mb-30" data-aos="">
                <div className="blog-list-post-wrapper">
                  {/* Section Title Start */}
                  <div className="section-title text-left mt-25 mb-25">
                    <span className="sub-title">What we do at</span>
                    <h2 className="title">
                      NNPC<span> Academy</span>
                    </h2>
                  </div>
                  {/* Section Title End */}
                  <ul className="blog-list-post">
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Strategic Learning Needs Analysis
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Competency Engineering
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Design, Development & Delivery of Capacity Building
                        Programming
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Bespoke Learning Interventions
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Certification Program
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Blog Start */}
              <div className="col max-mb-30" data-aos="">
                <div className="blog-list-post-wrapper">
                  {/* Section Title Start */}
                  <div
                    className="section-title text-left mt-25 mb-25"
                    style={{ visibility: "hidden" }}
                  >
                    <span className="sub-title">Enjoyable insights</span>
                    <h2 className="title">
                      What we <span> do</span>
                    </h2>
                  </div>
                  {/* Section Title End */}
                  <ul className="blog-list-post">
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Blending Approach to learning
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Corporate Retreats
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Pre and Post-Training Evaluations
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Customer Focused Consultancy Services
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-decoration-none">
                        Post-Training Mentorship
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Blog End */}
            </div>
          </div>
        </div>
      </div>

      <div></div>
      <div>
        <BFooter />
      </div>
      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default Home;
