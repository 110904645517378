import React, { useState, useEffect } from 'react'
import { delPost, getPosts, postBlog } from '../../../api/UserAPI';
import { useFetchData } from '../hooks/useFetchData';
import { GenericDataManagementComponent } from '../hooks/GenericBlocs';
import { useNavigate } from 'react-router-dom';
import { Drawer, theme, Modal, Space } from 'antd';
import {   FormGroup, Label,Input,Form, Button } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import altImage from "../../../assets/img/brand/courses-image.jpg";
import {  StyledSelect, } from "../../../components/StyledComponents";


const Blogs = () => {

  //const { data: blogs, isLoading, notFound } = useFetchData(getPosts);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const blogsPerPage = 9;
  const [blogs, setBlogs] = useState([])
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [post, setPost] = useState({
    title: '',
    content: '',
    tag:'',
    image: null,
  });
  const [selectedTag, setSelectedTag] = useState('');
  const uniqueTags = [...new Set(blogs.map((blog) => blog.tag))];

  const [selectedSortOrder, setSelectedSortOrder] = useState('asc'); // Default to ascending order

  const filteredBlogs = blogs.filter((blog) => {
    if (selectedTag === '') {
      return true; // Show all blogs if no tag is selected
    }
    return blog.tag === selectedTag; // Filter blogs by selected tag
  });
  const [selectedBlogId, setSelectedBlogId] = useState(null);

  const handleSortChange = (e) => {
    setSelectedSortOrder(e.target.value);
  };

  const sortedBlogs = blogs.sort((a, b) => {
    if (selectedSortOrder === 'asc') {
      return new Date(a.date) - new Date(b.date); // Sort in ascending order
    } else {
      return new Date(b.date) - new Date(a.date); // Sort in descending order
    }
  });

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  useEffect(()=>{

    const fetchBlog = async () => {

      try{

        const response = await getPosts()
        setBlogs(response);
              
      } catch(error){

      }

    }
  fetchBlog();

  }, [])

  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  // const showModal = () => {
  //   setOpenModal(true);
  // };
  const showModal = (id) => {
    setSelectedBlogId(id);
    setOpenModal(true);
  };
  const hideModal = () => {
    setSelectedBlogId(null);
    setOpenModal(false);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentblog = filteredBlogs.slice(
    (currentPage - 1) * blogsPerPage,
    currentPage * blogsPerPage
  );


const handleTagChange = (event) => {
  setSelectedTag(event.target.value);
};




  const handleCKEditorChange = (name, editor) => {
    const value = editor.getData();
    setPost({ ...post, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      const file = e.target.files[0];
      
          const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
          if (!validImageTypes.includes(file.type)) {
              alert("Please upload a valid image file (JPEG, PNG, GIF).");
              e.target.value = ""; // Clear the input
              return;
          };
        
          if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
              alert("Image size is too heavy. Please upload an image less than 2MB.");
              e.target.value = ""; // Clear the input
          }else{
              setPost({ ...post, [name]: e.target.files[0] });
          }
    } else {
      setPost({ ...post, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('title', post.title);
    formData.append('content', post.content);
    formData.append('tag', post.tag);

    if (post.image) {
      formData.append('image', post.image);
    }

    try {
     const blog = await postBlog(formData) 
     console.log(blog)
     if (!blog.error){
        alert('The blog is created successfully')
        window.location.reload();
      }

      setLoading(false);
      // Reset form or redirect user
      setPost({ title: '', content: '', image: null });
      // Optionally, redirect the user or give feedback

    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.',error);
      setLoading(false);
    }
  };
  // const blogAttributes = [
    
  //   { label: "Title", value: "title" },
  //   { label: "Content", value: "content" },
  //   { label: "Tag(s)", value: "tag" },
  //   { label: "Image Url", value: "image" },
  //   { label: "Date", value: "date" },

  // ];

  const handleDelete = async (blogId) => {
    // Confirm deletion
    // if (window.confirm("Are you sure you want to delete this post?")) {
    try{
      const res = await delPost(blogId);
      setMessage("Blog  deleted successfully");

      // Refresh data or handle UI update
      window.location.reload();

    }catch(error){
      setError(error)

    }
      


    // }

  };

  const handleEdit = async (blogId) => {
    navigate(`edit-blog/${blogId}`)
  };

  const blogActions = [
    { label: "Edit", buttonClass: "btn-info", handler: (item) => handleEdit(item.id) },
    { label: "Delete", buttonClass: "btn-danger", handler: (item) => handleDelete(item.id) },
  ];

  const handleCreate = () =>{
    navigate(`add-blog`);

  }

  const formatDate = (dateTimeString) => {
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      //hour: '2-digit',
      //minute: '2-digit',
      //second: '2-digit',
      //hour12: true
    };
  
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };

  return (
    <div> 
      <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Blogs</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          <button  style={{color:'white', background:'green', padding:10, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon> New blog</button>
          </div>
        </div>
      </div>
      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>
        <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <p className='m-2' style={{ fontWeight: 600, color:'#1A2F3E'}}>We found <span>{filteredBlogs.length} </span> Blogs available for you</p>
          </div>
        </div>
        <div className="col-6 text-end d-flex justify-content-between "> 
        
        
            <StyledSelect className="m-1" value={selectedSortOrder} onChange={handleSortChange}>
              <option value="asc">Sort by Date  (Ascending)</option>
              <option value="desc">Sort by Date (Descending)</option>
            </StyledSelect>
            
            <StyledSelect className="m-1" value={selectedTag} onChange={handleTagChange}>
              <option value="">All Tags</option>
              {uniqueTags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </StyledSelect>
        </div>
      </div>
      <div className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2  row-cols-1 max-mb-n30">
              {currentblog.map((blog) => (
                <div
                  key={blog.id}
                  className="col max-mb-30"
                  data-aos="fade-up"
                >
                  <div className="event">
                    <div className="thumbnail">
                      <a
                        className="image"
                        key={blog.id}
                        // onClick={() => handleEventClick(event.id)}
                      >
                        <img
                          src={blog.image}
                          alt="Event Image"
                          style={{
                            objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                            width: '100%', // Ensure the image fills the container's width
                            height: '200px', // Ensure the image fills the container's height
                          }}

                          onError={(e) => {
                            e.target.src = `${altImage}`;
                          }}

                          height={"200px"}                       
                           />
                        <div className="event-overlay-background"></div>
                        <div className="event-overlay-content">
                          
                        </div>
                      </a>
                    </div>
                    <div className="info">
                      <span className="date"> {formatDate(blog.date)}
                      </span>
                      <a
                        className="text-decoration-none"
                        key={blog.id}
                        // onClick={() => handleEventClick(event.id)}
                      >
                        <h3 className="title">
                          <div
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              maxLines: "2",
                              overflow: "hidden",
                              display: "-webkit-box",
                              webkitBoxOrient: "vertical",
                              webkitLineClamp: "2",
                            }}
                          >
                            {blog.title}
                          </div>
                        </h3>
                      </a>
                      <ul className="event-location">
                        <li>
                          <i className=""></i>
                          {blog.tag}
                        </li>
                      </ul>
                    </div>

                    <div className='team-info text-center'>
                      
                       
                      <Button color="success" onClick={()=> handleEdit(blog.id)}>Update {" "}       
                    </Button>
                        
                                      
                        <button className="btn btn-danger m-1" onClick={() => showModal(blog.id)}>Delete </button>
                        
                        <Modal
                          title={<div style={{ textAlign: 'center' }}>Deletion confirmation</div>}
                          open={openModal}
                          onCancel={hideModal}
                          footer={null}
                        >
                          <p style={{ textAlign: 'center' }}>Are you sure you want to delete this event?</p>
                          <div style={{ textAlign: 'center' }}> 
                          <Button className='btn btn' onClick={hideModal}>No</Button>                          
                          <Button className="btn btn-danger m-1" onClick={() => handleDelete(selectedBlogId)}>Yes </Button>
                          </div>

                        </Modal>
                      </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='mt-3' style={{textAlign:'center'}}>
            <ul className="pagination " style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              {Array(totalPages)
                .fill(0)
                .map((_, index) => (
                  <li key={index}>
                    <a
                      href="#"
                      onClick={() => handlePageChange(index + 1)}
                      className={index + 1 === currentPage ? 'active' : ''}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
            </ul>

          </div>

        </div>
      <div>
      <Drawer
        title="Add a new post"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            
          </Space>
        }
      >

<Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" name="title" id="title" value={post.title} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="content">Content</Label>
          {/* <Input type="textarea" name="content" id="content" value={post.content} onChange={handleChange} style={{ minHeight: '250px' }} required /> */}
          <CKEditor
            editor={ClassicEditor}
            data={post.content || ''}
            onChange={(event, editor) => handleCKEditorChange('content', editor)}
            onBlur={(event, editor) => {
              //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //console.log('Focus.', editor);
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="title">Tag(s)</Label>
          <Input type="text" name="tag" id="tag" value={post.tag} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image </Label>
          <Input type="file" name="image" id="image" onChange={handleChange} />
        </FormGroup>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        <Button type="submit" color="primary" disabled={loading}>
          {loading ? 'Creating...' : 'Create Post'}
        </Button>
      </Form>
        

      </Drawer>
      </div>
    


      {/* <div className='mb-3'>
     <Button color="primary" onClick={()=>handleCreate()}>Add New Blog{" "}       
      </Button>
      {message && (
                            <div className="alert alert-success" role="alert">
                              {message}
                            </div>
                          )}
    </div> */}
      {/* <div>
        <GenericDataManagementComponent
        title="Blogs "
        isLoading={isLoading}
        notFound={notFound}
        data={blogs}
        dataAttributes={blogAttributes}
        actions={blogActions} />
      </div> */}
    </div>
  )
}

export default Blogs