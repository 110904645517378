// Layout.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Home from '../pages/Home.js';
import AboutUs from '../pages/AboutUs.js';
import LoginPage from '../pages/admin/Login.js';
import AuthComponent from '../pages/admin/LoginModal.js';
import Courses from '../pages/Courses.js';
import Stories from '../pages/Stories.js';
import ContactUs from '../pages/ContactUs.js';
import Event from '../pages/Event.js';
import CustomizeCourse from '../pages/CustomizeCourse.js';
import TeamSection from '../pages/TeamSection.js';
import CourseDetails from '../pages/CourseDetails.js';
import EventDetails from '../pages/EventDetails.js';
import BlogNews from '../pages/BlogNews.js';
import BlogDetails from '../pages/BlogDetails.js';
import { Navigate } from 'react-router-dom';
import AdminDashboard from '../pages/admin/AdminDashboard.js';
import RequireAuth from './Auth.js';
import ScrollToTop from './ScrollTop';



const Layout = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>

        <Route path="/"  element={<Home />} />
        <Route path="/nnpcacademy"  element={<Home />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/stories" element={<Stories/> } />
        <Route path="/login" element={<AuthComponent />} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/event" element={<Event/>} />
        <Route path="/customize" element={<CustomizeCourse/>} />
        <Route path="/our-team" element={<TeamSection/>} /> 
        <Route path="/course/:courseId" element={<CourseDetails />} />
        <Route path="/event/:eventId" element={<EventDetails />} />
        <Route path="/blognews" element={<BlogNews/>} />
        <Route path="/blog/:blogId" element={<BlogDetails/>} />

      
        <Route path="/admin/*" element={<RequireAuth><AdminDashboard /></RequireAuth>} />
        







      </Routes>   
    </Router>
  );
}; 

export default Layout;
