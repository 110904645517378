import React, { useState } from 'react';
import axios from 'axios'; // Make sure to install axios if you haven't
//import { useHistory } from 'react-router-dom'; // This assumes you're using react-router for navigation
import { login, logout } from '../../api/UserAPI';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Fixed the import statement

const LoginModal = ({ show, handleClose, onLoginSuccess  }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  //const history = useHistory(); // Hook to control navig

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const data = await login(username, password);
      
      if (data && data.access) {
        localStorage.setItem('userToken', data.access); 
        localStorage.setItem('refresh', data.refresh); 
        localStorage.setItem('userData', JSON.stringify(data.user));

        let accc = localStorage.getItem('userToken')
        let rfff = localStorage.getItem('refresh')
        // const user = jwtDecode(rfff)

        // console.log(rfff)
        //console.log(rfff)

          navigate(`/admin`)
          onLoginSuccess();
      } else {
         

        setError(data ? data:'Inavalid username/password');
      }
    } catch (err) {
      setError(err.data ? err.data.detail : 'Sever connection failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  if (!show) return null;

  return (
<div className="modal show d-block " tabIndex="-1" role="dialog" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 1rem)', backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog " role="document" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(75vh - 1rem)' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title "> Admin Login</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body mb-3">
            <form onSubmit={handleSubmit}>
              {error && <div className="alert alert-danger" role="alert">{error}</div>}
              <div className="form-group">
                <input type="username" className="form-control mb-3" style={{borderColor:'#28a745', height:'50px'}}  placeholder='Email' value={username} onChange={(e) => setUsername(e.target.value)} required />
              </div>
              <div className="form-group mb-3">
                <input type="password" className="form-control " placeholder='Password' style={{borderColor:'#28a745', height:'50px'}} value={password} onChange={(e) => setPassword(e.target.value)} required />
              </div>
              <div className='container row  justify-content-center align-items-center' >
                <div className='d-grid col-6 mx-auto'>
                <button type="submit" className="btn btn-success "  disabled={loading}>
                {loading ? <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span> : 'Login'}
              </button>
                </div>
              
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
