import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-4 pb-4">
      <div className="container">
        <div className="row">
          {/* Quick Links */}
          <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/about" className="text-white">About Us</a></li>
              <li><a href="/services" className="text-white">Services</a></li>
              <li><a href="/blog" className="text-white">Blog</a></li>
              <li><a href="/contact" className="text-white">Contact Us</a></li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
            <h5>Contact Info</h5>
            <p>123 Street Name, Abuja, Nigeria.</p>
            <p>Phone: +234 456 7890</p>
            <p>Email: info@nnpcacademy.com</p>
          </div>

          {/* About the Company */}
          <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
            <h5>About</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum.</p>
          </div>

          {/* Social Media Links */}
          <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
            <h5>Follow Us</h5>
            <div className="d-flex justify-content-between" >
              <a href="#" className="text-white mr-2"><FaFacebookF  size={50}/></a>

              <a href="#" className="text-white mr-2"><FaTwitter  size={50}/></a>
              <a href="#" className="text-white mr-2"><FaInstagram  size={50}/></a>
              <a href="#" className="text-white mr-2"><FaLinkedinIn  size={50}/></a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="row mt-3">
          <div className="col-12 text-center">
            <p>&copy; {new Date().getFullYear()} NNPCL Academy. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
