import React, {useEffect, useState} from 'react';
import { delCourse, getCourses } from '../../../api/UserAPI';
import { useFetchData } from '../hooks/useFetchData';
import { GenericDataManagementComponent } from '../hooks/GenericBlocs';
//import { Button } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { StyledDiv, StyledAnchor, StyledSelect, } from "../../../components/StyledComponents";
import { AiFillStar } from "react-icons/ai";
import altImage from "../../../assets/img/brand/courses-image.jpg";
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { theme, Col, DatePicker, Drawer, Row, Select, Space, Popconfirm, Modal  } from 'antd';
import { getAudiance, getCategory, getInstructors, postCourses } from '../../../api/UserAPI';
import {   FormGroup, Label,Input,Form, Button } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



function Courses() {

  //const { data: courses, isLoading, notFound } = useFetchData(getCourses);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noCoursesFound, setNoCoursesFound] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [instructors, setInstructors] = useState([])
  const [open, setOpen] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [filter, setFilter] = useState({ venue: "", category: "", price: "" });
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const showModal = (id) => {
    setSelectedItem(id)
    setOpenModal(true);
  };
  const hideModal = () => {
    setSelectedItem(null)
    setOpenModal(false);
  };

  const coursesPerPage = 12;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  

  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true)
        try {
            const response = await getCourses();
            if (!response || response.length === 0) {
              setNoCoursesFound(true); // 
              setCourses([]);
          } else {
              setCourses(response);
              //console.log(response)                    
              setNoCoursesFound(false); //
            }

            
        } catch (error) {
        } finally {
          setIsLoading(false); // Set loading to false when the request is done
        }
    };

    fetchCourses();
}, [refresh]);


const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);

// // Scroll to top when currentPage changes
// useEffect(() => {
//   window.scrollTo({ top: 0, behavior: 'smooth' });
// }, [currentPage]);


  // const courseAttributes = [

  //   { label: "Course code", value: "code" },
  //   { label: "Title", value: "title" },
  //   { label: "Venue", value: "venue" },
  //   { label: "Price", value: "price" },
  //   { label: "Category", value: "category_name" }, 
  //   { label: "Is featured", value: "is_featured", formatter: isFeaturedText },          
  //   { label: "Image", value: "imageUrl" },


  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const categoriesRes = await getCategory();
        //console.log(categoriesRes)
        setCategories(categoriesRes);

        const audiencesRes = await getAudiance();
        setAudiences(audiencesRes);

       // const instructorsRes = await getInstructors();
        //setInstructors(instructorsRes);


       
      } catch (error) {
        console.error('Failed to fetch select options:', error);
        setError('Failed to fetch select options.');
      }
    };

    fetchSelectOptions();
  }, []);

  // const handleCKEditorChange = (editor) => {
  //   const description = editor.getData();
  //   setFormData(prevData => ({
  //     ...prevData,
  //     description: description,
  //   }));
  // };

  const handleCKEditorChange = (name, editor) => {
    const value = editor.getData();
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange = (e) => {

    const { name, type, checked, value, files, options } = e.target;
    if (type === 'file') {
      const file = e.target.files[0];
      
          const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
          if (!validImageTypes.includes(file.type)) {
              alert("Please upload a valid image file (JPEG, PNG, GIF).");
              e.target.value = ""; // Clear the input
              return;
          };
        
          if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
              alert("Image size is too heavy. Please upload an image less than 2MB.");
              e.target.value = ""; // Clear the input
          }else{
            setFormData((prevData) => ({
              ...prevData,
              [name]: e.target.files[0] 
            }));
          }
    } else if (type === 'checkbox') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked, // Directly use the checked property for checkboxes
        }));}else if (type === 'select-multiple') {
            // Collect all selected options for a multi-select
            const selectedOptions = Array.from(options)
                                          .filter(option => option.selected)
                                          .map(option => option.value);
            setFormData((prevData) => ({
              ...prevData,
              [name]: selectedOptions,
            }));}
         else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    try {
      const response = await postCourses(formDataToSend);

      if (!response.error) {
        alert('course added successful')
        // navigate('/admin/courses');
        setRefresh(prev => !prev); 


      }else{
        //navigate('/successPath'); // Adjust navigation path as needed
      }

     
    } catch (error) {
      console.error('Submission error:', error);
      setError('Submission failed.');
    } finally {
      setLoading(false);
    }
  };

  const fields = [
    { name: 'code', type: 'text', label: 'Course Code' },
    { name: 'title', type: 'text', label: 'Course Title' },
    { name: 'description', type: 'textarea', label: 'Description' },
    { name: 'category', type: 'select', label: 'Category', options: categories.map(cat => ({ value: cat.id, label: cat.name })) },
    { name: 'target_audience', type: 'select', label: 'Target Audience', options: audiences.map(aud => ({ value: aud.id, label: aud.name })) },
    { name: 'price', type: 'text', label: 'Price' },
    { name: 'duration', type: 'text', label: 'Duration' },
    { name: 'is_featured', type: 'checkbox', label: 'Is Featured' },
    { name: 'imageUrl', type: 'file', label: 'Image ' },
    { name: 'venue', type: 'text', label: 'Venue' },
    // { name: 'date', type: 'date', label: 'Date' },    
    { name: 'starting_date', type: 'date', label: 'Starting Date' },    
    { name: 'ending_date', type: 'date', label: 'Ending Date' },    
    { name: 'more_details', type: 'textarea', label: 'More Details' },


    // Add other fields as necessary
  ];

  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentCourses = filteredCourses.slice(
    (currentPage - 1) * coursesPerPage,
    currentPage * coursesPerPage
  );

  const handleDelete = async (courseId) => {
    //setIsLoading(true);
  //   const isConfirmed = window.confirm("Are you sure you want to delete this course?");
  
  // // If the user confirmed, call the delete function
  // if (isConfirmed) {
    
  
    try {
        // Pass the `teamId` to `delTeam`
        const response = await delCourse(courseId);
        if (!response) {
           // setNoTeamFound(true);
        } else {
          setMessage("course  deleted successfully");
      
        }
        setRefresh(prev => !prev); 

    } catch (error) {
        console.error("Could not delete course.", error);
        alert('unable to delete the course')
    } finally {
        //setIsLoading(false);
    }

  //}

  }
  const handleEdit = async (courseId) => {
    navigate(`edit-course/${courseId}`)
  };

  useEffect(() => {
    filterCourses(filter);
  }, [filter, courses]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterCourses = (filterCriteria) => {
    let updatedCourses = courses.filter(
      (course) =>
        (filterCriteria.venue ? course.venue === filterCriteria.venue : true) &&
        (filterCriteria.category
          ? course.category_name === filterCriteria.category
          : true) &&
        (filterCriteria.price ? course.price <= filterCriteria.price : true)
    );
    setFilteredCourses(updatedCourses);
  };

  
  

  // const courseActions = [
  //   { label: "Update", buttonClass: "btn-success", handler: (item) => handleEdit(item.id) },
  //   { label: "Delete", buttonClass: "btn-danger", handler: (item) => handleDelete(item.id) },
  // ];


  const handleCreate = () =>{
    navigate(`add-course`);

  }
  function numberWithSeparator(numberString) {
    // Convert numberString to string if it's not already a string
    if (typeof numberString !== "string") {
      numberString = String(numberString);
    }

    // Check if numberString contains a decimal point
    const hasDecimal = numberString.includes(".");

    // Split the string into integer and fractional parts (if any)
    const parts = numberString.split(".");

    // Format the integer part with thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and fractional parts (if any)
    return parts.join(hasDecimal ? "." : "");
  }

  


  return (
    <div>

      <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Courses</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          <button  style={{color:'white', background:'green', padding:10, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon> New course</button>
          </div>
        </div>
      </div>

      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>
        <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <p style={{ fontWeight: 600, color:'#1A2F3E'}}>We found <span>{filteredCourses?.length}</span> courses available for you</p>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
            <StyledSelect
              className=""
              name="category"
              value={filter.category}

              onChange={handleFilterChange}
              
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.name} value={category.name}>
                  {category.name}
                </option>
              ))}
            </StyledSelect>
            
            
          {/* <button  style={{color:'white', background:'green', padding:5, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon> New course</button> */}
          </div>
        </div>
      </div>
      <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 max-mb-n30">
        
            {currentCourses.map((course) => (
              <div
                key={course.id}
                className="col max-mb-30"
                // data-aos="fade-up"
              >
                <div
                  className="course-4 text-decoration-none"
                  key={course.id}
                  // onClick={() => handleCourseClick(course.id)}
                >
                  <div className="thumbnail" style={{ maxHeight: "200px" }}>
                    <Link className="image">
                      <img
                        src={course.imageUrl}
                        alt=" CoursesImage "
                        style={{
                          objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                          width: '100%', // Ensure the image fills the container's width
                          height: '200px', // Ensure the image fills the container's height
                        }}

                        onError={(e) => {
                          e.target.src = `${altImage}`;
                        }}
                        height={"200px"}
                      />
                    </Link>
                  </div>

                  <div className="info ">
                    <div className="instructor-avatar">
                      <span className="instructor-name">
                        {course.category_name}
                      </span>
                      
                    </div>
                    <span className=" fw-bold text-success">
                      <b>
                        <span>₦{numberWithSeparator(course.price)}</span>
                        <span style={{ fontSize: 13 }}>.00</span>
                      </b>
                    </span>
                    <h6 className="">
                      <Link
                        className="text-decoration-none"
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          maxLines: "2",
                          overflow: "hidden",
                          display: "-webkit-box",
                          webkitBoxOrient: "vertical",
                          webkitLineClamp: "2",
                        }}
                      >
                        {course.title}
                        {course.is_featured && (
                          <AiFillStar
                            style={{ marginLeft: "0.5rem", color: "gold" }}
                          />
                        )}
                      </Link>
                    </h6>
                    <ul className="meta">
                      <li style={{
                          
                          maxLines: "1",
                          overflow: "hidden",
                          display: "-webkit-box",
                          webkitBoxOrient: "vertical",
                          webkitLineClamp: "1",
                        }}>
                        <i className="far fa-file-alt"></i>
                        {course.venue}
                      </li>
                      {course.students && (
                        <li>
                          <i className="far fa-user-alt"></i>
                          {course.students}
                        </li>
                      )}
                    </ul>

                    <ul className="meta">
                      <li>
                       Starting and Closing Date
                      </li>

                      <li>
                        <i className=""></i>
                        {course.starting_date}
                      </li> 
                  
                        <li>
                          <i className=""></i>
                          {course.ending_date}
                        </li>
                    </ul>
                  </div>
                  
                  <div className='team-info text-center'>
                      
                       
                      <Button color="success" onClick={()=> handleEdit(course.id)}>Update {" "}       
                    </Button>
                        
                                      
                        <button className="btn btn-danger m-1" onClick={()=>showModal(course.id)}>Delete</button>
                        
                        <Modal
                          title={<div style={{ textAlign: 'center' }}>Deletion confirmation</div>}
                          open={openModal}
                          onCancel={hideModal}
                          footer={null}
                        >
                          <p style={{ textAlign: 'center' }}>Are you sure you want to delete this course?</p>
                          <div style={{ textAlign: 'center' }}> 
                          <Button className='btn btn' onClick={hideModal}>No</Button>                          
                          <Button className="btn btn-danger m-1" onClick={() => handleDelete(selectedItem)}>Yes </Button>
                          </div>

                        </Modal>
                        
                      </div>
                      
                </div>
                
              </div>
            ))}
           
          </div>
          <div style={{textAlign:'center'}}>
          <ul className="pagination " style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        {Array(totalPages)
          .fill(0)
          .map((_, index) => (
            <li key={index}>
              <a
                href="#"
                onClick={() => handlePageChange(index + 1)}
                className={index + 1 === currentPage ? 'active' : ''}
              >
                {index + 1}
              </a>
            </li>
          ))}
      </ul>

          </div>

          
          



        {/* <GenericDataManagementComponent
        title="Courses "
        isLoading={isLoading}
        notFound={notFound}
        data={courses}
        dataAttributes={courseAttributes}
        actions={courseActions} /> */}
      </div>
      <div>
        <Drawer
        title="Add a new course"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            
          </Space>
        }
      >
        {/* <Form layout="vertical" > */}
        <Form onSubmit={handleSubmit}  encType="multipart/form-data">
        {fields.map((field) =>
          field.type === 'select' ? (
            <FormGroup key={field.name} style={{ width: '48%', display: 'inline-block', margin:5 }} >
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="select"
                name={field.name}
                id={field.name}
                value={formData[field.name] || ''}
                onChange={handleChange}
                required
              >
                <option value="">Select...</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value} required>
                    {option.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ) : field.type === 'file' ? (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="file"
                name={field.name}
                id={field.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
          ) :  field.type === 'textarea' ? (
            <div key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <CKEditor
                editor={ClassicEditor}
                data={formData[field.name] || ''}
                onChange={(event, editor) => handleCKEditorChange(field.name, editor)}
                onBlur={(event, editor) => {
                  //console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  //console.log('Focus.', editor);
                }}
              />
            

            </div>

          ):(
            <FormGroup key={field.name} style={{ width: '48%', display: 'inline-block', margin:5 }}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type={field.type}
                name={field.name}
                id={field.name}
                value={field.type === 'checkbox' ? undefined : formData[field.name] || ''}
                checked={formData[field.name] || false}                
                 onChange={handleChange}
                 
              />
            </FormGroup>
          )
        )}
        {error && <div className="alert alert-danger">{error}</div>}
        <Button className='m-3' onClick={onClose}>Cancel</Button>

        <Button type="submit" color="success" disabled={loading}>
          {loading ? 'Adding...' : 'Add New Course'}
        </Button>
            
      </Form>
          {/* <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name',
                  },
                ]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="url"
                label="Url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter url',
                  },
                ]}
              >
                <Input
                  style={{
                    width: '100%',
                  }}
                  addonBefore="http://"
                  addonAfter=".com"
                  placeholder="Please enter url"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              
            </Col>
            <Col span={12}>
              
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="approver"
                label="Approver"
                rules={[
                  {
                    required: true,
                    message: 'Please choose the approver',
                  },
                ]}
              >
                
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dateTime"
                label="DateTime"
                rules={[
                  {
                    required: true,
                    message: 'Please choose the dateTime',
                  },
                ]}
              >
                <DatePicker.RangePicker
                  style={{
                    width: '100%',
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'please enter url description',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter url description" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'please enter url description',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter url description" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'please enter url description',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter url description" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'please enter url description',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter url description" />
              </Form.Item>
            </Col>
          </Row> */}
        {/* </Form> */}
      </Drawer>
      </div>
      
    </div>

  )
}

export default Courses
