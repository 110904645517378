import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { editTeam, getTeam1 } from '../../../../api/UserAPI';
import { theme,   } from 'antd';

const EditTeamPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [teamMember, setTeamMember] = useState({
    name: '',
    position: '',
    bio: '',
    image: null,
    facebookUrl: '',
    twiiterUrl: '',
    linkedinUrl: '',
  });
  const  teamMemberId  = useParams(); // Assuming you're using React Router and the URL is like /edit-team/:teamMemberId
  const navigate = useNavigate();
  const Id = (teamMemberId.teamId);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  useEffect(() => {
    
    const fetchTeamMemberData = async () => {
      try {

        const response = await getTeam1(Id);

        //console.log(response)
        setTeamMember(response);

      } catch (error) {
        // Handle error, e.g., show notification or redirect
        setError(error)
      }
    };

    fetchTeamMemberData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setTeamMember({ ...teamMember, [name]: e.target.files[0] });
    } else {
      setTeamMember({ ...teamMember, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const formData = new FormData();
    formData.append('name', teamMember.name);
    formData.append('position', teamMember.position);
    formData.append('facebookUrl', teamMember.facebookUrl);
    formData.append('twiiterUrl', teamMember.twiiterUrl)
    formData.append('linkedinUrl', teamMember.linkedinUrl)
    formData.append('bio', teamMember.bio);
    if (teamMember.image) {
      formData.append('image', teamMember.image);
    }

    try {
      const response = await editTeam(Id, formData); // Await the editTeam function call
      if (response) {
        setMessage("Team member updated successfully");
        setLoading(false);
        // Here you might want to navigate back or to a specific page
        navigate('/admin/teams');
      }
    } catch (error) {
      setError( error.message || "Something went wrong.");
      setLoading(false);
    }
  };

  // Removed modal specific JSX, you can now directly return your form
  return (
    <div className='mt-5' //style={{ maxWidth: '600px', margin: '0 auto' }}
    >
       <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Update Team</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          </div>
        </div>
      </div>
      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>  
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input type="text" placeholder='Enter Name' name="name" id="name" value={teamMember.name} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="position">Position</Label>
          <Input type="text" placeholder='Enter Position' name="position" id="position" value={teamMember.position} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="bio">Bio</Label>
          <Input type="textarea" name="bio" id="bio" value={teamMember.bio} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
              <Input type="text" placeholder='facebook url' name="facebookUrl" id="facebookUrl"value={teamMember.facebookUrl}  onChange={handleChange}  />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='twitter url' name="twiiterUrl" id="twiiterUrl" value={teamMember.twiiterUrl}  onChange={handleChange}  />
            </FormGroup>
            <FormGroup>
              <Input type="text" placeholder='linkedin url' name="linkedinUrl" id="linkedinUrl" value={teamMember.linkedinUrl} onChange={handleChange}  />
            </FormGroup>
        <FormGroup>
          <Label for="image"></Label>
          <img src={teamMember.image} alt="Current-image" height={200} className='mb-2' />
          <Input type="file" name="image" id="image" onChange={handleChange} required/>
        </FormGroup>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        {message && <div className="alert alert-success" role="alert">{message}</div>}
        <Button type="submit" color="success" disabled={loading}>
          {loading ? 'Updating...' : 'Update Team Member'}
        </Button>
        
      </Form>
      </div>
    </div>
  );
};

export default EditTeamPage;




