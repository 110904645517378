import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { postEvents } from '../../../../api/UserAPI';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EventCreate = () => {
  const [event, setEvent] = useState({
    title: '',
    location: '',
    description: '',
    date: '',
    image: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setEvent({ ...event, [name]: e.target.files[0] });
    } else {
      setEvent({ ...event, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('title', event.title);
    formData.append('description', event.description);
    formData.append('location', event.location);
    formData.append('date', event.date)
    if (event.image) {
      formData.append('image', event.image);
    }
    try {
      const event = await postEvents(formData)
      console.log(event)
      if (!event.error){
        alert('The Event is created successfully')
        navigate('/admin/events');
      }
      setLoading(false);
      // Reset form or redirect user
      setEvent({ title: '', description: '', location: '', date: '', image: null });
      // Optionally, redirect the user or give feedback
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.',error);
      setLoading(false);
    }
  };
  

  return (
    <div className='create-blog-container'>
      <h2>Add New Event</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" name="title" id="title" value={event.title} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="location">Location</Label>
          <Input type="text" name="location" id="location" value={event.location} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="date">Date</Label>
          <Input type="date" name="date" id="date" value={event.date} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <CKEditor
            
          
            editor={ClassicEditor}
            data="<p> Write the details of the event</p>"
            onReady={editor => {
              //console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              if (editor) {
                const description = editor.getData();
                setEvent({ ...event, description });
              }
            }}
            onBlur={(event, editor) => {
              //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //console.log('Focus.', editor);
            }}
            
            // config={{
            //   minHeight: 50,
            //   uploadAdapter: async (loader) => {
            //     const uploadUrl = '/upload'; // Your upload URL
            //     const response = await fetch(uploadUrl, {
            //       method: 'POST',
            //       body: loader.file,
            //     });
            //     const data = await response.json();
            //     loader.uploaded(data.url);
            //   },
            // }}
            
          />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image </Label>
          <Input type="file" name="image" id="image" onChange={handleChange} />
        </FormGroup>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        <Button type="submit" color="primary" disabled={loading}>
          {loading ? 'Creating...' : 'Create Event'}
        </Button>
      </Form>
    </div>
  );
};

export default EventCreate;