import React from 'react'

function InstructorTab() {
  return (
    <div>

      <div id="instructor" class="tab-pane ">
          <div class="course-instructor">
              <div class="row">
                  <div class="col-md-4">
                      <div class="profile-image">
                          <img src="assets/images/profile/instructor.jpeg" alt="profile"/>
                      </div>
                  </div>
                  <div class="col-md-8">
                      <div class="profile-info">
                          <h5><a href="profile.html">Maggie Strickland</a></h5>
                          <div class="author-career">Advanced Educator</div>
                          <p class="author-bio">Maggie is a brilliant educator, whose life was spent for computer science and love of nature. Being a female, she encountered a lot of obstacles and was forbidden to work in this field by her family. With a true spirit and talented gift, she was able to succeed and set an example for others.</p>


                          <ul class="author-social-networks">
                              <li class="item">
                                  <a href="#;" target="_blank" class="social-link"> <i class="fab fa-twitter social-link-icon"></i> </a>
                              </li>
                              <li class="item">
                                  <a href="#" target="_blank" class="social-link"> <i class="fab fa-facebook-f social-link-icon"></i> </a>
                              </li>
                              <li class="item">
                                  <a href="#;" target="_blank" class="social-link"> <i class="fab fa-instagram social-link-icon"></i> </a>
                              </li>
                              <li class="item">
                                  <a href="#" target="_blank" class="social-link"> <i class="fab fa-pinterest social-link-icon"></i> </a>
                              </li>
                              <li class="item">
                                  <a href="#" target="_blank" class="social-link"> <i class="fab fa-youtube social-link-icon"></i> </a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default InstructorTab