import React, { useState, useEffect } from "react";
import Header from "../layout/navbar/HeaderSection";
import FooterSection from "../layout/footer/FooterSection";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { StyledLink } from "../components/StyledComponents";
import { getPosts } from "../api/UserAPI";
import altImage from "../assets/img/brand/courses-image.jpg";

function BlogNews() {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;
  const navigate = useNavigate();

  //Django events endpoint fetch
  useEffect(() => {

    const fetchSelectOptions = async () => {
      try {
  
        const response = await getPosts();
        if (Array.isArray(response)) { // Check if response is an array
          setBlogs(response)
        } else {
          console.error("Response is not an array:", response);
        }

        
    } catch (error) {
      if (!blogs) return <div>Blog not found</div>;
    }
  };
  
  fetchSelectOptions();
    //setBlogs(blogsData);

   // fetch("http://13.58.79.27/api/posts/")
   // .then((response) => response.json())
   // .then((data) => setBlogs(data))
    //.catch((error) => console.error("Fetching blogs failed", error));
  }, []);

  // Calculate the current posts to display
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(blogs.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleBlogClick = (blogId) => {
    navigate(`/blog/${blogId}`);
  };

  const formatDate = (dateTimeString) => {
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      //hour: '2-digit',
      //minute: '2-digit',
      //second: '2-digit',
      //hour12: true
    };
  
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        <div class="page-title-section section mt-5" id="top">
          <div class="page-title">
            <div class="container">
              <h1 class="title">Blog & News</h1>
            </div>
          </div>
        </div>
      </div>

      <div>
      
        <div className="blog-section mb-5">
        
          <div className="container">
          <div className="col-sm-auto col-12 max-mb-10">
                <p className="result-count">
                  We found <span>{blogs.length}</span> blogs available for
                  you
                </p>
              </div>
              {Array.isArray(currentPosts) && currentPosts.length > 0 ? (

            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 mb-3">

              
              {currentPosts.map((blog) => (
                <div className="col" key={blog.id}>
                  <div className="blog">
                    <div className="thumbnail">
                      <a
                        href="#"
                        className="image"
                        key={blog.id}
                        onClick={() => handleBlogClick(blog.id)}
                      >
                        <img src={blog.image} alt="Blog Image" 
                        style={{
                          objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                          width: '100%', // Ensure the image fills the container's width
                          height: '200px', // Ensure the image fills the container's height
                        }}
                        onError={(e) => {
                          e.target.src = `${altImage}`;
                        }}
                         />
                      </a>
                    </div>
                    <div className="info">
                      <a href="#" className="category text-decoration-none">
                        {blog.category}
                      </a>
                      <a
                        className="text-decoration-none"
                        style={{ color: "#000" }}
                        key={blog.id}
                        onClick={() => handleBlogClick(blog.id)}
                      >
                        <h3 className="title">
                          <Link
                            className="text-decoration-none"
                            style={{
                              fontSize: "18px",
                              color: "#000",
                              fontWeight: "bold",
                              maxLines: "2",
                              overflow: "hidden",
                              display: "-webkit-box",
                              webkitBoxOrient: "vertical",
                              webkitLineClamp: "2",
                            }}
                          >
                            {blog.title}
                          </Link>
                        </h3>
                      </a>

                      <ul className="meta">
                        <li>
                          <i className="far fa-calendar"></i>
                          {formatDate(blog.date)}
                        </li>
                        <li>
                          <i className="far fa-eye"></i>
                          {blog.views} views
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))} 
           
            </div>
             ):(
              <div></div>
            )}
            <nav>
              <ul className="pagination justify-content-center">
                {pageNumbers.map((number) => (
                  <li key={number} className="page-item m-2 ">
                    <HashLink
                      onClick={() => paginate(number)}
                      to={"/blognews/#top"}
                      className="page-link"
                    >
                      {number}
                    </HashLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default BlogNews;
