import React from "react";
import { StyledLink } from "../../components/StyledComponents";
import Brochure from "../../assets/2024BROCHURE.pdf";

function FooterSection() {
  return (
    <div className="footer-section section">
      <div className="container">
        {/* Footer Top Widgets Start */}
        <div className="row">
          {/* Footer Widget Start */}
          <div className="col-xl-6 col-md-5 col-12 max-mb-50">
            <div className="footer-widget">
              <h4 className="footer-widget-title">Address</h4>
              <div className="footer-widget-content">
                <div className="content" style={{ color: "#6F6F6F" }}>
                  <p>Herbert Macaulay Way P.M.B, 190, Garki, Abuja</p>
                  <p>
                    (+234) 815-143-9793 <br /> (+234) 806-530-3556 <br />
                    (Monday – Friday : 09:00 – 17:00){" "}
                  </p>
                  <p>
                    <StyledLink to="mailto:nnpcacademy@nnpcgroup.com">
                      nnpcacademy@nnpcgroup.com
                    </StyledLink>
                  </p>
                </div>
                <div className="footer-social-inline">
                  <StyledLink href="">
                    <i className="fab fa-facebook-square"></i>
                  </StyledLink>
                  <StyledLink href="#">
                    <i className="fab fa-twitter"></i>
                  </StyledLink>
                  <StyledLink href="#">
                    <i className="fab fa-instagram"></i>
                  </StyledLink>
                  <StyledLink href="#">
                    <i className="fab fa-StyledLinkedin"></i>
                  </StyledLink>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Widget End */}

          {/* Footer Widget Start */}
          <div className="col-xl-3 col-md-4 col-sm-7 col-12 max-mb-50">
            <div className="footer-widget">
              <h4 className="footer-widget-title">Explore</h4>
              <div className="footer-widget-content">
                <ul className="column-1">
                  {/* <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      to="/about-us"
                    >
                      About us
                    </StyledLink>
                  </li> */}
                  <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      to="/courses"
                    >
                      Courses
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      to="/blognews"
                    >
                      Blog
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      to="/event"
                    >
                      Events
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      to="/stories"
                    >
                      Success story
                    </StyledLink>
                  </li>
                  {/* <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      to="/contact-us"
                    >
                      Contact us
                    </StyledLink>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {/* Footer Widget End */}

          {/* Footer Widget Start */}
          <div className="col-xl-3 col-md-3 col-sm-5 col-12 max-mb-50">
            <div className="footer-widget">
              <h4 className="footer-widget-title">Information</h4>
              <div className="footer-widget-content">
                <ul>
                  <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      to={Brochure}
                      target="_blank"
                    >
                      Brochure
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                     
                    >
                      Privacy policy
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      style={{ textDecoration: "none", color: "#6F6F6F" }}
                      
                    >
                      Terms of service
                    </StyledLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Footer Widget End */}
        </div>
        {/* Footer Top Widgets End */}

        {/* Footer Copyright Start */}
        <div className="row max-mt-20">
          <div className="col">
            <p className="copyright">
              &copy; 2024 NNPC Academy. All Rights Reserved
            </p>
          </div>
        </div>
        {/* Footer Copyright End */}
      </div>
    </div>
  );
}

export default FooterSection;
