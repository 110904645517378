import React, { useState } from "react";
import { Link } from "react-router-dom";

const MobileMenuModal = ({ isOpen, toggleModal }) => {
  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("modal-backdrop")) {
      toggleModal();
    }
  };

  return (
    <div>
      {isOpen && (
        <div
          className="modal show d-block "
          tabIndex="-1"
          role="dialog"
          style={{
            display: "flex",
            minHeight: "calc(80vh - 1rem)",
            backgroundColor: "rgba(0,0,0,0)",
          }}
        >
          <div
            className="modal-backdrop "
            role="document"
            style={{ display: "flex", minHeight: "calc(50vh - 1rem)" }}
            onClick={handleBackdropClick}
          >
            <div
              className="modal-content"
              style={{
                width: "100%",
                right: "0",
                top: "0",
                bottom: 0,
                margin: "auto",
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title "> </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body mb-3">
                <div className="mobile-menu-content">
                  <nav className="site-mobile-menu menu-hover-1">
                    <ul className="price">
                      <li className="has-children">
                        <Link to={"/about-us"} className="text-decoration-none">
                          <span className="text-primary">About us</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/stories"} className="text-decoration-none">
                          <span className="text-primary">Success Story</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/about-us"} className="text-decoration-none">
                          <span className="text-primary">Overview</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our-team"} className="text-decoration-none">
                          <span className="text-primary">Our team</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/courses"} className="text-decoration-none">
                          <span className="text-primary">Courses</span>
                        </Link>
                      </li>

                      <li>
                        <Link to={"/event"} className="text-decoration-none">
                          <span className="text-primary">Events</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/blognews"} className="text-decoration-none">
                          <span className="text-primary">Blogs & News</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={"/contact-us"}
                          className="text-decoration-none"
                        >
                          <span className="text-primary ">Contact us</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenuModal;
