import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { getAudiance, getCategory, getInstructors, postCourses } from '../../../../api/UserAPI';

const CourseCreateForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const categoriesRes = await getCategory();
        //console.log(categoriesRes)
        setCategories(categoriesRes);

        const audiencesRes = await getAudiance();
        setAudiences(audiencesRes);

       // const instructorsRes = await getInstructors();
        //setInstructors(instructorsRes);


       
      } catch (error) {
        console.error('Failed to fetch select options:', error);
        setError('Failed to fetch select options.');
      }
    };

    fetchSelectOptions();
  }, []);

  const handleChange = (e) => {
    const { name, type, checked, value, files, options } = e.target;
    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.files[0] 
      }));
    } else if (type === 'checkbox') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked, // Directly use the checked property for checkboxes
        }));}else if (type === 'select-multiple') {
            // Collect all selected options for a multi-select
            const selectedOptions = Array.from(options)
                                          .filter(option => option.selected)
                                          .map(option => option.value);
            setFormData((prevData) => ({
              ...prevData,
              [name]: selectedOptions,
            }));}
         else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    try {
      const response = await postCourses(formDataToSend);

      if (!response.error) {
        alert('course added successful')
        navigate('/admin/courses');

      }else{
        //navigate('/successPath'); // Adjust navigation path as needed
      }

     
    } catch (error) {
      console.error('Submission error:', error);
      setError('Submission failed.');
    } finally {
      setLoading(false);
    }
  };

  const fields = [
    { name: 'code', type: 'text', label: 'Course Code' },
    { name: 'title', type: 'text', label: 'Course Title' },
    { name: 'description', type: 'text', label: 'Description' },
    { name: 'category', type: 'select', label: 'Category', options: categories.map(cat => ({ value: cat.id, label: cat.name })) },
    { name: 'target_audience', type: 'select', label: 'Target Audience', options: audiences.map(aud => ({ value: aud.id, label: aud.name })) },
    { name: 'price', type: 'text', label: 'Price' },
    { name: 'duration', type: 'text', label: 'Duration' },
    { name: 'is_featured', type: 'checkbox', label: 'Is Featured' },
    { name: 'imageUrl', type: 'file', label: 'Image ' },
    { name: 'venue', type: 'text', label: 'Venue' },
    { name: 'date', type: 'date', label: 'Date' },    
    { name: 'more_details', type: 'text', label: 'More Details' },


    // Add other fields as necessary
  ];

  
  return (
    <div>
        <div>
        <h2>Add New Course</h2>
        </div>

      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        {fields.map((field) =>
          field.type === 'select' ? (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="select"
                name={field.name}
                id={field.name}
                value={formData[field.name] || ''}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ) : field.type === 'file' ? (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type="file"
                name={field.name}
                id={field.name}
                onChange={handleChange}
              />
            </FormGroup>
          ) : (
            <FormGroup key={field.name}>
              <Label for={field.name}>{field.label}</Label>
              <Input
                type={field.type}
                name={field.name}
                id={field.name}
                value={field.type === 'checkbox' ? undefined : formData[field.name] || ''}
                checked={formData[field.name] || false}                
                 onChange={handleChange}
              />
            </FormGroup>
          )
        )}
        {error && <div className="alert alert-danger">{error}</div>}
        <Button type="submit" color="primary" disabled={loading}>
          {loading ? 'Adding...' : 'Add New Course'}
        </Button>
      </Form>
    </div>
  );
};

export default CourseCreateForm;
