import React, {useState, useEffect} from 'react';
import RequireAuth from '../../layout/Auth.js';
import Index from './pages/Index';
import { getCourses, logout } from '../../api/UserAPI';
import Team from './pages/Team';
import Contact from './pages/Contact';
import Blogs from './pages/Blog';
import TeamEditForm from './pages/modals/EditTeam';
import CourseCreateForm from './pages/createpage/CreateCourse';
import CreateBlog from './pages/createpage/BlogCreate';
import EditPostComponent from './pages/createpage/EditBlog';
import EventCreate from './pages/createpage/EventCreate';
import EditEvent from './pages/createpage/EventEdit';
import UpdateCourse from './pages/createpage/UpdateCourses';
import EnrollForm from './pages/EnrolForm';
import { Link, Routes, Route } from 'react-router-dom';
import Courses from './pages/Courses';
import Events from './pages/Events.js';
import UserManagement from './pages/usermanagemet.js';
import Background from './pages/Background.js'
import { NavLink } from 'react-router-dom';
import AdminHeader from './Navbar/AdminNavbar.js';
import logo from '../../assets/img/brand/logo.png';
import profile from '../../assets/img/team/profile.jpeg';
import { useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SettingFilled,
  BellFilled,
  ExclamationCircleOutlined,
  GlobalOutlined,
  MenuOutlined,
  ReadOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
  FileAddOutlined,
  PhoneOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme, Image, Dropdown } from 'antd';
import UpdateUser from './pages/UpdateUser.js';


const { Header, Content, Footer, Sider } = Layout;


const AdminDashboard = () => {
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState('');

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (path) => {
    navigate(path);
  };
  
  useEffect(()=>{
    let rfff = localStorage.getItem('userData');
    const data = rfff; // the variable that holds your JSON string

    if (data) {
        try {
            const jsonData = JSON.parse(data);
            // Continue with your logic here
        } catch (error) {
            console.error("Failed to parse JSON:", error);
        }
    } else {
        console.error("Data is undefined or null");
    }
    
    setUserData("Admin")
    
      // setUserData(user)

  }, [])

// Parse the string into an object


const handleLogout = async () => {
  // Call the logout function from the API
  await logout();

  // Clear localStorage or your method of persistence
  localStorage.removeItem('userToken');
  localStorage.removeItem('refresh');
  localStorage.removeItem('userData');

  // Navigate to the home or login page
  navigate('/');
};

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<SettingFilled />} onClick={() => handleLogout()} >
        Sign out
      </Menu.Item>
      
    </Menu>
  );

  return (
    <Layout style={{
      minHeight: '100vh',
    }}>
      <Sider
      style={{
        background: colorBgContainer,
      }}
      width={250}
      collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
      >
        
        <div className="demo-logo-vertical" />
        <Header
          style={{
            display: 'flex',
            justifyContent: 'center', // Align items horizontally in the center
            alignItems: 'center', // Align items vertically in the center
            height:150,
          }}
        >
            <div className=''>
              <div className=''>
                <Image
                      src={profile}
                      style={{
                        borderRadius: 25, // Creates rounded corners
                        width: 50, // Set the width of the image
                        
                      }}
                      
                    />
              </div>
              <div className='' style={{color:'white'}}>
                <h6>{userData}</h6>
                <h6>{}</h6>

              </div>
                
              </div> 
          

          
        </Header>
        <Menu
      theme="light"
      mode="inline"
    >
      <Menu.Item key="1" icon={<MenuOutlined />} onClick={() => handleMenuClick('/admin')}>
        Dashboard
      </Menu.Item>
      <Menu.Item key="2" icon={<ReadOutlined />} onClick={() => handleMenuClick('courses')}>
        Courses
      </Menu.Item>
      <Menu.Item key="3" icon={<CalendarOutlined />} onClick={() => handleMenuClick('events')}>
        Events
      </Menu.Item>
      <Menu.Item key="4" icon={<FileAddOutlined />} onClick={() => handleMenuClick('blogs')}>
        Blogs
      </Menu.Item>
      <Menu.Item key="5" icon={<UsergroupAddOutlined />} onClick={() => handleMenuClick('teams')}>
        Teams
      </Menu.Item>
      <Menu.Item key="6" icon={<FileProtectOutlined />} onClick={() => handleMenuClick('enroll')}>
        Enrollments
      </Menu.Item>
      <Menu.Item key="7" icon={<PhoneOutlined />} onClick={() => handleMenuClick('contacts')}>
        Contacts
      </Menu.Item>
      <Menu.Item key="8"  onClick={() => handleMenuClick('background')}>
        Background Banner
      </Menu.Item>
      <Menu.Item key="9" icon={<UserOutlined />} onClick={() => handleMenuClick('user-manage')}>
        Users management
      </Menu.Item>

    </Menu>
        
      </Sider>
      <Layout style={{
    }}>
        <Header
          style={{
            padding: 0,
            height: 90,
            background:'none',
          }}
        >
          <div className=' row'> 
            <div className=' col-6'>
              <div className='container'>
              <Image
                  src={logo}
                />
              </div> 
            
            </div>
            <div className='col-4' > 
            <Menu mode='horizontal' theme='none'
              style={{
                display: 'flex',
                justifyContent: 'end', // Align items horizontally in the center
                alignItems: 'center', // Align items vertically in the center
              }}
             items={[
              {
                label: new Date().toDateString()
                }, 
              // {
              //   key: '1',
              //   icon: <ExclamationCircleOutlined />,
              //   label: 'help'
              // }, 
              {
                key: '2',
                icon: <GlobalOutlined />,
                label: 'visit site',
                onClick: () => handleMenuClick('/')
              },
            ]}
            />
            </div>
            
            <div className='col-2'>
              <Menu
                theme="dark"
                mode="horizontal"
                style={{
                  display: 'flex',
                  justifyContent: 'center', // Align items horizontally in the center
                  alignItems: 'center', // Align items vertically in the center
                  borderBottomLeftRadius: 25,
                }}
              >
                {/* <Menu.Item key="1" icon={<SettingFilled />} >
                
                
                </Menu.Item> */}
                <Dropdown
                    overlay={menu}
                    onVisibleChange={handleVisibleChange}
                    visible={visible}
                  >
                    <Menu.Item key="1" icon={<SettingFilled />} onClick={() => setVisible(!visible)}>
                      
                    </Menu.Item>
                </Dropdown>
                <Menu.Item key="2" icon={<BellFilled />}></Menu.Item>
              </Menu>
            </div> 



          </div>


        </Header>
        <Content
          style={{
            margin: '0 15px',
            padding: 10,
            background: 'none',
            //borderRadius: borderRadiusLG,

          }}
        >
          <div
            style={{
              padding: 10,
              background: 'none',
              borderRadius: borderRadiusLG,
            }}
          >
          <Routes>

           <Route path="/" element={<RequireAuth><Index/></RequireAuth> } />
           <Route path="courses" element={<RequireAuth><Courses /></RequireAuth> } />
           <Route path="courses/add-course" element={<RequireAuth>< CourseCreateForm/></RequireAuth> } />
           <Route path="courses/edit-course/:courseId" element={<RequireAuth>< UpdateCourse/></RequireAuth> } />
           <Route path="teams" element={<RequireAuth><Team /></RequireAuth> } />
           <Route path="teams/team-edit/:teamId" element={<RequireAuth><TeamEditForm /></RequireAuth> } />
           <Route path="events" element={<RequireAuth><Events /></RequireAuth> } />
           <Route path="events/add-event" element={<RequireAuth><EventCreate /></RequireAuth> } />
           <Route path="events/edit-event/:eventId" element={<RequireAuth><EditEvent /></RequireAuth> } />
           <Route path="contacts" element={<RequireAuth><Contact /></RequireAuth> } />
           <Route path="blogs" element={<RequireAuth><Blogs /></RequireAuth> } />
           <Route path="enroll" element={<RequireAuth><EnrollForm /></RequireAuth> } />
           <Route path="blogs/add-blog" element={<RequireAuth><CreateBlog /></RequireAuth> } />
           <Route path="background" element={<RequireAuth><Background /></RequireAuth> } />
           <Route path="blogs/edit-blog/:blogId" element={<RequireAuth><EditPostComponent /></RequireAuth> } />
           <Route path="user-manage" element={<RequireAuth><UserManagement /></RequireAuth> } />
           <Route path="user-manage/user-edit/:id" element={<RequireAuth><UpdateUser /></RequireAuth> } />

         </Routes>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          NNPC Academy ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default AdminDashboard;


// import React, {useEffect, useState} from 'react';
// import { Link, Routes, Route } from 'react-router-dom';
// import Courses from './pages/Courses';
// import Events from './pages/Events.js';
// import { NavLink } from 'react-router-dom';
// import AdminHeader from './Navbar/AdminNavbar.js';
// import SideBar from './Navbar/SideBar.js';
// import RequireAuth from '../../layout/Auth.js';
// import Index from './pages/Index';
// import { getCourses } from '../../api/UserAPI';
// import Team from './pages/Team';
// import Contact from './pages/Contact';
// import Blogs from './pages/Blog';
// import TeamEditForm from './pages/modals/EditTeam';
// import CourseCreateForm from './pages/createpage/CreateCourse';
// import CreateBlog from './pages/createpage/BlogCreate';
// import EditPostComponent from './pages/createpage/EditBlog';
// import EventCreate from './pages/createpage/EventCreate';
// import EditEvent from './pages/createpage/EventEdit';
// import UpdateCourse from './pages/createpage/UpdateCourses';
// import EnrollForm from './pages/EnrolForm';

// function AdminDashboard() {

//   return (
//     <div className="admin-container">
//       <nav className="navbar ">

//         <AdminHeader/>
        
//       </nav>
//       <div className="content-with-sidebar mt-5 pt-5">
//         <aside className="sidebar">

//           <div>
//             <nav>
//               <SideBar/>
//           </nav>
//           </div>
  
//         </aside>
//         <div className="main-content">
//         <Routes>

//           <Route path="/" element={<RequireAuth><Index/></RequireAuth> } />
//           <Route path="courses" element={<RequireAuth><Courses /></RequireAuth> } />
//           <Route path="courses/add-course" element={<RequireAuth>< CourseCreateForm/></RequireAuth> } />
//           <Route path="courses/edit-course/:courseId" element={<RequireAuth>< UpdateCourse/></RequireAuth> } />
//           <Route path="teams" element={<RequireAuth><Team /></RequireAuth> } />
//           <Route path="teams/team-edit/:teamId" element={<RequireAuth><TeamEditForm /></RequireAuth> } />
//           <Route path="events" element={<RequireAuth><Events /></RequireAuth> } />
//           <Route path="events/add-event" element={<RequireAuth><EventCreate /></RequireAuth> } />
//           <Route path="events/edit-event/:eventId" element={<RequireAuth><EditEvent /></RequireAuth> } />
//           <Route path="contacts" element={<RequireAuth><Contact /></RequireAuth> } />
//           <Route path="blogs" element={<RequireAuth><Blogs /></RequireAuth> } />
//           <Route path="enroll" element={<RequireAuth><EnrollForm /></RequireAuth> } />
//           <Route path="blogs/add-blog" element={<RequireAuth><CreateBlog /></RequireAuth> } />
//           <Route path="blogs/edit-blog/:blogId" element={<RequireAuth><EditPostComponent /></RequireAuth> } />

//         </Routes>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default AdminDashboard;
