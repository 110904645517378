import React, {useState} from 'react'
import NavbarHome from '../layout/navbar/Navbar';
import BeforeFooter from '../layout/footer/BeforeFooter';
import Footer from '../layout/footer/Footer';
import AfterHeader from '../layout/bgheader/AfterHeader';
import { PostContactUs } from "../api/UserAPI";

function CustomizeCourse() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const formPayload = {
      name: formData.fullname,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };



    try {
      const response = await PostContactUs(formPayload);
      setMessage("Your request is successfully sent");

      // Handle success, such as resetting form, displaying a message, etc.
      setFormData({
        fullname: "",
        email: "",
        subject: "",
        message: "",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "Something went wrong.");
      setLoading(false);
    }
  };

    
  return (
    <div>
        <div>
            <NavbarHome/>

        </div>
        <div className='row m-5'>

        </div>
        
        
        <hr/>

        <div id='top'>
        <div className='container row m-5 justify-content-center align-items-center '>
            <div className='container row align-items-center ' >
            <div class=" ">
              <div className=' align-items-center text-center'>
                <h4 className='mb-3' >Request for your Customized Course.  </h4>
                <p>We are proud to offer a variety of training formats to suit your needs, including public training courses,<br/> 
                    in-house training solutions, and online training programs.
                    </p>
              </div>
              
            </div>

            </div>
            <div class="col-md-9 ">
              <div className='mt-5'>

              <form onSubmit={handleSubmit} method="post">
                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="col-6">
                          <input
                            className="form-control "
                            style={{ borderColor: "#28a745", height: "50px" }}
                            type="text"
                            name="fullname"
                            placeholder="Fullname *"
                            value={formData.fullname}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control  "
                            style={{ borderColor: "#28a745", height: "50px" }}
                            type="email"
                            name="email"
                            placeholder="Email *"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="col-12">
                          <input
                            className="form-control"
                            style={{ borderColor: "#28a745", height: "50px" }}
                            type="text"
                            name="subject"
                            placeholder="Requesting a customized course(s) for my company/deapartment *"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="col-12">
                          <textarea
                            className="form-control "
                            style={{ borderColor: "#28a745", height: "150px" }}
                            name="message"
                            placeholder="Explain the customization details such as date, location, etc *"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="container row mb-5 justify-content-center align-items-center ">
                        <div className="d-grid col-12 mx-autom-2">
                          {error && (
                            <div className="alert alert-danger" role="alert">
                              {error}
                            </div>
                          )}
                          {message && (
                            <div className="alert alert-success" role="alert">
                              {message}
                            </div>
                          )}
                        </div>
                        <div className="d-grid col-4 mx-auto">
                          <button
                            type="submit"
                            className="btn btn-success btn-lg"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-lg"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
              

              </div>
            </div>
          </div>

        </div>

        
        
        <div>
            <Footer/>
        </div>
        

    </div>
  )
}

export default CustomizeCourse