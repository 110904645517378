import React, { useState, useMemo } from "react";
import { Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarHome from "../layout/navbar/Navbar";
import AfterHeader from "../layout/bgheader/AfterHeader";
import BeforeFooter from "../layout/footer/BeforeFooter";
import Footer from "../layout/footer/Footer";
import {
  FaCalendar,
  FaCalendarAlt,
  FaClipboardList,
  FaDownload,
  FaFileDownload,
  FaList,
  FaListAlt,
  FaListUl,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaSort,
  FaSortAlphaDown,
  FaSortAmountDownAlt,
  FaWindows,
} from "react-icons/fa";
import CoursesList from "./CourseList";
import FooterSection from "../layout/footer/FooterSection";
import BFooter from "../layout/footer/BFooter";
import CourseMode from "../layout/bgheader/CourseMode";
import Header from "../layout/navbar/HeaderSection";
import Banner from "../assets/img/banner/couses.jpg";
import { Link } from "react-router-dom";

function Courses() {
  const [viewMode, setViewMode] = useState("grid"); // Default to grid view

  const toggleViewMode = () => {
    setViewMode(viewMode === "grid" ? "list" : "grid");
  };

  const [filter, setFilter] = useState({ venue: "", category: "", price: 0 });
  const [filteredCourses, setFilteredCourses] = useState();

  // Example venues and categories for select options
  const venues = [
    "New York",
    "Boston",
    "Chicago",
    "San Francisco",
    "Los Angeles",
  ];
  const categories = ["Technology", "Business", "Health", "Art"];

  // useMemo to calculate the unique venues and categories if they are dynamic
  // const venues = useMemo(() => [...new Set(courses.map(course => course.venue))], [courses]);
  // const categories = useMemo(() => [...new Set(courses.map(course => course.category))], [courses]);

  {
    /* const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter(prev => ({
            ...prev,
            [name]: value
        }));

        const newFilter = { ...filter, [name]: value };
        filterCourses(newFilter);
    };

   const filterCourses = (filterCriteria) => {
        let updatedCourses = courses.filter(course =>
            (filterCriteria.venue ? course.venue === filterCriteria.venue : true) &&
            (filterCriteria.category ? course.category === filterCriteria.category : true) &&
            (filterCriteria.price ? course.price <= filterCriteria.price : true)
        );
        setFilteredCourses(updatedCourses);
    }; */
  }

  return (
    <div>
      <div className="">
        <Header />
      </div>

      <div className="pt-5 ">
        <header className="" style={{ paddingLeft: 0 }}>
          <div
            className="pt-5 bg-image"
            style={{
              backgroundColor: "green",
              height: 500,
              background: `url(${Banner}) top / cover no-repeat green`,
              backgroundSize: "cover",
              boxShadow: `inset 0 0 0 2000px rgba(0, 0, 0, 0.7)`,
            }}
          >
            <div className="d-flex justify-content-center align-items-center m-5">
              <div className="text-white text-center m-5 pt-5">
                <h1 className="mb-3">Courses </h1>
                <p className="mb-3" style={{ fontSize: "18px" }}>
                  Grow strong to take up the challenges of life. The top lessons
                  for getting you back on track.
                </p>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Page Title Section Start  */}
      <div
        class="page-breadcrumb-no-title-section section mt-3"
        style={{ paddingTop: "1px" }}
      >
        <div class="page-breadcrumb">
          <div class="container">
            <ul class="breadcrumb">
              <li>
                <a href="nnpcacademy">Home</a>
              </li>
              <li class="current">Courses</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Page Title Section End */}

      <div id="courses">
        <CoursesList />
      </div>

      <hr />

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default Courses;
