import React, { useState, useEffect } from 'react'
import { delEvents, getEvents, postEvents } from '../../../api/UserAPI'
import { useFetchData } from '../hooks/useFetchData';
import { GenericDataManagementComponent } from '../hooks/GenericBlocs';
import { useNavigate } from 'react-router-dom';
import { Drawer, theme, Modal, Space } from 'antd';
import {   FormGroup, Label,Input,Form, Button } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import altImage from "../../../assets/img/brand/courses-image.jpg";
import {  StyledSelect, } from "../../../components/StyledComponents";



function Events() {
  //const { data: eventList, isLoading, notFound } = useFetchData(getEvents);
  const navigate = useNavigate();
  // const eventAttributes = [
  //   { label: "Event title", value: "title" },
  //   { label: "Location", value: "location" },
  //   { label: "Description", value: "description" },
  //   { label: "Image", value: "image" },    
  //   { label: "Date", value: "date" },
  // ];
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const eventsPerPage = 12;
  const [event, setEvent] = useState({
    title: '',
    location: '',
    description: '',
    date: '',
    image: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const uniqueTags = [...new Set(events.map((event) => event.location))];

  const [selectedSortOrder, setSelectedSortOrder] = useState('asc'); // Default to ascending order

  const filteredEvent = events.filter((event) => {
    if (selectedTag === '') {
      return true; // Show all blogs if no tag is selected
    }
    return event.location === selectedTag; // Filter blogs by selected tag
  });
  const [selectedItem, setSelectedItem] = useState(null);

const handleTagChange = (e) => {
  setSelectedTag(e.target.value);
};


  const handleSortChange = (e) => {
    setSelectedSortOrder(e.target.value);
  };

  const sortedEvents = events.sort((a, b) => {
    if (selectedSortOrder === 'asc') {
      return new Date(a.date) - new Date(b.date); // Sort in ascending order
    } else {
      return new Date(b.date) - new Date(a.date); // Sort in descending order
    }
  });


  // const handleCKEditorChange = (name, editor) => {
  //   const value = editor.getData();
  //   setEvent ({
  //     ...event,
  //     [name]: value,
  //   });
  // };

  const handleCKEditorChange = (name, editor) => {
    const value = editor.getData();
    setEvent({ ...event, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
      if (name === "image") {const file = e.target.files[0];
      
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
      if (!validImageTypes.includes(file.type)) {
          alert("Please upload a valid image file (JPEG, PNG, GIF).");
          e.target.value = ""; // Clear the input
          return;
      };
    
      if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
          alert("Image size is too heavy. Please upload an image less than 2MB.");
          e.target.value = ""; // Clear the input
      }else{
        
        setEvent({ ...event, [name]: e.target.files[0] });
      }


    } else {
      setEvent({ ...event, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('title', event.title);
    formData.append('description', event.description);
    formData.append('location', event.location);
    formData.append('date', event.date)
    if (event.image) {
      formData.append('image', event.image);
    }
    try {
      const event = await postEvents(formData)
      console.log(event)
      if (!event.error){
        alert('The Event is created successfully')
        //navigate('/admin/events');
        window.location.reload();

      }
      setLoading(false);
      // Reset form or redirect user
      setEvent({ title: '', description: '', location: '', date: '', image: null });
      // Optionally, redirect the user or give feedback
    } catch (error) {
      console.error('Error creating event:', error);
      setError('Failed to create event. Please try again.',error);
      setLoading(false);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  useEffect(()=>{

    const fetchEvent = async () => {

      try{

        const response = await getEvents()
        setEvents(response);

      } catch{

      }

    }
  fetchEvent();

  }, [])


  const totalPages = Math.ceil(filteredEvent.length / eventsPerPage);
  const showModal = (id) => {
    setSelectedItem(id)
    setOpenModal(true);
  };
  const hideModal = () => {
    setSelectedItem(null)
    setOpenModal(false);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentEvents = filteredEvent.slice(
    (currentPage - 1) * eventsPerPage,
    currentPage * eventsPerPage
  );

  const handleDelete = async (eventId) => {
    //setIsLoading(true);
  //   const isConfirmed = window.confirm("Are you sure you want to delete this event?");
  
  // // If the user confirmed, call the delete function
  // if (isConfirmed) 
  
    try {
        // Pass the `teamId` to `delTeam`
        const response = await delEvents(eventId);
        if (!response) {
           // setNoTeamFound(true);
        } else {
          setMessage("event  deleted successfully");
      
        }
    } catch (error) {
        console.error("Could not delete event.", error);
    } finally {
        //setIsLoading(falsblogPerPagee);
    }

    window.location.reload();
  // }

  }
  const handleEdit = async (eventId) => {
    navigate(`edit-event/${eventId}`)
  };


  // const eventActions = [
  //   { label: "Edit", buttonClass: "btn-info", handler: (item) =>  handleEdit(item.id) },
  //   { label: "Delete", buttonClass: "btn-danger", handler: (item) => handleDelete(item.id) },
  // ];

  const handleCreate = () =>{
    navigate(`add-event`);

  }


  return (
    <div> 
      <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Events</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          <button  style={{color:'white', background:'green', padding:10, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon> New Event</button>
          </div>
        </div>
      </div>


      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>
        <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <p style={{ fontWeight: 600, color:'#1A2F3E'}}>We found <span>{filteredEvent.length} </span> Events available for you</p>
          </div>
        </div>

        <div className="col-6 text-end d-flex justify-content-between "> 
        
            <StyledSelect className="m-1" value={selectedSortOrder} onChange={handleSortChange}>
              <option value="asc">Sort by Date  (Ascending)</option>
              <option value="desc">Sort by Date (Descending)</option>
            </StyledSelect>
            <StyledSelect className="m-1" value={selectedTag} onChange={handleTagChange}>
              <option value="">All location</option>
              {uniqueTags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </StyledSelect>
        </div>
      </div>

      <div className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2  row-cols-1 max-mb-n30">
              {currentEvents.map((event) => (
                <div
                  className="col max-mb-30"
                  data-aos="fade-up"
                >
                  <div className="event">
                    <div className="thumbnail">
                      <a
                        className="image"
                        // onClick={() => handleEventClick(event.id)}
                      >
                        <img
                          src={event.image}
                          alt="Event Image"
                          style={{
                            objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                            width: '100%', // Ensure the image fills the container's width
                            height: '200px', // Ensure the image fills the container's height
                          }}
                          onError={(e) => {
                            e.target.src = `${altImage}`;
                          }}
                          height={"200px"}                       
                           />
                        <div className="event-overlay-background"></div>
                        <div className="event-overlay-content">
                          
                        </div>
                      </a>
                    </div>
                    <div className="info">
                      <span className="date">{event.date}</span>
                      <a
                        className="text-decoration-none"
                        key={event.id}
                        // onClick={() => handleEventClick(event.id)}
                      >
                        <h3 className="title">
                          <div
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              maxLines: "2",
                              overflow: "hidden",
                              display: "-webkit-box",
                              webkitBoxOrient: "vertical",
                              webkitLineClamp: "2",
                            }}
                          >
                            {event.title}
                          </div>
                        </h3>
                      </a>
                      <ul className="event-location">
                        <li>
                          <i className="far fa-map-marker-alt"></i>
                          {event.location} 
                        </li>
                      </ul>
                    </div>

                    <div className='team-info text-center'>
                      
                       
                      <Button color="success" onClick={()=> handleEdit(event.id)}>Update  {" "}       
                    </Button>
                        
                                      
                    <button className="btn btn-danger m-1" onClick={ () => showModal(event.id)}>Delete 
                    
                    </button>
                    <Modal
                          title={<div style={{ textAlign: 'center' }}>Deletion confirmation</div>}
                          open={openModal}
                          onCancel={hideModal}
                          footer={null}
                        >
                          <p style={{ textAlign: 'center' }}>Are you sure you want to delete this event?</p>
                          <div style={{ textAlign: 'center' }}> 
                          <Button className='btn btn' onClick={hideModal}>No</Button>                          
                          <Button className="btn btn-danger m-1" onClick={() => handleDelete(selectedItem)}>Yes {selectedItem}</Button>
                          </div>

                        </Modal>
                        
                        
                      </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='mt-3' style={{textAlign:'center'}}>
            <ul className="pagination " style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              {Array(totalPages)
                .fill(0)
                .map((_, index) => (
                  <li key={index}>
                    <a
                      href="#"
                      onClick={() => handlePageChange(index + 1)}
                      className={index + 1 === currentPage ? 'active' : ''}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
          </ul>

      </div>




    </div>
    <div>
    <Drawer
        title="Add a new event"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            
          </Space>
        }
      >
        <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" name="title" id="title" value={event.title} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="location">Location</Label>
          <Input type="text" name="location" id="location" value={event.location} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="date">Date</Label>
          <Input type="date" name="date" id="date" value={event.date} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <CKEditor
            editor={ClassicEditor}
            data={event.description || ''}
            onChange={(event, editor) => handleCKEditorChange('description', editor)}
            onBlur={(event, editor) => {
              //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //console.log('Focus.', editor);
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image </Label>
          <Input type="file" name="image" id="image" onChange={handleChange} />
        </FormGroup>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        <Button type="submit" color="primary" disabled={loading}>
          {loading ? 'Creating...' : 'Create Event'}
        </Button>
      </Form>
      </Drawer>
    </div>


      {/* <div className='mb-2' >
      <button className="btn btn-primary  m-1" onClick={() => handleCreate()}>Add new Event</button>

      </div> */}

      {/* <div>

      <GenericDataManagementComponent
        title="Events"
        isLoading={isLoading}
        notFound={notFound}
        data={eventList}
        dataAttributes={eventAttributes}
        actions={eventActions}
        />

      </div> */}
    </div>
    )
}

export default Events