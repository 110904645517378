import React, { useState, useEffect } from 'react';
import axios, { formToJSON } from 'axios';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import {  UpdateEvent, getEvents1,  } from '../../../../api/UserAPI';
import { theme,   } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const EditEvent = () => {
    
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const getId = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState({
    // title: '',
    // location: '',
    // description: '',
    // date: '',
    // image: null,
  });
  const Id = (getId.eventId)
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  useEffect(() => {
    const fetchEventData = async () => {
        try {
          const response = await getEvents1(Id);
          setEvent(response);
        } catch (error) {
          setError(error)
        }
      };
  
      fetchEventData();
    }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setEvent({ ...event, [name]: e.target.files[0] });
    } else {
      setEvent({ ...event, [name]: value });
    }
  };
  const handleCKEditorChange = (name, editor) => {
    const value = editor.getData();
    setEvent({ ...event, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    // const formData = new FormData();
    // Object.entries(event).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });


    const formData = new FormData();
    formData.append('title', event.title);
    formData.append('location', event.location);
    formData.append('date', event.date);
    formData.append('description', event.description);

    if (event.image) {
      formData.append('image', event.image);
    }else{
      formData.append('image', event.image);

    }
    try {
      // const response = await axios.put(`http://13.58.79.27/api/events/${Id}/`, formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      const response = await UpdateEvent(Id, formData);
      if (response.error){

      }else{
        setMessage("Event  updated successfully");
        setLoading(false);
        navigate('/admin/events');

      }
     
      // Here you might want to navigate back or to a specific page
     
    } catch (error) {
      console.error('Error updating event:', error);
      setError("Select an image");
      setLoading(false);
    }
  };

  return (

    <div>

    <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Update Event</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          </div>
        </div>
      </div>

      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}>
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="title">Title</Label>
        <Input type="text" name="title" id="title" value={event.title} onChange={handleChange} required />
      </FormGroup>
      <FormGroup>
        <Label for="location">Location</Label>
        <Input type="text" name="location" id="location" value={event.location} onChange={handleChange} required />
      </FormGroup>
      <FormGroup>
      <Label for="date">Date</Label>
      <Input type="date" name="date" id="date"  value={event.date} onChange={handleChange} required />
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <CKEditor
            editor={ClassicEditor}
            data={event.description || ''}
            onChange={(event, editor) => handleCKEditorChange('description', editor)}
            onBlur={(event, editor) => {
              //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //console.log('Focus.', editor);
            }}
          />
        {/* <Input type="textarea" name="description" id="description" value={event.description} onChange={handleChange} style={{ minHeight: '250px' }} required /> */}
      </FormGroup>
      <FormGroup>
        <Label for="image">Image </Label>
        <Input type="file" name="image" id="image"  onChange={handleChange} required />
      </FormGroup>
      {message && <div className="alert alert-success" role="alert">{message}</div>}

      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      <Button type="submit" color="primary" disabled={loading}>
        {loading ? 'Updating...' : 'Update Event'}
      </Button>
    </Form>
  </div>
  </div>
  );
};

export default EditEvent;
