// LoginModal.js

import React, { useState } from 'react';
import axios from 'axios';
import { login, logout } from '../../api/UserAPI';
import LoginModal from './Login';

const AuthComponent = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('');
    
    const [isModalOpen, setIsModalOpen] = useState(true);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    
  
    const handleLogin = async () => {
      const credentials = { username: 'user', password: 'pass' }; // Get these from your form or login input fields
      const data = await login(credentials.username, credentials.password);
      if (data && data.token) {
        setIsLoggedIn(true);
        setToken(data.token);
        // Store the token in localStorage or another method of persistence if needed
      }
    };
  
    const handleLogout = async () => {
      await logout(); // If your logout doesn't require a token sent to the backend, you can simply remove the token from local storage and update state
      setIsLoggedIn(false);
      setToken('');
      // Remove the token from localStorage or your method of persistence
    };
  
    return (
      <div>
        <LoginModal show={isModalOpen} handleClose={closeModal} />        
      </div>
    );
  };
  
  export default AuthComponent;