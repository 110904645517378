import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { postEnroll } from "../../api/UserAPI";

const CourseModal = ({ isOpen, toggle, title, course }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
  
    const formPayload = {
      user: formData.name,
      email: formData.email,
      number: formData.number,
      course: course,
      comment: formData.message,
    };
    console.log(formPayload);
  
    try {
      const response = await postEnroll(formPayload);
      console.log(response);
  
      if (!response.hasOwnProperty('error')) { // Check if response contains 'error' property
        setMessage("Your enrollment request for " + course +" was sent successfully, we will contact u shortly");
  
        // Handle success, such as resetting form, displaying a message, etc.
        setFormData({
          name: "",
          email: "",
          number: "",
          message: "",
        });
        setLoading(false);
      } else {
        setError(response.error);
        setLoading(false); // Ensure loading state is set to false in case of error
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "Something went wrong.");
      setLoading(false);
    }
  };
  

  return (
    <div className="mt-5" style={{ alignItems: "center", position: "center" }}>
      <Modal className="mt-5 pt-5" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title} for the Course</ModalHeader>
        <ModalBody>
          <div className="event-form">
            <form onSubmit={handleSubmit}>

              <div className="row">
              {message && (
                    <div className="alert alert-success" role="alert">
                      {message}
                    </div>
                  )}
                <div className="col-md-12 col-12 max-mb-30">
                  <input
                    type="text"
                    placeholder="Your Name *"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12 col-12 max-mb-30">
                  <input
                    type="text"
                    placeholder="number *"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                  />
                </div>
                
                <div className="col-md-12 col-12 max-mb-30">
                  <input
                    type="email"
                    placeholder="Email *"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 max-mb-30">
                  <textarea
                    name="message"
                    placeholder= 'comment'
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="d-grid col-12 mx-autom-2">
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  
                </div>

                <div className="col-12 text-center">
                  <button
                    className="btn btn-success btn-hover-secondary btn-width-180 btn-height-60"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-lg"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CourseModal;
