import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #6f6f6f;

  &:hover {
    color: #159351;
  }

  &:focus,
  &:hover,
  &:visited,
  &:styledlink,
  &:active {
    text-decoration: none;
    color: #159351;
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
  color: #000;

  &:hover {
    color: #159351;
  }
`;

// eslint-disable-next-line no-undef
export const Button = styled.button`
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
  background-color: #159351;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: ${(props) => (props.height ? props.height : "52px")};
  padding: ${(props) => (props.padding ? props.padding : "0 40px")};
  cursor: pointer;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 5px;
  outline: 0;

  &:hover {
    background-color: #212529;
  }

  ${(props) =>
    props.outline &&
    css`
      background: #fff;
      border: 1px solid #eee;
      color: #159351;
      min-width: ${(props) => (props.width ? props.width : "250px")};

      &:hover {
        background-color: #159351;
        color: #fff;
      }
    `}
`;

export const StyledSelect = styled.select`
  width: 270px;
  min-height: 52px;
  cursor: pointer;
  padding: 3px 40px 3px 20px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #6f6f6f;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  background-color: #f5f5f5;

  & form-select.form-select-open .form-select:hover .form-select:focus {
    border-color: #159351;
    background-color: #fff;
  }
`;

export const StyledDiv = styled.div`
  &:hover {
    transform: scale(1.1) !important;
    // width: 25%;
    font-size: 24px;
  }
`;
