import { useState, useEffect } from 'react';

export const useFetchData = (fetchFunction) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await fetchFunction();
                if (!response || response.length === 0) {
                    setNotFound(true);
                    setData([]);
                } else {
                    setData(response);
                    setNotFound(false);
                }
            } catch (error) {
                console.error("Could not fetch data", error);
                setNotFound(true); // Optionally handle errors more specifically
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [fetchFunction]); // Make sure to include fetchFunction in the dependency array

    return { data, isLoading, notFound };
};
