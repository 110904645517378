import React from "react";
import { Link } from "react-router-dom";

function CourseMode() {
  return (
    <div>
      <div className="section section-padding">
        <div className="container mt-3">
          {/* Section Title Start */}
          <div className="section-title text-center" data-aos="">
            <span className="sub-title">
              We offer a variety of training formats, including public, in-house
              and online training programs.
            </span>
            <h2 className="title fz-48">
              Explore our <span>Courses</span>
            </h2>
          </div>
          {/* Section Title End */}

          {/* Feature Wrapper Start */}
          <div className="row row-cols-xl-3 row-cols-sm-2 row-cols-1 max-mb-n30">
            {/* Feature Start (Icon Box) */}
            <div className="col max-mb-30" data-aos="">
              <Link
                to="/courses"
                className="icon-box h5-icon-box text-center text-decoration-none"
              >
                <div className="icon">
                  <img
                    src="https://i.ibb.co/dPYV8gD/home-5-image-box-01.png"
                    style={{ width: "auto", height: "auto" }}
                    alt="Course 1"
                  />
                </div>
                <div className="content">
                  <h3 className="title">Classroom Training</h3>
                  <div className="desc">
                    <p>
                      Learn practical skills from industry experts in our
                      instructor-led classroom courses. Find the perfect
                      training to advance your career.
                    </p>
                  </div>
                  <span class="link">
                    View programs <i class="far fa-long-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
            {/* Feature End (Icon Box) */}

            {/* Feature Start (Icon Box) */}
            <div className="col max-mb-30" data-aos="">
              <Link
                to="/customize"
                className="icon-box h5-icon-box text-center text-decoration-none"
              >
                <div className="icon">
                  <img
                    src="https://i.ibb.co/dbt9JXM/home-5-image-box-02.png"
                    style={{ width: "auto", height: "auto" }}
                    alt="Course 1"
                  />
                </div>
                <div className="content">
                  <h3 className="title">In-house Training</h3>
                  <div className="desc">
                    <p>
                      Up your team's skills with our customized in-house
                      training. We deliver expert-led programs to your workplace
                      for minimal disruption and max impact.
                    </p>
                  </div>
                  <span className="link">
                    Schedule trainings
                    <i className="far fa-long-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
            {/* Feature End (Icon Box) */}

            {/* Feature Start (Icon Box) */}
            <div className="col max-mb-30" data-aos="">
              <Link
                to="/courses"
                className="icon-box h5-icon-box text-center text-decoration-none "
              >
                <div className="icon">
                  <img
                    src="https://i.ibb.co/KF0hzLr/home-5-image-box-03.png"
                    style={{ width: "auto", height: "auto" }}
                    alt="Course 1"
                  />
                </div>
                <div className=" content ">
                  <h3 className=" title ">Online Courses</h3>
                  <div className=" desc">
                    <p>
                      Learn anytime, anywhere with our online training. Choose
                      from a vast library of self-paced courses and interactive
                      sessions led by industry experts.
                    </p>
                  </div>
                  <span className="link">
                    View courses <i className="far fa-long-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
            {/* Feature End (Icon Box) */}
          </div>
          {/* Feature Wrapper End */}
        </div>
      </div>
    </div>
  );
}

export default CourseMode;
