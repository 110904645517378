import React, { useState, useEffect } from "react";
import Header from "../layout/navbar/HeaderSection";
import { Link } from "react-router-dom";
import BFooter from "../layout/footer/BFooter";
import FooterSection from "../layout/footer/FooterSection";
import Banner from "../assets/img/banner/teams.jpg";
import Director from "../assets/img/team/director.jpeg";
import Profile from "../assets/img/team/profile.jpeg";
import { getTeam } from "../api/UserAPI";
import altImage from "../assets/img/team/profile.jpeg";


const TeamSection = () => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    //setTeams(teamMembers);
    const fetchTeam = async () => {
      // setIsLoading(true)
      try {
        const response = await getTeam();
        if (!response || response.length === 0) {
          //setNoTeamFound(true); //
          setTeams([]);
        } else {
          setTeams(response);
          // setNoTeamFound(false); //
        }
      } catch (error) {
        console.error("Could not fetch team members.", error);
      } finally {
      }
    };

    fetchTeam();
  }, []);

  // Define a custom sorting function
  const customSort = (a, b) => {
    // Define the order of positions
    const order = {
      "Director": 1,
      "Head Learning and Partnership": 2,
      "Head of Customer Experience": 3,
    };

    // Get the position order for each member
    const orderA = order[a.position];
    const orderB = order[b.position];

    // Compare the positions and sort accordingly
    return orderA - orderB;
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="mt-5">
        <header className="" style={{ paddingLeft: 0 }}>
          <div
            className="pt-5 bg-image"
            style={{
              backgroundColor: "green",
              height: 500,
              background: `url(${Banner}) top / cover no-repeat green`,
              backgroundSize: "cover",
              boxShadow: `inset 0 0 0 2000px rgba(0, 0, 0, 0.7)`,
            }}
          >
            <div className="d-flex justify-content-center align-items-center m-5">
              <div className="text-white text-center m-5 pt-5">
                <h1 className="mb-3">Our team </h1>
                <p className="mb-3" style={{ fontSize: "18px" }}>
                  Grow strong to take up the challenges of life. The top lessons
                  for getting you back on track.
                </p>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Page Title Section Start  */}
      <div class="page-breadcrumb-no-title-section section">
        <div class="page-breadcrumb">
          <div class="container">
            <ul class="breadcrumb mt-3">
              <li>
                <a href="nnpcacademy">Home</a>
              </li>
              <li class="current">About us</li>
              <li class="current">Our team</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Page Title Section End */}

      <div>
        <div className="team-section section section-padding-bottom">
          <div className="container">
            <div className="section-title text-center" data-aos="fade-up">
              <span className="sub-title">The guys behind the curtains</span>
              <h2 className="title fz-48">
                Whose <span>Inspirations</span> You Love
              </h2>
            </div>

            <div className="row ht-team-member-style-two">
              {Array.isArray(teams) && teams.length > 0 ? (
                teams.sort(customSort).map((member) => (
                  <div
                    key={member.id}
                    className="col-lg-4 col-md-6 col-sm-6 max-mb-50"
                  >
                    <div className="grid-item">
                      <div className="ht-team-member">
                        <div className=" text-center team-image">
                          <img
                            className=""
                            src={member.image}
                            alt={member.name}
                            style={{
                              objectFit: 'cover', // Ensure the image fills the container while maintaining aspect ratio
                              width: '100%', // Ensure the image fills the container's width
                              height: '400px', // Ensure the image fills the container's height
                            }}
                            onError={(e) => {
                                    e.target.src = `${altImage}`;
                                  }}
                              height={"400px"}
                                                     />
                          <div className="social-networks">
                            <div className="inner">
                              <Link
                                to={member.facebookUrl}
                                target="_blank"
                                className="hint--bounce hint--top hint--theme-two"
                                aria-label="facebook"
                              >
                                <i className="fab fa-facebook"></i>
                              </Link>
                              <Link
                                to={member.twiiterUrl}
                                target="_blank"
                                className="hint--bounce hint--top hint--theme-two"
                                aria-label="Twitter"
                              >
                                <i className="fab fa-twitter"></i>
                              </Link>
                              <Link
                                to={member.linkedinUrl}
                                target="_blank"
                                className="hint--bounce hint--top hint--theme-two"
                                aria-label="LinkedIn"
                              >
                                <i className="fab fa-linkedin"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="team-info text-center">
                          <h5 className="name">{member.name}</h5>
                          <div className="position">{member.position}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>
                  No team found in the database ...please, add team members.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <BFooter />
      </div>

      <div>
        <FooterSection />
      </div>
    </div>
  );
};

export default TeamSection;
