import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../bgheader/style.css";
import LoginModal from "../../pages/admin/Login";
import { Button, StyledAnchor } from "../../components/StyledComponents";
import Brochure from "../../assets/2024BROCHURE.pdf";
import MobileMenuModal from './MobileNav'

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    aboutUs: false,
    eventsBlogs: false,
  }); // Managing sub-menus
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  //const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal2 = () => {
        setIsModalOpen2(!isModalOpen2);
    };

    const toggleModal = () => {
      setIsModalOpen2(!isModalOpen2);
  };


  // Toggle for sub-menus
  const toggleSubMenu = (menu) => {
    setIsSubMenuOpen({ ...isSubMenuOpen, [menu]: !isSubMenuOpen[menu] });
  };
  return (
    <div className="header-section header-transparent sticky-header section  ">
      <nav
        className=" fixed-top "
        style={{
          backgroundColor: "white",
        }}
      >
        <div className="container position-relative">
          <div className="row justify-content-between align-items-center">
            {/* Header Logo Start */}
            <div className="col-xl-3 col-auto">
              <div className="header-logo">
                <Link to={"/nnpcacademy"}>
                  <img
                    src="https://logos-world.net/wp-content/uploads/2023/05/NNPC-Logo-500x281.png"
                    width="300"
                    height="90"
                    className="dark-logo d-inline-block align-top"
                    alt="NNPC-Academy-logo"
                  />
                  <img
                    src="https://logos-world.net/wp-content/uploads/2023/05/NNPC-Logo-500x281.png"
                    width="300"
                    height="90"
                    className="light-logo d-inline-block align-top"
                    alt="NNPC-Academy-logo"
                  />
                </Link>
              </div>
            </div>
            {/* Header Logo End */}

            {/* Header Main Menu Start */}
            <div className="col d-none d-xl-block position-static">
              <nav className="site-main-menu menu-hover-1">
                <ul className="text-white">
                  <li className="has-children">
                    <Link to={"/about-us"} className="text-decoration-none">
                      <span className="menu-text">About us</span>
                    </Link>
                    <span className="menu-toggle">
                      <i className="far fa-angle-down"></i>
                    </span>
                    <ul className="sub-menu">
                      <li>
                        <Link to={"/about-us"} className="text-decoration-none">
                          <span className="menu-text">Overview</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our-team"} className="text-decoration-none">
                          <span className="menu-text">Our team</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/stories"} className="text-decoration-none">
                          <span className="menu-text">Success Story</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to={"/courses"} className="text-decoration-none">
                      <span className="menu-text">Courses</span>
                    </Link>
                  </li>
                  <li className="has-children">
                    <Link className="text-decoration-none">
                      <span className="menu-text">Events & Blogs</span>
                    </Link>
                    <span className="menu-toggle">
                      <i className="far fa-angle-down"></i>
                    </span>
                    <ul className="sub-menu">
                      <li>
                        <Link to={"/event"} className="text-decoration-none">
                          <span className="menu-text">Events</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/blognews"} className="text-decoration-none">
                          <span className="menu-text">Blogs & News</span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to={"/contact-us"} className="text-decoration-none">
                      <span className="menu-text ">Contact us</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            {/* Header Main Menu End */}

            {/* Header Right Start */}
            <div className="col-xl-3 col-auto">
              <div className="header-right">
                <div className="inner">
                  {/* Header Login Start */}
                  <div
                    className="header-login"
                    style={{ visibility: "hidden" }}
                  >
                    <a href="profile.html">
                      <i className="far fa-user-circle"></i>
                    </a>
                  </div>
                  {/* Header Login End */}

                  <div className="event-button">
                    <Button height="32px" padding="0 20px" onClick={openModal}>
                      Login{" "}
                    </Button>
                    <LoginModal show={isModalOpen} handleClose={closeModal} />
                  </div>

                  {/* Header Mobile Menu Toggle Start */}
                  <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
            <button className="toggle" onClick={toggleModal}>
                <i className="icon-top"></i>
                <i className="icon-middle"></i>
                <i className="icon-bottom"></i>
            </button>
            <MobileMenuModal isOpen={isModalOpen2} toggleModal={toggleModal} />
        </div>

                  {/* Header Mobile Menu Toggle End */}
                </div>
              </div>
            </div>
            {/* Header Right End */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
