import React from 'react';
import { Navigate } from 'react-router-dom';

const RequireAuth = ({ children }) => {
    // Check if userToken exists in localStorage to determine if the user is authenticated
    const isAuthenticated = localStorage.getItem('userToken') !== null;

    if (!isAuthenticated) {
        // User is not authenticated, redirect them to the login page
        return <Navigate to="/login" replace />;
    }

    // User is authenticated, render the child component
    return children;
};

export default RequireAuth;


