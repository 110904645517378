import logo from './logo.svg';
import './App.css';
import Layout from './layout/Index.js';
import React, { Component } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

class App extends Component {
  componentDidMount() {
    AOS.init({
      // Global settings:
      once: false, // whether animation should happen only once - while scrolling down
      // other options can be found in AOS documentation
    });
  }

  render() {
    return (
      <Layout />
    );
  }
}

export default App;


