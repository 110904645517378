import React, { useEffect, useMemo, useState } from "react";
import Header from "../layout/navbar/HeaderSection";
import FooterSection from "../layout/footer/FooterSection";
import { useParams } from "react-router-dom";
import blogsData from "./jsonfiles/blogs.json";
import commentsData from "./jsonfiles/commentsData.json";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getPosts, getPosts1 } from "../api/UserAPI";
import altImage from "../assets/img/brand/courses-image.jpg";

function BlogDetails() {
  const getId = useParams();
  const Id = getId.blogId;
  const [blogtDetails, setblogDetails] = useState("");
  const [popularPosts, setPopularPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentUrl = window.location.href;

  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const response = await getPosts1(Id);
        setblogDetails(response);

        const popular = await getPosts();
        const otherPosts = popular.filter((post) => post.id != Id);
        setPopularPosts(otherPosts);
      } catch (error) {
        if (!blogtDetails) return <div>Blog not found</div>;
      }
    };

    fetchSelectOptions();
  }, []);

  //const blogclick = blogsData.find(blog => blog.id === parseInt(blogId, 10))

  const categories = useMemo(
    () => [...new Set(popularPosts.map((blogcat) => blogcat.category))],
    [blogsData]
  );
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form logic here
    console.log(formData);
    // Reset form or show success message
  };

  const handleBlogClick = (blogId) => {
    setIsLoading(true); // Set loading state to true

    setTimeout(() => {
      navigate(`/blog/${blogId}`);
      setIsLoading(false);
      window.location.reload();
    });
  };

  const formatDate = (dateTimeString) => {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      //second: '2-digit',
      hour12: true,
    };

    return new Date(dateTimeString).toLocaleString(undefined, options);
  };

  const handleShare = (socialMedia) => {
    switch (socialMedia) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            currentUrl
          )}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            currentUrl
          )}`,
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            currentUrl
          )}`,
          "_blank"
        );
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="mt-5">
        <div class="page-title-section section mt-5">
          <div class="page-breadcrumb mb-5">
            <div class="container">
              <ul class="breadcrumb">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <a href="blog-news.html">Blog & News</a>
                </li>
                <li class="current">Blog Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="section section-padding-bottom fix">
          <div class="container">
            <div class="row max-mb-n50">
              <div className="col-lg-4 col-12 order-lg-1 max-mb-50">
                <div className="sidebar-widget-wrapper">
                  <div className="sidebar-widget">
                    <h3 className="sidebar-widget-title mb-2">Popular posts</h3>
                    <div className="sidebar-widget-content">
                      {isLoading ? (
                        <p>Loading...</p> // Show loading indicator if isLoading is true
                      ) : (
                        <ul className="sidebar-widget-list-post">
                          {popularPosts.map((blog1, index) => (
                            <li>
                              <a
                                href="#"
                                onClick={() => handleBlogClick(blog1.id)}
                                className=" Title text-decoration-none"
                              >
                                {blog1.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="sidebar-widget">
                    <h3 className="sidebar-widget-title">Popular tags</h3>
                    <div className="sidebar-widget-content">
                      <div className="tagcloud">
                        {categories.map((category, index) => (
                          <a href="#">{category}</a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-12 order-lg-2 max-mb-50">
                <div class="row row-cols-1 g-0">
                  <div className="blog-3 blog-details col" data-aos="">
                    <div className="thumbnail">
                      <img
                        src={blogtDetails.image}
                        alt="Blog Image"
                        width={"100%"}
                      />
                    </div>
                    <div className="info">
                      <a href="#" className="category">
                        {blogtDetails.category}
                      </a>
                      <h3 className="title">{blogtDetails.title}</h3>
                      <ul className="meta">
                        <li>
                          <a href="#">
                            <img src={altImage} alt="" className="avatar" />
                          </a>
                        </li>
                        <li>
                          <i className="far fa-calendar"></i>
                          {formatDate(blogtDetails.date)}
                        </li>
                        <li>
                          <i className="far fa-eye"></i>
                          {blogtDetails.views}
                        </li>
                      </ul>
                      <div className="desc">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: blogtDetails.content,
                          }}
                        ></div>
                      </div>
                      <div className="row justify-content-between align-items-center max-mt-50">
                        <div className="col-auto">
                          <div className="entry-post-tags">
                            <div className="tag-label">
                              <span className="tag-icon far fa-tags"></span>
                            </div>
                            <div className="tag">
                              <a href="#"> </a>
                              <a href="#"></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="post-share">
                            <span className="label">Share this post</span>
                            <div className="media ">
                              <span
                                className="icon"
                                style={{ width: "65px", height: "65px" }}
                              >
                                <i
                                  className="fas fa-share-alt "
                                  style={{ fontSize: "20px" }}
                                ></i>
                              </span>
                              <div className="list">
                                <a
                                  href="#"
                                  onClick={() => handleShare("facebook")}
                                >
                                  <i className="fab fa-facebook-f"></i>
                                </a>
                                <a
                                  href="#"
                                  onClick={() => handleShare("twitter")}
                                >
                                  <i className="fab fa-twitter"></i>
                                </a>
                                <a
                                  href="#"
                                  onClick={() => handleShare("linkedin")}
                                >
                                  <i className="fab fa-linkedin"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <FooterSection />
      </div>
    </div>
  );
}

export default BlogDetails;
