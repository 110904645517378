import React, {useState, useEffect} from 'react'

import { getContactUs, getEnroll, delEnroll  } from '../../../api/UserAPI';
import { Form, NavLink } from 'react-router-dom';
import { Button, ButtonDropdown, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {useFetchData} from '../hooks/useFetchData'
// import {GenericDataManagementComponent} from '../hooks/GenericBlocs'
// import { editContactUs, delContactUs } from '../../../api/UserAPI';
import { Drawer, theme, Modal, Space, Card, Collapse, Select } from 'antd';
import * as XLSX from 'xlsx';
import {  StyledSelect, } from "../../../components/StyledComponents";

const EnrollForm = () => {
  // const { data: contacts, isLoading, notFound: noMessagesFound } = useFetchData(getEnroll);
  const [editModal, setEditModal] = useState(false);
  const [currentEnroll, setCurrentEnroll] = useState([]); 
  const [filterCourse, setFilterCourse] = useState('');
  const [uniqueCourses, setUniqueCourses] = useState([]);
  const contactsPerPage = 40;
  const [currentPage, setCurrentPage] = useState(1);
  const [editFormData, setEditFormData] = useState({
    id: '',
    name:'',
    number:'',
    email: '',
    message: '',
    // Initialize other fields here if necessary
  });
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchEnroll = async () => {
      try {
        const response = await getEnroll();
        const enrollments = Array.isArray(response) ? response : response.results || [];
        setCurrentEnroll(enrollments);
        
        const courses = [...new Set(enrollments.map(enroll => enroll.course))];
        setUniqueCourses(courses);
        
      } catch (error) {
        setMessage(error);
        console.log(error);
      }
    };
    fetchEnroll();
  }, []);
  


  const totalPages = Math.ceil(currentEnroll.length / contactsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

   const filteredEnrollments = currentEnroll.filter((enroll) =>
    filterCourse ? enroll.course === filterCourse : true
  );

  const Contactspage = filteredEnrollments.slice(
    (currentPage - 1) * contactsPerPage,
    currentPage * contactsPerPage
  );

  // const Contactspage = currentEnroll.slice(
  //   (currentPage - 1) * contactsPerPage,
  //   currentPage * contactsPerPage
  // );


  
  const handleEditClick = () => {
   //
  };

  const handleUpdate = async () => {
   
  };

  const showModal = (id) => {
    setSelectedItem(id)
    setOpenModal(true);
  };
  const hideModal = () => {
    setSelectedItem(null)
    setOpenModal(false);
  };



  const handleDelete = async (enrollId) => {

    try {
      // Pass the `teamId` to `delTeam`
      const response = await delEnroll(enrollId);
      if (!response) {
         // setNoTeamFound(true);
      } else {
        setMessage("enoll  deleted successfully");
    
      }
  } catch (error) {
      console.error("Could not delete enrollment.", error);
  } finally {
      //setIsLoading(falsblogPerPagee);
  }

  window.location.reload();
    
  };

const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredEnrollments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Enrollments');
    XLSX.writeFile(workbook, 'Enrollments.xlsx');
  };



  return (
    <div>
      <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <h4 style={{ fontWeight: 700, color:'#1A2F3E'}}>Enrollment</h4>
          </div>
        </div>
        <div className="col-6 text-end "> 
          <div className=' '>
          {/* <button  style={{color:'white', background:'green', padding:10, borderRadius:25, border:'none'}} onClick={showDrawer}> <icon><PlusOutlined/></icon> New Event</button> */}
          </div>
        </div>
      </div>

      <div 
       style={{
        padding: 20,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
        minHeight: 500,
      }}>
          <div className="row mb-3">
        <div className="col-6 ">
          <div className=' ms-2'>
            <p style={{ fontWeight: 600, color:'#1A2F3E'}}>We found <span>{filteredEnrollments.length} </span> enrollments</p>
          </div>
        </div>

        <div className="col-6 text-end d-flex justify-content-between "> 
        
            {/* <StyledSelect className="m-1" value={selectedSortOrder} onChange={handleSortChange}>
              <option value="asc">Sort by Date  (Ascending)</option>
              <option value="desc">Sort by Date (Descending)</option>
            </StyledSelect>
            <StyledSelect className="m-1" value={selectedTag} onChange={handleTagChange}>
              <option value="">All location</option>
              {uniqueTags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </StyledSelect> */}
            <StyledSelect className="" 
            placeholder="Filter by course"
              value={filterCourse}
              //onChange={(value) => setFilterCourse(value)}
              onChange={(e) => setFilterCourse(e.target.value)}

              >
              <option value="">All Courses Application</option>
              {uniqueCourses.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
                
              ))}
              
            </StyledSelect> 


            
            <Button className='btn btn-success' onClick={downloadExcel}>Download Excel <span> {filteredEnrollments.length} </span> data entity(s)</Button>

        </div>
      </div>



    <div>{message && (
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      )}</div>
      <div>

      <div className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2  row-cols-1 max-mb-n30">
        {Array.isArray(Contactspage) && Contactspage.length > 0 ?( Contactspage.map((enroll) => (
         <div
         className="col-lg-4 col-md-6 col-sm-6 max-mb-50"
          >

        <Card 
          className='mt-1 p-1'
            bordered={true} 
            style={{ 
              width: '100%', 
              border: '2px solid #ddd', // add this line
              margin: 1,
            }} 
          >
          <div style={{ 
            paddingBottom: '5px' 
          }}>
            
            <div>
            <Collapse
                size="small"
                items={[
                  {
                    label: <strong>
                    {enroll.user} <br/> wants to enroll <br/> 
                    <b style={{ 
                      display: '-webkit-box', 
                      WebkitLineClamp: '1', 
                      maxLines: '1',
                      WebkitBoxOrient: 'vertical', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      whiteSpace: 'wrap' 
                    }}>
                      {enroll.course}
                    </b>
                  </strong>,
                    children: <div>  
                        <p> {enroll.email} </p>
                        <p>{enroll.number} </p>
                        <p>{enroll.course}</p> 
                        <p>{enroll.comment} </p>
                      </div>,
                  },
                ]}
              />
            </div>

          </div>
          <button className="btn btn-danger btn-xs" onClick={()=>showModal(enroll.id)}>Delete</button>
          <Modal
            title={<div style={{ textAlign: 'center' }}>Deletion confirmation</div>}
            open={openModal}
            onCancel={hideModal}
            footer={null}
          >
            <p style={{ textAlign: 'center' }}>Are you sure you want to delete this enrollment?</p>
            <div style={{ textAlign: 'center' }}> 
            <Button className='btn btn' onClick={()=> hideModal}>No</Button>                          
            <Button className="btn btn-danger m-1" onClick={() => handleDelete(selectedItem)}>Yes</Button>
            </div>

          </Modal>


          </Card>
           
        </div>
        ))
      ): (
          <p>
            Wait a little while enrollments are loading         
             </p>
        )}


      </div>
      <div className='mt-3' style={{textAlign:'center'}}>
            <ul className="pagination " style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              {Array(totalPages)
                .fill(0)
                .map((_, index) => (
                  <li key={index}>
                    <a
                      href="#"
                      onClick={() => handlePageChange(index + 1)}
                      className={index + 1 === currentPage ? 'active' : ''}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
            </ul>

          </div>

      </div>

      </div>
    </div>
  )
}

export default EnrollForm